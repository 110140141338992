import { notification, Row } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import AddContact from '../contacts/addContact';
import ContactForm from '../contacts/contact';
import FormValidationRules from './FormValidationRules';
import OrganismDataApi from './../../services/OrganismDataApi';
import OrganismHelper from '../../Helpers/OrganismHelper';
import CTAForm from './Partials/CTAForm';
import { getOrganizations } from './Partials/getOrganism';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { CreateForm } from './Partials/createForm';

const NewOrganismForm = ({ step, onStepChange, themes = [], setVisible, visible }) => {
    const [contacts, setContacts] = React.useState([{ fullName: '', email: '', phone: '' }]);
    const [addOrganismStatus, setAddOrganismStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            organismName: '',
            organismTheme: '',
            organismEmail: '',
            organismTelephone: '',
            organismAdress: '',
            organismExtraAdress: '',
            organismRecipient: '',
            organismPostalCode: '',
            organismTown: '',
            organismCountry: '',
            organismContacts: contacts,
        },
        validationSchema: FormValidationRules.addFormValidatioNRule(),
        //enableReinitialize: true,
    });
    const dispatch = useDispatch();

    useEffect(() => {
        formik.handleSubmit();
        formik.values.organismContacts = [{ email: '', phone: '', fullName: '' }];
        if (addOrganismStatus === true) {
            console.log('envoir....');
            onStepChange(0);
        }
    }, [visible]);

    const handleAddContactClick = () => {
        const items = formik.values.organismContacts;
        let item = null;
        if (items.length > 0) {
            item = items[items.length - 1];
        }
        if (item != null) {
            if (!isEmpty(item.fullName)) {
                const contact = { fullName: '', email: '', phone: '' };
                items.push(contact);
                setContacts(items);
                onStepChange(-1);
                setTimeout(() => {
                    onStepChange(1);
                }, 10);
            } else {
                notification.warning({
                    message: "Le dernier contact n'a pas de nom",
                });
            }
        }
    };

    React.useEffect(() => {
        console.log('create change trigger');
        const values = formik.values;
        formik.values = { ...values, organismName: values.organismName };
    }, [contacts]);

    const handleChangeStep0 = async () => {
        formik.handleSubmit();
        if (step == 0) {
            if (OrganismHelper.isValid(formik, ['organismName', 'organismTheme', 'organismEmail', 'organismTelephone'])) {
                onStepChange(1);
            } else {
                notification.open({
                    message: 'Veillez à remplir tous les champs obligatoires',
                    type: 'error',
                });
            }
        }
    };

    const handleValidate = async () => {
        const values = formik.values;
        if (!formik.isValid) {
            if (formik.values.organismContacts.length == 1) {
                if (!isEmpty(formik.values.organismContacts[0].fullName)) {
                    notification.error({
                        message: 'Veuillez remplir correctement les champs',
                    });
                    return;
                }
            } else {
                notification.error({
                    message: 'Veuillez remplir correctement les champs',
                });
                return;
            }
        }
        setLoading(true);
        let organization = OrganismHelper.getOrganismObjectProperties(values);
        await OrganismDataApi.create(organization)
            .then(async () => {
                setAddOrganismStatus(true);
                const rep = await getOrganizations(1);
                dispatch({
                    type: 'ORGANISM_SEARCH',
                    payload: rep.list,
                });
                notification.open({
                    message: 'Organisme créé avec succès',
                    type: 'success',
                });
                onStepChange(0);
                formik.resetForm(formik.initialValues);
                setVisible(false);
            })
            .catch((reason) => {
                const status = reason.response.status;
                if (status == 401) {
                    notification.open({
                        message: 'Une erreur est survenue! Veuillez réessayer, svp!',
                        type: 'warning',
                    });
                } else if (status == 403) {
                    notification.open({
                        message: 'Vous ne pouvez pas créer un organisme',
                        type: 'info',
                    });
                } else if (status == 400) {
                    let description = '';
                    try {
                        reason.response.data['hydra:description'];
                    } catch (error) {
                        console.log(error);
                    }
                    notification.open({
                        message: 'Veuillez vérifier vos insertions, svp! ' + description,
                        type: 'warning',
                    });
                } else if (status == 422) {
                    notification.open({
                        message: 'Il semble que vous avez saisi le nom déjà utilisé!',
                        type: 'warning',
                    });
                } else {
                    notification.open({
                        message: 'Une erreur est survenue!',
                        type: 'warning',
                    });
                }
                setLoading(false);
            });
    };

    return (
        <form className="form-popup" onSubmit={formik.handleSubmit}>
            {step === 0 && (
                <>
                    <CreateForm formik={formik} themes={themes} />
                </>
            )}

            {step === 1 && (
                <>
                    {formik.values.organismContacts.map((contact, key) => {
                        return (
                            <ContactForm
                                contact={{ ...contact, id: key }}
                                key={key}
                                values={formik.values.organismContacts[key]}
                                //onChange={(e, value) => console.log('cc', value, e)}
                                onChange={formik.handleChange}
                                formik={formik}
                            />
                        );
                    })}
                    <AddContact handleAddContact={handleAddContactClick} />
                </>
            )}

            <Row justify="space-around" style={{ marginTop: 20 }}>
                <CTAForm handleClick={handleChangeStep0} loading={loading} handleValidate={handleValidate} step={step} onStepChange={onStepChange} />
            </Row>
        </form>
    );
};

export default NewOrganismForm;
