import { Col, Typography, List, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DateHelper from './../../Helpers/DateHelper';
import FolderFilters from './FolderFilters';
import { StatusLabel } from './ListFolder';
import HeaderTitle from '../Partials/HeaderTitle';
import { FolderPagination } from './Partials/folderPagination';
import { FolderTypes, getNameFromValue } from './FolderValues';

const ListFolders = () => {
    const folders = useSelector((state) => state.folder.folders);
    const loading = useSelector((state) => state.folder.loadingFolder);

    return (
        <>
            <List
                size="large"
                locale={{ emptyText: 'La liste est vide' }}
                itemLayout="horizontal"
                dataSource={folders}
                loading={loading}
                renderItem={(folder) => (
                    <Link to={`/folders/${folder.uid}/view`}>
                        <List.Item onClick={(event) => console.log(folder, event)} key={folder.uid}>
                            <Row align="middle" style={{ width: '100%', height: '100%' }}>
                                <Col span={10}>
                                    <CellFolderRow
                                        subvalue={folder.customerPhone}
                                        label={folder.deadFullName}
                                        value={`Contact : ${folder.customerFullName}`}
                                    />
                                </Col>

                                <Col span={7}>
                                    <CellFolderRow
                                        subvalue=""
                                        label={<TypeLabel folder={folder} />}
                                        value={DateHelper.getDate(folder.typeDatetime, 'LL')}
                                    />
                                </Col>

                                <Col span={7} style={{ textAlign: 'left' }}>
                                    <CellFolderRow
                                        label={<StatusLabel folder={folder} />} // prettier-ignore
                                        value={folder.pfName}
                                        subvalue={folder.pfCounselorName}
                                    />
                                </Col>
                            </Row>
                        </List.Item>
                    </Link>
                )}
            />
            <FolderPagination />
        </>
    );
};

function Folders() {
    return (
        <div>
            <FolderFilters screen="folder" />
            <div className="box withTitle">
                <HeaderTitle title="Tous les dossiers" />
                <ListFolders />
            </div>
        </div>
    );
}

const CellFolderRow = ({ label, value, subvalue }) => {
    const { Text } = Typography;
    return (
        <div>
            <div>
                <Text style={{ fontWeight: 'bold' }}>{label}</Text>
            </div>
            <div>
                <Text>{value}</Text>
            </div>
            <div>
                <Text>{subvalue}</Text>
            </div>
        </div>
    );
};

const TypeLabel = ({ folder }) => {
    return getNameFromValue(FolderTypes, folder.type);
};

export { Folders };
