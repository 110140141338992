import React from 'react';

const StepProgress = ({ items, step, active }) => {
    const length = items.length;
    console.log(length);

    return (
        <ol className={'steps items-' + length}>
            {items.map((item, key) => {
                const stepActive = key <= step ? active : '';
                return (
                    <li key={key} className={stepActive}>
                        <span>{item}</span>
                    </li>
                );
            })}
        </ol>
    );
};

export default StepProgress;
