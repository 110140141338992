import React from 'react';
import { Select } from 'antd';

const OptionsThemeOrganism = ({ value, onChange, list = [] }) => {
    const { Option } = Select;

    return (
        <>
            <Select
                suffixIcon={<i className="icon-angle-down"></i>}
                defaultValue={value}
                onChange={onChange}
                name="organismTheme"
                style={{ width: '100%' }}
            >
                {list.map((p, key) => {
                    return (
                        <Option key={key} value={p['@id']}>
                            {p.name}
                        </Option>
                    );
                })}
            </Select>
        </>
    );
};

export default OptionsThemeOrganism;
