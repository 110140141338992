import { Input } from 'antd';
import React from 'react';
import FormikErrorSection from '../../Partials/FormikErrorSection';
import StyledLabel from '../../Partials/StyledLabel';
import OptionsThemeOrganism from './OptionsThemeOrganism';

export function CreateForm({ formik = null, themes = [] }) {
    return (
        <React.Fragment>
            {!!formik && (
                <React.Fragment>
                    <StyledLabel label="Nom de l'organisme *" />
                    <Input name="organismName" onChange={formik.handleChange} value={formik.values.organismName} />
                    <FormikErrorSection field={formik.errors.organismName} touched={formik.touched.organismName} />
                    <StyledLabel label="Thème *" />
                    <OptionsThemeOrganism
                        value={formik.values.organismTheme}
                        onChange={(v) => formik.setFieldValue('organismTheme', v)}
                        list={themes}
                    />
                    <FormikErrorSection field={formik.errors.organismTheme} touched={formik.touched.organismTheme} />
                    <StyledLabel label="Destinataire" />
                    <Input name="organismRecipient" onChange={formik.handleChange} value={formik.values.organismRecipient} />
                    <StyledLabel label="Téléphone de l'organisme" />
                    <Input name="organismTelephone" onChange={formik.handleChange} value={formik.values.organismTelephone} />
                    <FormikErrorSection field={formik.errors.organismTelephone} touched={formik.touched.organismTelephone} />

                    <StyledLabel label="Email de l'organisme" />
                    <Input name="organismEmail" onChange={formik.handleChange} value={formik.values.organismEmail} />
                    <FormikErrorSection field={formik.errors.organismEmail} touched={formik.touched.organismEmail} />

                    <StyledLabel label="Adresse" />
                    <Input name="organismAdress" onChange={formik.handleChange} value={formik.values.organismAdress} />
                    <StyledLabel label="Complément d'adresse" />
                    <Input name="organismExtraAdress" onChange={formik.handleChange} value={formik.values.organismExtraAdress} />
                    <StyledLabel label="Code postal" />
                    <Input name="organismPostalCode" onChange={formik.handleChange} value={formik.values.organismPostalCode} />
                    <StyledLabel label="Ville" />
                    <Input name="organismTown" onChange={formik.handleChange} value={formik.values.organismTown} />
                    <StyledLabel label="Pays" />
                    <Input name="organismCountry" onChange={formik.handleChange} value={formik.values.organismCountry} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
