import React from 'react';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { FolderButton } from './FolderButton';
import HeaderReturn from '../Headers/headerViewBack';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, List, Progress, Row, Skeleton, Typography, Upload } from 'antd';
import { EyeFilled as EyeIcon, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import folderFoDocumentCategoryGroups from '../../../../services/folderFoDocumentCategoryGroups';
import folderFoDocumentCatGroupItems from '../../../../services/folderFoDocumentCatGroupItems';
import folderFoDocument from '../../../../services/folderFoDocument';
import folderFoDocumentFile from '../../../../services/folderFoDocumentFile';
import { PdfReader } from '../Modal/PdfReader';
import config from '../../../../config';
import { getFolder } from '../getFolders';
import folderFoDocumentCategory from '../../../../services/folderFoDocumentCategory';
import { canEditFolder } from '../advisorTreatment';

const { Title } = Typography;

const HeadetTitle = ({ label }) => {
    return (
        <Row style={{ borderBottom: '1px solid #FFEAE4', marginBottom: 20 }}>
            <Col flex="1">
                <Title style={{ color: '#cb5d4b' }} level={3}>
                    {label}
                </Title>
            </Col>
        </Row>
    );
};

const Waiting = () => (
    <React.Fragment>
        <Skeleton active />
        <Skeleton active />
    </React.Fragment>
);

function ConsultDocScreen() {
    const [folder, setFolder] = React.useState({});
    const currentfolder = useSelector((state) => state.folder.currentFolder);
    const [isLoading, setLoading] = React.useState(true);
    const { uid } = useParams();
    const dispatch = useDispatch();
    const hist = useHistory();
    const [groups, setGroup] = React.useState([]);
    const [documents, setDocument] = React.useState([]);
    const [enabled, setEnabled] = React.useState(true);
    const [title, setTitle] = React.useState('');

    const getFile = async (uri) => {
        let item;
        await folderFoDocumentFile.getByUri(uri).then((response) => {
            item = response.data;
        });
        return item;
    };

    const getDocuments = async (folder) => {
        let items = [];
        const data = `folder=${folder}&itemsPerPage=100`;
        await folderFoDocument.getByKeys(data).then((response) => {
            items = folderFoDocument.getData(response.data);
        });
        await Promise.all(
            items.map(async (item) => {
                const current = await getFile(item.file);
                if (current != undefined && current != null) {
                    const { mimeType, originalName, size, uid } = current;
                    item.name = originalName;
                    item.size = size;
                    item.mimeType = mimeType;
                    item.fileUid = uid;
                    item.percent = 0;
                }
            }),
        );
        setDocument(items);
        setEnabled(true);
    };

    const getGroupItems = async (group) => {
        let items = [];
        const data = `group=${group}&order[order]=asc`;
        await folderFoDocumentCatGroupItems.getByKeys(data).then((response) => {
            items = folderFoDocumentCatGroupItems.getData(response.data);
        });
        return items;
    };

    const onLoadCategory = async () => {
        let list = [];
        await folderFoDocumentCategory
            .getByKeys('order[order]=asc')
            .then(async (catItem) => {
                list = folderFoDocumentCategory.getData(catItem.data);
                await Promise.all(
                    list.map(async (p) => {
                        await folderFoDocumentCategoryGroups.getByKeys(`order[order]=asc&category=${p['@id']}`).then(async (response) => {
                            const groups = folderFoDocumentCategoryGroups.getData(response.data);
                            await Promise.all(
                                groups.map(async (item) => {
                                    const groupItems = await getGroupItems(item['@id']);
                                    item.items = groupItems;
                                }),
                            );
                            p.groups = groups;
                        });
                    }),
                );
            })
            .catch(() => {});
        return list;
    };

    function getTitle(folder) {
        let info = '';
        const gender = folder.people.civility;
        info = `Liste de documents fournis par `;
        if (gender == 'M') {
            info += `Monsieur ${folder.customerFullName}`;
        } else if (gender == 'Mme') {
            info += `Madame ${folder.customerFullName}`;
        } else {
            info += `${folder.customerFullName}`;
        }
        return info;
    }

    React.useEffect(async () => {
        if (currentfolder == null || currentfolder.uid == undefined) {
            await getFolder(uid).then(async (response) => {
                const rep = response.value;
                const info = getTitle(rep);
                await getDocuments(rep['@id']);
                setFolder(rep);
                setTitle(info);
                dispatch({ type: 'CURRENT_FOLDER', payload: rep });
            });
        } else {
            setFolder(currentfolder);
            await getDocuments(currentfolder['@id']);
            const info = getTitle(currentfolder);
            setTitle(info);
        }
        const list = await onLoadCategory();
        setGroup(list);
        setLoading(false);
    }, []);

    function handleReturn() {
        if (hist != undefined) {
            hist.goBack();
        }
    }

    const renderItem = (item) => (
        <GroupData
            label={item.name}
            key={`${item.uid}`}
            list={item.items}
            documents={documents}
            setDocument={setDocument}
            folder={folder}
            getDocuments={getDocuments}
            setEnabled={setEnabled}
            enabled={enabled}
        />
    );

    const renderCategory = (item) => (
        <div style={{ padding: '10px 20px' }}>
            <Title level={5}>{item.name}</Title>
            <List dataSource={item.groups} renderItem={renderItem} />
        </div>
    );

    return (
        <React.Fragment>
            {folder == undefined && <Redirect to="/folders" />}
            {!isLoading && folder != null && (
                <React.Fragment>
                    <HeaderReturn handleBackToList={handleReturn} />
                    <div className="box" style={{ padding: 15 }}>
                        <HeadetTitle label={title} />
                        <List dataSource={groups} renderItem={renderCategory} />
                    </div>
                </React.Fragment>
            )}
            {isLoading && <Waiting />}
        </React.Fragment>
    );
}

const GroupData = ({ label, list = [], setDocument, documents, folder, getDocuments, enabled = true, setEnabled }) => {
    const renderItem = (item, index) => (
        <DocList
            key={`item_group${item.uid}_${index}`}
            list={documents}
            setList={setDocument}
            label={item.name}
            group={item['@id']}
            folder={folder}
            getDocuments={getDocuments}
            setDocument={setDocument}
            enabled={enabled}
            setEnabled={setEnabled}
        />
    );
    return (
        <div style={{ padding: '10px 0px' }}>
            <Title style={{ fontSize: 14, opacity: 0.8 }} level={5}>
                {label}
            </Title>
            <List dataSource={list} renderItem={renderItem} />
        </div>
    );
};

function DocList({ list = [], setList, label, group, folder, getDocuments, enabled = false, setEnabled }) {
    const [visible, setVisible] = React.useState(false);
    const [currentFile, setCurrentFile] = React.useState(false);
    const [currentFileType, setCurrentFileType] = React.useState(null);
    const [loadUrl, setLoadUrl] = React.useState(true);

    async function handleRead(item) {
        setLoadUrl(true);
        setVisible(true);
        setCurrentFile(null);
        setCurrentFileType(item.mimeType);
        const uid = item.fileUid;
        let url = null;
        await folderFoDocumentFile.getUrl(uid).then((response) => {
            url = config.URL_API + response.data.url;
        });
        if (url != null) {
            setCurrentFile(url);
            setLoadUrl(false);
        } else {
            setVisible(false);
        }
    }

    const renderFiles = (item) => {
        return (
            <List.Item>
                <Row align="middle">
                    {item.percent > 0 && (
                        <Col>
                            <Progress
                                status="active"
                                type="circle"
                                width={40}
                                percent={item.percent.toFixed(0)}
                                style={{ paddingLeft: 20, paddingRight: 20, position: 'relative' }}
                            />
                        </Col>
                    )}
                    {item.loading === true && (
                        <Col>
                            <LoadingOutlined style={{ color: '#cb5d4b', padding: 5 }} />
                        </Col>
                    )}
                    <Col flex="1">
                        <div style={{ maxWidth: '120px' }}>
                            <Typography.Paragraph style={{ paddingTop: 12, width: '100%' }} ellipsis={{ rows: 1 }}>
                                {item.name}
                            </Typography.Paragraph>
                        </div>
                    </Col>
                    <Col>
                        <Button style={{ padding: 5 }} onClick={() => handleRead(item)} type="text" disabled={item.percent > 0 || item.loading}>
                            <EyeIcon style={{ fontSize: 25, color: '#a49f99' }} />
                        </Button>
                    </Col>
                </Row>
            </List.Item>
        );
    };

    async function handleChange(info, categoryGroupItem, folder) {
        setEnabled(false);
        const items = info.fileList;
        const item = items[items.length - 1];
        item.categoryGroupItem = categoryGroupItem;
        item.loading = true;
        setList(items);
        if (item.status == undefined && item.percent == 0) {
            const data = new FormData();
            data.append('file', item.originFileObj);
            await folderFoDocumentFile
                .create(data)
                .then(async (response) => {
                    const fileId = response.data['@id'];
                    const dataFo = { folder, categoryGroupItem, file: fileId };
                    await folderFoDocument.create(dataFo).then(async () => {
                        getDocuments(folder);
                    });
                })
                .catch(() => getDocuments(folder));
        }
    }

    return (
        <React.Fragment>
            <Row style={{ padding: '10px 0px' }} justify="space-between">
                <Col flex="1">
                    <Title style={{ fontWeight: 'normal', paddingRight: 35 }} level={5}>
                        {label == null ? 'Aucun titre' : label}
                    </Title>
                </Col>
                <Col>
                    {canEditFolder(folder) && (
                        <Upload
                            style={{ marginTop: 50 }}
                            beforeUpload={() => false}
                            onChange={(info) => handleChange(info, group, folder['@id'])}
                            showUploadList={false}
                            defaultFileList={list}
                            fileList={list}
                            accept="application/pdf"
                        >
                            <Button shape="circle" style={{ background: '#a49f99', position: 'absolute', right: 0, top: -5 }} disabled={!enabled}>
                                <PlusOutlined style={{ fontSize: 20, color: '#fff' }} />
                            </Button>
                        </Upload>
                    )}
                </Col>
            </Row>
            {list.filter((l) => l.categoryGroupItem == group).length > 0 && (
                <List
                    dataSource={list.filter((l) => l.categoryGroupItem == group)}
                    renderItem={renderFiles}
                    grid={{ gutter: 16, column: 2 }}
                    style={{ marginRight: 60, marginLeft: 20 }}
                />
            )}
            <PdfReader
                visible={visible}
                handleClose={() => {
                    setVisible(false);
                    setCurrentFile(null);
                }}
                url={currentFile}
                load={loadUrl}
                type={currentFileType}
            />
        </React.Fragment>
    );
}

function FolderConsultDocs({ folder }) {
    return (
        <React.Fragment>
            <Link to={`/docs/${folder.uid}/consult`}>
                <FolderButton title="Consulter les documents" type={1} />
            </Link>
        </React.Fragment>
    );
}
export { ConsultDocScreen, FolderConsultDocs };
