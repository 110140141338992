import http from './refConfig';
import { isEmpty } from 'lodash';

const refApiSource = 'referentiels';

class RefServiceApi {
    getPostalCode(queryParam) {
        const url = `${refApiSource}/postal-codes?${queryParam}`;
        return http.get(url);
    }

    getPostalCodeUid(uid) {
        const url = `${refApiSource}/postal-codes/${uid}`;
        return http.get(url);
    }

    getCountryByCode(code) {
        const url = `${refApiSource}/countries/${code}`;
        return http.get(url);
    }
    getAdministrativeDivision(query) {
        const url = `${refApiSource}/administrative-divisions${!isEmpty(query) ? '?' + query : ''}`;
        return http.get(url);
    }
    getAdministrativeDivisionByUid(uid) {
        const url = `${refApiSource}/administrative-divisions/${uid}`;
        return http.get(url);
    }
}

export default new RefServiceApi();
