import { Modal, Result } from 'antd';
import React from 'react';

function DeleteComment({ visible = false, handleClose }) {
    return (
        <Modal
            visible={visible}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={handleClose}
            centered
            footer={null}
            closeIcon="Fermer"
            wrapClassName="modal"
        >
            <Result status="success" style={{ paddingBottom: 0 }} />
            <span
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#675e51',
                    paddingBottom: 45,
                }}
            >
                Votre commentaire a bien été supprimé
            </span>
        </Modal>
    );
}

export { DeleteComment };
