import React from 'react';
import { ComponentInput, ComponentSelect } from '../../../../Partials/tools';
import { FolderTypes } from '../../../FolderValues';

const UpdateAppointment = ({ formik }) => {
    return (
        <React.Fragment>
            <ComponentInput label="Nom du conseiller" type="text" formik={formik} name="counselorName" enabled={false} />
            <ComponentSelect
                label="Type de dossier"
                enabled={false}
                key="folder_type"
                id="folder_type"
                name="folderType"
                formik={formik}
                list={FolderTypes.map((p) => ({ value: p.value, title: p.name }))}
            />
            <ComponentInput
                label="Date du rendez-vous"
                placeholder=""
                key="appointment_date"
                formik={formik}
                type="date"
                enabled={false}
                name="appointDate"
            />
            <ComponentInput label="Heure du rendez-vous" placeholder="" enabled={false} key="time" formik={formik} type="time" name="time" />
            <ComponentInput label="Numéro de suivi La Poste" placeholder="" key="tracking_number" type="text" formik={formik} name="trackingNumber" />
        </React.Fragment>
    );
};
export { UpdateAppointment };
