import moment from 'moment';
import 'moment/locale/fr';

class DateHelper {
    getDate(date, format) {
        if (arguments.length === 1) {
            format = arguments[0];
            date = undefined;
        }
        moment.locale('fr');
        if (date === null) return null;
        return moment(date).format(format);
    }

    sortFolderTypeDatetime(a, b) {
        const dateA = new Date(a.typeDatetime).getTime();
        const dateB = new Date(b.typeDatetime).getTime();
        return dateA - dateB;
    }

    addMonth(num, date) {
        if (date === null) return null;
        return moment(date).add(num, 'month');
    }

    getDateOnly(date) {
        if (date === null) return null;
        return moment(date);
    }

    isGreater(date1, date2) {
        const time1 = this.getDateOnly(date1)?.toDate().getTime();
        const time2 = this.getDateOnly(date2)?.toDate().getTime();
        return time1 > time2;
    }
}

export default new DateHelper();
