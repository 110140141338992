import React from 'react';
import { Col, List, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import PaymentPictoLabel from './Partials/PaymentPictoLabel';
import dateHelper from '../../Helpers/DateHelper';
import { useSelector } from 'react-redux';
import { HomeFolderPagination } from './Partials/folderPagination';
import { FolderStatus, getNameFromValue } from './FolderValues';

const ListFolders = () => {
    const resultSearchFolder = useSelector((state) => state.folder.getCounselorFolders);
    const loading = useSelector((state) => state.folder.loadingCounselor);

    return (
        <>
            <div className="list_folders">
                <List
                    size="large"
                    loading={loading}
                    itemLayout="horizontal"
                    dataSource={resultSearchFolder}
                    locale={{ emptyText: 'La liste est vide' }}
                    renderItem={(folder) => (
                        <Link to={`/folders/${folder.uid}/view`}>
                            <List.Item key={folder.uid}>
                                <Row align="middle" style={{ width: '100%', height: '100%' }}>
                                    <Col span={8}>
                                        <CellFolderRow label={folder.deadFullName} value={`Contact : ${folder.customerFullName}`} />
                                    </Col>

                                    <Col span={6}>
                                        <CellFolderRow label="Rendez-vous" value={dateHelper.getDate(folder.typeDatetime, 'LL')} />
                                    </Col>
                                    <Col span={4}>
                                        <CellFolderRow label="Contact" value={folder.customerPhone} />
                                    </Col>
                                    <Col span={6} style={{ textAlign: 'left' }}>
                                        <CellFolderRowPicto
                                            style={{ textAlign: 'left' }}
                                            label={<PaymentStatusPictoLabel status={folder.paymentState} />}
                                            value={<StatusLabel folder={folder} />}
                                        />
                                    </Col>
                                </Row>
                            </List.Item>
                        </Link>
                    )}
                />
                <HomeFolderPagination />
            </div>
        </>
    );
};

const CellFolderRow = ({ label, value }) => {
    const { Text } = Typography;
    return (
        <div>
            <div>
                <Text style={{ fontWeight: 'bold' }}>{label}</Text>
            </div>
            <div>
                <Text>{value}</Text>
            </div>
        </div>
    );
};

const CellFolderRowPicto = ({ label, value }) => {
    const { Text } = Typography;
    return (
        <div>
            <div>
                <Text style={{ fontWeight: 'bold' }}>{label}</Text>
            </div>
            <div>
                <Text style={{ marginLeft: 24 }}>{value}</Text>
            </div>
        </div>
    );
};

export const StatusLabel = ({ folder }) => {
    const nameParts = getNameFromValue(FolderStatus, folder.state).split(' / ');
    const name = folder.type === 'bilan' ? nameParts[0] : nameParts.pop();
    return 'Dossier ' + name.toLowerCase();
};

const PaymentStatusPictoLabel = ({ status }) => {
    switch (status) {
        case 'paying':
            return <PaymentPictoLabel text="En cours de paiement" picto="paying" />;
        case 'not_paid':
            return <PaymentPictoLabel text="Pas encore payé" picto="not_paid" />;
        case 'paid':
            return <PaymentPictoLabel text="Payé" picto="paid" />;
        default:
            return <PaymentPictoLabel text="Statut de paiement non défini" picto="default" />;
    }
};

export default ListFolders;
