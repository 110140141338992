import { Col, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import NewOrganismButton from '../../NewOrganismButton';
import { canAdministrateReferential } from '../../../folders/Partials/advisorTreatment';

const HeaderListOrganisms = ({ screen = '' }) => {
    const label = screen == 'organization' ? 'Liste des organismes' : screen == 'pf' ? 'Liste des pompes funèbres' : '';

    return (
        <Row justify="space-between" className="container_header_list_annuaire">
            <Col flex="1">
                <Link to="/organisms" className="title" style={{ fontWeight: 'bold', color: '#CB5D4B', fontSize: 24 }}>
                    {label}
                </Link>
                <div>
                    <Typography.Title style={{ fontSize: 16, fontWeight: 'normal' }}>Tous les résultats</Typography.Title>
                </div>
            </Col>
            <Col>{screen == 'organization' && canAdministrateReferential() && <NewOrganismButton label={'Ajouter un organisme'} />}</Col>
        </Row>
    );
};

export default HeaderListOrganisms;
