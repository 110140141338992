import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import StatsBlock from './StatsBlock';
import { statsLabels } from './StatsLabels';
import GenericHelper from '../../Helpers/GenericHelper';
import { useSelector } from 'react-redux';
// Statistiques

const Stats = () => {
    const [statsInfos, setStatsInfos] = useState([]);
    const counselor = useSelector((state) => state.user.id);
    const qTotalBilan = 'exists[bilanDatetime]=true';

    useEffect(() => {
        if (!counselor) return;
        const totalDraftData = {
            label: statsLabels.stats.labels.draft,
            params: {
                q1: 'state[]=draft&state[]=pending',
            },
            sizeCell: 12,
        };
        const doneLastMonthData = {
            label: statsLabels.stats.labels.done_last_month,
            params: {
                q1: 'state=completed&typeDatetime[after]=' + GenericHelper.getDateThirtyDaysBefore(),
            },
            sizeCell: 12,
        };
        const conversionRateData = {
            label: statsLabels.stats.labels.conversion_rate,
            params: {
                q1: qTotalBilan + '&type=entretien&state=completed',
                q2: qTotalBilan,
                rate: true,
            },
            sizeCell: 24,
        };

        const stats = [totalDraftData, doneLastMonthData, conversionRateData];
        stats.forEach((s) => {
            const prefix = 'counselor=' + counselor + '&';
            s.params.q1 = prefix + s.params.q1;
            if (s.params.q2 !== undefined) {
                s.params.q2 = prefix + s.params.q2;
            }
        });
        setStatsInfos(stats);
    }, [counselor]);

    return (
        <Row>
            <StatsBlock size={24} statsInfos={statsInfos} title={statsLabels.stats.title} />
        </Row>
    );
};

export { Stats };
