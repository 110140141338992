import React, { useState, useEffect } from 'react';
import { Col, Row, Modal, Button, Input, Select, message } from 'antd';
import userServiceApi from '../../services/userServiceApi';
import { useFormik } from 'formik';
import { isEmpty } from '../Partials/tools';
import FormValidationRules from '../organisms/FormValidationRules';
import { ValidationModal } from '../folders/Partials/Modal/validation';
import { advisorStatusList } from '../../Helpers/PersonHelper';

function UserManageView({
    visible,
    handleClose,
    title,
    description,
    btn1title,
    lastName,
    firstName,
    role,
    email,
    popupmessage,
    setRefresh,
    actif,
    btn2title,
    password,
    update,
    id,
}) {
    const { Option } = Select;
    const [popupvisible, setpopupvisible] = React.useState(false);
    const [popupresult, setpopupresult] = useState(false);
    const [load, setLoad] = useState(false);
    const [userrole, setRole] = useState('');
    const [userid, setId] = useState('');
    const regPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=(.*\d){2}).{8,72}$/;
    const [options, setOptions] = React.useState([]);

    const formik = useFormik({
        initialValues: {
            lastName,
            firstName,
            role,
            email,
            password,
        },
    });

    let data = {
        lastName,
        firstName,
        role,
    };

    useEffect(() => {
        let user = localStorage.getItem('user-info');
        if (user === null) {
            setRole('none');
            setId('');
        } else {
            user = JSON.parse(user);
            setRole(user.role);
            setId(user.uid);
        }
        let list = [];
        const findAdvisorStatusListValueIndex = (r) => advisorStatusList.findIndex((p) => p.value === r);
        const userroleIndex = userrole === 'dev' ? advisorStatusList.length : findAdvisorStatusListValueIndex(userrole);
        if (userroleIndex !== -1 && userroleIndex >= findAdvisorStatusListValueIndex('manager')) {
            if (userid !== id) {
                list = advisorStatusList.slice(0, userroleIndex);
            } else {
                list = advisorStatusList.slice(userroleIndex, userroleIndex + 1);
            }
        }
        setOptions(list);
    }, [role, userrole, id, userid]);

    useEffect(() => {
        formik.setValues({ ...formik.values, lastName, firstName, email, role, password });
    }, [lastName, firstName, email, role, visible, password]);

    async function deleteuser(id) {
        setLoad(true);
        try {
            await userServiceApi.delete(id).then((response) => {
                if (response.status === 204) {
                    setpopupvisible(false);
                    setpopupresult(true);
                    setRefresh(true);
                } else {
                    message.error('Une erreur est survenue');
                }
            });
        } catch (e) {
            message.error('Une erreur est survenue');
        }
        setLoad(false);
    }

    async function updateuser() {
        // console.log("USER-INFO", JSON.parse(localStorage.getItem("user-info")).role);
        data = {
            lastName: formik.values.lastName,
            firstName: formik.values.firstName,
            role: formik.values.role,
        };
        if (!isEmpty(formik.values.password)) {
            if (regPassword.test(formik.values.password) === true) {
                data.password = formik.values.password;
            } else {
                message.error('mot de passe incorrect');
                return;
            }
        }

        setLoad(true);
        try {
            await userServiceApi.update(id, data).then((response) => {
                if (response.status === 200) {
                    setpopupvisible(false);
                    setpopupresult(true);
                    setRefresh(true);
                } else {
                    message.error('Une erreur est survenue');
                }
            });
        } catch (e) {
            message.error('Une erreur est survenue');
        }
        setLoad(false);
    }
    async function register() {
        if (
            !isEmpty(formik.values.lastName) &&
            !isEmpty(formik.values.password) &&
            !isEmpty(formik.values.role) &&
            !isEmpty(formik.values.firstName) &&
            !isEmpty(formik.values.email)
        ) {
            if (FormValidationRules.isEmail(formik.values.email) && regPassword.test(formik.values.password) === true) {
                const data = {
                    lastName: formik.values.lastName,
                    firstName: formik.values.firstName,
                    email: formik.values.email,
                    role: formik.values.role,
                    password: formik.values.password,
                };
                setLoad(true);
                let ok = false;
                try {
                    await userServiceApi.create(data).then((response) => {
                        if (response.status === 201) {
                            setpopupresult(true);
                            setRefresh(true);
                            ok = true;
                        } else {
                            message.error('Une erreur est survenue');
                        }
                    });
                } catch (e) {
                    message.error('Une erreur est survenue');
                }
                setLoad(false);
                if (ok) {
                    formik.values.lastName = '';
                    formik.values.firstName = '';
                    formik.values.email = '';
                    formik.values.role = '';
                    formik.values.password = '';
                }
            } else {
                message.error('E-mail ou mot de passe incorrect');
            }
        } else {
            message.error('Merci de compléter tous les champs');
        }
    }
    return (
        <>
            <Modal
                bodyStyle={{ height: 600 }}
                centered
                visible={visible}
                onCancel={handleClose}
                title={
                    <div>
                        <h2 style={{ fontWeight: 'bold', color: '#CB5D4B', marginTop: 5, textAlign: 'center', fontSize: 20 }}>{title}</h2>
                        <h3 style={{ textAlign: 'center', fontSize: 14, margin: '0 auto' }}>{description}</h3>
                    </div>
                }
                closeIcon="Fermer"
                wrapClassName="modal"
                footer={[
                    <Row align="middle" justify="center" style={{ marginTop: -20, marginBottom: 20 }} gutter={16} key="ok">
                        <Col>
                            {userid !== id && (
                                <Button
                                    shape="round"
                                    style={{ margin: 10, borderColor: '#cb5d4b', color: '#cb5d4b', fontWeight: 'bold', height: 40, fontSize: 15 }}
                                    key="back"
                                    onClick={() => setpopupvisible(true)}
                                >
                                    {btn1title}
                                </Button>
                            )}
                        </Col>

                        <Col>
                            <Button
                                shape="round"
                                loading={load}
                                style={{
                                    margin: 10,
                                    borderColor: '#cb5d4b',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    background: '#cb5d4b',
                                    marginTop: 10,
                                    height: 40,
                                    fontSize: 15,
                                }}
                                key="submit"
                                type="submit"
                                onClick={() => register()}
                                justify="space-around"
                            >
                                {btn2title}
                            </Button>
                        </Col>

                        <Col>
                            <Button
                                shape="round"
                                style={{
                                    margin: 10,
                                    borderColor: '#cb5d4b',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    background: '#cb5d4b',
                                    marginTop: 10,
                                    height: 40,
                                    fontSize: 15,
                                }}
                                key="submit"
                                type="submit"
                                onClick={() => updateuser()}
                                justify="space-around"
                            >
                                {update}
                            </Button>
                        </Col>
                    </Row>,
                ]}
            >
                <div className="modal-container">
                    <form className="form-popup">
                        <h3 style={{ color: '#CB5D4B' }}>Droits du conseiller</h3>
                        <br />
                        <label style={{ color: '#CB5D4B', fontWeight: 'bold' }}>Nom du conseiller</label>
                        <Input placeholder="Nom du conseiller" name="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
                        <br />
                        <br />
                        <label style={{ color: '#CB5D4B', paddingTop: 100, fontWeight: 'bold' }}>Prénom du conseiller</label>
                        <Input placeholder="Prénom du conseiller" name="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
                        <br />
                        <br />
                        <label style={{ color: '#CB5D4B', fontWeight: 'bold' }}>E-mail</label>
                        <Input placeholder="E-mail" name="email" value={formik.values.email} onChange={formik.handleChange} disabled={actif} />
                        <br />
                        <br />
                        <label style={{ color: '#CB5D4B', fontWeight: 'bold' }}>Statut</label>
                        <br />
                        <Select
                            suffixIcon={<i className="icon-angle-down"></i>}
                            value={formik.values.role}
                            name="role"
                            onChange={(role) => formik.setFieldValue('role', role)}
                            style={{ width: '100%' }}
                        >
                            {options.map((p, index) => (
                                <Option key={`${p.label}${index}`} value={p.value}>
                                    {p.label}
                                </Option>
                            ))}
                        </Select>
                        <br />
                        <br />
                        <label style={{ color: '#CB5D4B', fontWeight: 'bold' }}>Mot de passe</label>
                        <Input
                            type="Password"
                            value={formik.values.password}
                            name="password"
                            placeholder="Mot de passe"
                            onChange={formik.handleChange}
                        />
                        <label style={{ textAlign: 'center', fontSize: 12, margin: '0 auto' }}>
                            Le mot de passe doit avoir au minimum 8 caractères, au moins 1 majuscule, au moins 1 minuscule et au moins 2 chiffres.
                        </label>
                    </form>
                </div>
            </Modal>
            <ValidationModal message="Opération effectuée avec succès !" handleClik={() => setpopupresult(false)} visible={popupresult} />
            <div>
                <Modal
                    visible={popupvisible}
                    title={
                        <div>
                            <h2 style={{ fontWeight: 'bold', color: '#CB5D4B', marginTop: 5, textAlign: 'center' }}>Confirmation </h2>
                        </div>
                    }
                    onOk={() => setpopupvisible(false)}
                    onCancel={() => setpopupvisible(false)}
                    footer={[
                        <Row align="middle" justify="center" style={{ marginTop: -10, marginRight: 150, width: '100%' }} key="ok">
                            <Col>
                                <Button
                                    shape="round"
                                    style={{ margin: 10, borderColor: '#cb5d4b', color: '#cb5d4b', fontWeight: 'bold' }}
                                    key="back"
                                    onClick={() => setpopupvisible(false)}
                                >
                                    Annuler
                                </Button>
                                <Button
                                    shape="round"
                                    onClick={() => deleteuser(id)}
                                    style={{ margin: 10, borderColor: '#cb5d4b', color: '#fff', fontWeight: 'bold', background: '#cb5d4b' }}
                                    key="submit"
                                >
                                    Valider
                                </Button>
                            </Col>
                        </Row>,
                    ]}
                >
                    <p style={{ textAlign: 'center' }}>{popupmessage}</p>
                </Modal>
            </div>
        </>
    );
}
export { UserManageView };
