import React from 'react';
import { ComponentInput, ComponentSelect, getList } from '../../../../Partials/tools';
import { isEmpty } from 'lodash';
import deadAVDCServiceApi from '../../../../../services/deadAVDCServiceApi';

const SearchDecease = ({ formik, setEnabled, step }) => {
    const [list, setList] = React.useState([]);
    const [search, setSearch] = React.useState(formik.values.fullName);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (isEmpty(search) || search == '') {
            setList([]);
            return;
        }
        setLoading(true);
        let query = `fullName=${search}`;
        deadAVDCServiceApi
            .getByKeys(query)
            .then((response) => {
                const items = deadAVDCServiceApi.getData(response.data);
                setList(getList('id', 'fullName', items));
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [search]);

    React.useEffect(() => {
        setEnabled(true);
        let items = list.filter((p) => p.value == formik.values.fullName);
        if (items.length > 0) {
            formik.setValues({ ...formik.values, name: items[0].title });
        }
        if (isEmpty(formik.values.fullName) && step == 0) {
            setEnabled(false);
        } else {
            setEnabled(true);
        }
    }, [formik.values.fullName]);

    React.useEffect(() => {
        const date = formik.values.deathDate;
        if (date == '' || date == null || date == undefined) {
            setEnabled(false);
        } else {
            setEnabled(true);
        }
    }, [formik.values.deathDate]);
    return (
        <React.Fragment>
            <ComponentInput label="Date de décès" type="date" name={'deathDate'} formik={formik} key="d" />
            <ComponentSelect
                name="fullName"
                search
                formik={formik}
                list={list}
                label="Nom du défunt"
                id="search_decease"
                key="full_name"
                showInput={false}
                placeholder="Rechercher ou ajouter le défunt"
                titleBtn="Ajouter un nouveau défunt"
                setSearch={setSearch}
                loading={loading}
            />
        </React.Fragment>
    );
};

export { SearchDecease };
