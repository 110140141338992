import { Button, Col, Input, Row, Select, Typography } from 'antd';
import React, { useState } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import InputAutoCompletion from '../Partials/InputAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { getThemes } from './Partials/getOrganism';

const { Text } = Typography;

const GeoDivisionFilter = ({ label, onClickFilter, filterGeo, setFilterGeo }) => {
    return (
        <span className="hastooltip">
            <Button onClick={onClickFilter}>
                <Text style={{ fontWeight: 'bold' }}>{label}</Text>
            </Button>
            <span className={filterGeo ? 'tooltip-filters open' : 'tooltip-filters'}>
                <InputAutoCompletion showFilter={setFilterGeo} placeholder={'Code postal'} />
            </span>
        </span>
    );
};

const TownFilter = ({ reset, show, setShow }) => {
    const [value, setValue] = React.useState(null);
    const dispatch = useDispatch();
    const screen = useSelector((state) => state.organism.screen);

    function handleChange(v) {
        setValue(v);
        if (v) {
            if (screen == 'organization') {
                dispatch({
                    type: 'ORGANIZATION_FILTER',
                    payload: `postalName=${v}`,
                });
            } else if (screen == 'pf') {
                dispatch({
                    type: 'PF_FILTER',
                    payload: `townName=${v}`,
                });
            }
        } else {
            if (screen == 'organization') {
                dispatch({
                    type: 'ORGANIZATION_FILTER',
                    payload: ``,
                });
            } else if (screen == 'pf') {
                dispatch({
                    type: 'PF_FILTER',
                    payload: ``,
                });
            }
        }
    }
    React.useEffect(() => {
        if (reset) {
            setValue('');
            setShow(false);
        }
    }, [reset]);

    return (
        <>
            <span className="hastooltip">
                <Button type="text" onClick={() => setShow(!show)}>
                    <Text style={{ fontWeight: 'bold' }} onClick={null}>
                        Ville
                    </Text>
                </Button>

                <span className={show ? 'tooltip-filters open' : 'tooltip-filters'}>
                    <Input
                        style={{ width: '100%', height: 48, backgroundColor: '#fff', border: '1px solid #d9d9d9' }}
                        placeholder="Nom de la ville"
                        value={value}
                        onChange={(v) => handleChange(v.target.value)}
                    />
                </span>
            </span>
        </>
    );
};

const ThemeFilter = ({ label, placeholder, reset, showTextFilter, setShowTextFilter }) => {
    const [list, setList] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [value, setValue] = React.useState(null);
    const dispatch = useDispatch();

    function handleChange(v) {
        setValue(v);
        dispatch({
            type: 'ORGANIZATION_FILTER',
            payload: `theme=${v}`,
        });
    }
    React.useEffect(() => {
        if (reset) {
            setValue(null);
            setShowTextFilter(false);
        }
    }, [reset]);

    React.useEffect(() => {
        getThemes()
            .then((list) => {
                setList(list);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, []);

    return (
        <>
            <span className="hastooltip">
                <Button onClick={() => setShowTextFilter(!showTextFilter)}>
                    <Text style={{ fontWeight: 'bold' }} onClick={null}>
                        {label}
                    </Text>
                </Button>

                <span className={showTextFilter ? 'tooltip-filters open' : 'tooltip-filters'}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={placeholder}
                        optionFilterProp="children"
                        value={value}
                        onChange={(v) => handleChange(v)}
                        loading={loading}
                        autoComplete="false"
                    >
                        {list.map((p) => (
                            <Select.Option value={p.uid} key={`theme${p.uid}`}>
                                {`${p.name}`}
                            </Select.Option>
                        ))}
                    </Select>
                </span>
            </span>
        </>
    );
};

const AlphabeticalFilter = ({ order, setOrder, reset = false }) => {
    const { Text } = Typography;
    const dispatch = useDispatch();
    const [orderFilter, setOrderFilter] = useState(order);
    const screen = useSelector((state) => state.organism.screen);

    React.useEffect(() => {
        if (reset) {
            setOrderFilter('ASC');
        }
    }, [reset]);

    const onChangeOrderFilter = (sort) => {
        setOrderFilter(sort);
        setOrder(sort);
        if (screen == 'organization') {
            dispatch({
                type: 'ORGANIZATION_FILTER',
                payload: `order[name]=${sort}`,
            });
        } else if (screen == 'pf') {
            dispatch({
                type: 'PF_FILTER',
                payload: `order[name]=${sort}`,
            });
        }
    };

    return (
        <>
            {orderFilter === 'DESC' && (
                <Button onClick={() => onChangeOrderFilter('ASC')}>
                    <Text style={{ fontWeight: 'bold' }}>{'A'}</Text>
                    <ArrowLeftOutlined />
                    <Text style={{ fontWeight: 'bold' }}>{'Z'}</Text>
                </Button>
            )}
            {orderFilter === 'ASC' && (
                <Button onClick={() => onChangeOrderFilter('DESC')}>
                    <Text style={{ fontWeight: 'bold' }}>{'A'}</Text>
                    <ArrowRightOutlined />
                    <Text style={{ fontWeight: 'bold' }}>{'Z'}</Text>
                </Button>
            )}
        </>
    );
};

const ResetFilters = ({ label, handleClick }) => {
    return (
        <Button type="text" onClick={handleClick} style={{ fontWeight: 'bold' }}>
            <Text style={{ fontWeight: 'bold' }} underline>
                {label}
            </Text>
        </Button>
    );
};

const OrganismFilters = ({ showTheme = true }) => {
    const [filterGeo, setFilterGeo] = useState(false);
    const [reset, setReset] = useState(false);
    const screen = useSelector((state) => state.organism.screen);
    const dispatch = useDispatch();
    const [showThemeScreen, setShowTheme] = React.useState(false);
    const [showTownScreen, setShowTownScreen] = React.useState(false);
    const elements = [setFilterGeo, setShowTheme, setShowTownScreen];
    const [order, setOrder] = React.useState('ASC');

    React.useEffect(() => {
        if (filterGeo) onClear(0);
    }, [filterGeo]);
    React.useEffect(() => {
        if (showThemeScreen) onClear(1);
    }, [showThemeScreen]);
    React.useEffect(() => {
        if (showTownScreen) onClear(2);
    }, [showTownScreen]);
    React.useEffect(() => {
        onClear(-1);
    }, [order]);

    React.useEffect(() => {
        if (reset) {
            setReset(false);
        }
    }, [reset]);

    function onClear(position) {
        elements.forEach((p, index) => {
            if (position != index) p(false);
        });
    }

    const onClickGeoFilter = () => {
        setFilterGeo(!filterGeo);
    };

    const onResetFilters = () => {
        setReset(true);
        if (screen == 'organization') {
            dispatch({
                type: 'ORGANIZATION_FILTER',
                payload: ``,
            });
        } else if (screen == 'pf') {
            dispatch({
                type: 'PF_FILTER',
                payload: ``,
            });
        }
    };
    return (
        <>
            <Row justify="space-between" className="filters">
                <Col flex="1">
                    <GeoDivisionFilter label={'Code postal'} filterGeo={filterGeo} setFilterGeo={setFilterGeo} onClickFilter={onClickGeoFilter} />
                    <TownFilter reset={reset} setShow={setShowTownScreen} show={showTownScreen} />
                    {showTheme && (
                        <ThemeFilter label="Thème" placeholder="" setShowTextFilter={setShowTheme} showTextFilter={showThemeScreen} reset={reset} />
                    )}
                    <AlphabeticalFilter setOrder={setOrder} order={order} reset={reset} />
                </Col>
                <Col>
                    <ResetFilters label="Réinitialiser tous les filtres" handleClick={onResetFilters} />
                </Col>
            </Row>
        </>
    );
};

export default OrganismFilters;
