import { combineReducers } from 'redux';
import reducerFolder from './reducerFolder';
import reducerOrganism from './reducerOrganism';
import reducerNotification from './reducerNotification';
import reducerUser from './reducerUser';
import reducerAdvisor from './reducerAdvisor';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['notifications', 'user'],
};

const rootReducer = combineReducers({
    organism: reducerOrganism,
    folder: reducerFolder,
    notifications: reducerNotification,
    advisor: reducerAdvisor,
    user: reducerUser,
});

export default persistReducer(persistConfig, rootReducer);
