import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import DateHelper from '../../Helpers/DateHelper';
import GenericHelper from '../../Helpers/GenericHelper';

const Clock = () => {
    const { Title } = Typography;
    const [currentTime, setCurrentTime] = useState(DateHelper.getDate('HH:mm'));
    useEffect(() => {
        const interval = setInterval(() => setCurrentTime(DateHelper.getDate('HH:mm')), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Title level={4} className="clock">
            {currentTime}
        </Title>
    );
};

const HeaderTimer = () => {
    const { Text } = Typography;
    const dateHeader = GenericHelper.getHeaderDateString();
    return (
        <>
            <Clock />
            <Text className="date">{dateHeader}</Text>
        </>
    );
};

export default HeaderTimer;
