import { isEmpty } from 'lodash';

export const advisorStatusList = [
    { value: 'conseiller', label: 'Conseiller' },
    { value: 'assistant_manager', label: 'Assistant manager' },
    { value: 'manager', label: 'Manager' },
    { value: 'proprietaire', label: 'Propriétaire' },
];

class PersonHelper {
    getFullName(firstName, lastName) {
        let response = '';
        if (!isEmpty(firstName)) response += firstName + ' ';
        if (!isEmpty(lastName)) response += lastName;
        return response;
    }

    getAdvisorStatus(value = '') {
        return advisorStatusList.find((p) => p.value === value)?.label ?? value;
    }
}
export default new PersonHelper();
