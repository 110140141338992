import { Calendar } from './components/calendar/calendar';
// import { Documents } from './components/documents/documents';
import { Folders } from './components/folders/folders';
import { Organisms, OrganismView } from './components/organisms/organisms';
import { HomeLanding } from './components/home/homeLanding';
import { Notifications } from './components/notifications/notifications';
import { Management } from './components/management/management';
import { Connexion } from './components/connexion/connexion';
import { FolderView } from './components/folders/folderView';
import { UserManage } from './components/UserManage/UserManage';
import { AddDocScreen } from './components/folders/Partials/Button/FolderAddDocs';
import { ConsultDocScreen } from './components/folders/Partials/Button/FolderConsultDoc';

// Routes
const Routes = [
    {
        name: 'homelanding',
        path: '/',
        label: 'Home landing',
        component: HomeLanding,
        roles: [],
        exact: true,
    },
    {
        name: 'folders',
        path: '/folders',
        label: 'Folders',
        component: Folders,
        roles: [],
        exact: true,
    },
    // {
    //     name: 'documents',
    //     path: '/documents',
    //     label: 'Documents',
    //     component: Documents,
    //     roles: [],
    //     exact: true,
    // },
    {
        name: 'calendar',
        path: '/calendar',
        label: 'Calendar',
        component: Calendar,
        roles: [],
        exact: true,
    },
    {
        name: 'organisms',
        path: '/organisms',
        label: 'Organisms',
        component: Organisms,
        roles: [],
        exact: false,
    },
    {
        name: 'notifications',
        path: '/notifications',
        label: 'Notifications',
        component: Notifications,
        roles: [],
        exact: false,
    },
    {
        name: 'management',
        path: '/management',
        label: 'Pilotage',
        component: Management,
        roles: [],
        exact: false,
    },
    {
        name: 'connexion',
        path: '/connexion',
        label: 'Connexion',
        component: Connexion,
    },
    {
        name: 'folderView',
        path: '/folders/:uid/view',
        label: '',
        component: FolderView,
        roles: [],
        exact: false,
    },

    {
        name: 'UserManage',
        path: '/userManage',
        label: 'UserManage',
        component: UserManage,
        roles: [],
        exact: true,
    },
    {
        name: 'addDocs',
        path: '/docs/:uid/create',
        label: 'Ajouter des documents',
        component: AddDocScreen,
        roles: [],
        exact: true,
    },
    {
        name: 'consultDocs',
        path: '/docs/:uid/consult',
        label: 'Consulter des documents',
        component: ConsultDocScreen,
        roles: [],
        exact: true,
    },
    {
        name: 'OrganismView',
        path: '/organism/:screen/:uid/view',
        label: "Voir l'organisme",
        component: OrganismView,
        roles: [],
        exact: true,
    },
];
export default Routes;
