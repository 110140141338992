import axios from 'axios';

const config = require('../config');

export default axios.create({
    baseURL: config.URL_API,
    headers: {
        'Content-Type': 'application/json',
    },
});
