import { Pagination } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizations, getPF } from './getOrganism';

function OrganizationPagination() {
    const page = useSelector((state) => state.organism.pageOrganization);
    const total = useSelector((state) => state.organism.totalOrganization);
    const loading = useSelector((state) => state.organism.loadingOrganization);
    const filter = useSelector((state) => state.organism.filterOrganization);
    const dispatch = useDispatch();
    const [change, setChange] = React.useState(false);
    const [init, setInit] = React.useState(true);

    function handlePage(v) {
        setChange(true);
        dispatch({
            type: 'ORGANIZATION_PAGE',
            payload: v,
        });
    }

    React.useEffect(async () => {
        if (page != 1 || change) {
            dispatch({
                type: 'ORGANIZATION_LOADING',
                payload: true,
            });
            const rep = await getOrganizations(page, filter);
            dispatch({
                type: 'ORGANIZATION_TOTAL',
                payload: rep.total,
            });
            dispatch({
                type: 'ORGANISM_SEARCH',
                payload: rep.list,
            });
            dispatch({
                type: 'ORGANIZATION_LOADING',
                payload: false,
            });
            setChange(false);
        }
        setInit(false);
    }, [page]);

    React.useEffect(async () => {
        if (init == false) {
            dispatch({
                type: 'ORGANIZATION_LOADING',
                payload: true,
            });
            dispatch({
                type: 'ORGANIZATION_PAGE',
                payload: 1,
            });
            const rep = await getOrganizations(1, filter);
            dispatch({
                type: 'ORGANIZATION_TOTAL',
                payload: rep.total,
            });
            dispatch({
                type: 'ORGANISM_SEARCH',
                payload: rep.list,
            });
            dispatch({
                type: 'ORGANIZATION_LOADING',
                payload: false,
            });
            setChange(false);
        }
        setInit(false);
    }, [filter]);

    return (
        <React.Fragment>
            {total > 10 && (
                <Pagination
                    style={{ padding: 10 }}
                    total={total}
                    pageSize={10}
                    defaultCurrent={1}
                    current={page}
                    onChange={(v) => handlePage(v)}
                    showSizeChanger={false}
                    disabled={loading}
                    className="pagination"
                />
            )}
        </React.Fragment>
    );
}

function PfPagination() {
    const page = useSelector((state) => state.organism.pagePF);
    const total = useSelector((state) => state.organism.totalPF);
    const loading = useSelector((state) => state.organism.loadingPF);
    const filter = useSelector((state) => state.organism.filterPF);
    const dispatch = useDispatch();
    const [change, setChange] = React.useState(false);
    const [init, setInit] = React.useState(true);

    function handlePage(v) {
        setChange(true);
        dispatch({
            type: 'PF_PAGE',
            payload: v,
        });
    }

    React.useEffect(async () => {
        if (page != 1 || change) {
            dispatch({
                type: 'PF_LOADING',
                payload: true,
            });
            const rep = await getPF(page, filter);
            dispatch({
                type: 'PF_TOTAL',
                payload: rep.total,
            });
            dispatch({
                type: 'PF_SEARCH',
                payload: rep.list,
            });
            dispatch({
                type: 'PF_LOADING',
                payload: false,
            });
            setChange(false);
        }
        setInit(false);
    }, [page]);

    React.useEffect(async () => {
        if (init == false) {
            dispatch({
                type: 'PF_LOADING',
                payload: true,
            });
            dispatch({
                type: 'PF_PAGE',
                payload: 1,
            });
            const rep = await getPF(1, filter);
            dispatch({
                type: 'PF_TOTAL',
                payload: rep.total,
            });
            dispatch({
                type: 'PF_SEARCH',
                payload: rep.list,
            });
            dispatch({
                type: 'PF_LOADING',
                payload: false,
            });
            setChange(false);
        }
        setInit(false);
    }, [filter]);

    return (
        <React.Fragment>
            {total > 10 && (
                <Pagination
                    style={{ padding: 10 }}
                    total={total}
                    pageSize={10}
                    defaultCurrent={1}
                    current={page}
                    onChange={(v) => handlePage(v)}
                    showSizeChanger={false}
                    disabled={loading}
                    className="pagination"
                />
            )}
        </React.Fragment>
    );
}

export { OrganizationPagination, PfPagination };
