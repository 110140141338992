// Layout and UI
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import HeaderTitle from '../Partials/HeaderTitle';
import folderServiceApi from '../../services/folderServiceApi';

// Statistiques
const StatsItem = ({ item }) => {
    const [statsNumber, setStatsNumber] = useState(null);
    useEffect(() => {
        const params = item.params;
        folderServiceApi
            .getByKeys(params.q1 + '&itemsPerPage=0')
            .then((response) => {
                const x = response.data['hydra:totalItems'];
                if (params.q2 !== undefined) {
                    folderServiceApi
                        .getByKeys(params.q2 + '&itemsPerPage=0')
                        .then((response) => {
                            const y = response.data['hydra:totalItems'];
                            if (params.rate) {
                                const rate = y != 0 ? Math.round((x / y) * 100) : 0;
                                setStatsNumber(rate + '%');
                            } else {
                                setStatsNumber(x + y);
                            }
                        })
                        .catch((e) => console.warn('Error ', e));
                } else {
                    setStatsNumber(x);
                }
            })
            .catch((e) => console.warn('Error ', e));
    }, [item]);

    return (
        <>
            <Col style={{ padding: 12 }} span={item.sizeCell} className="item">
                <div className="container">
                    <span className="label">{item.label}</span>
                    <span className={`number${item.warning ? ' warning' : ''} `}>{statsNumber}</span>
                </div>
            </Col>
        </>
    );
};

const StatsBlock = ({ title, size, statsInfos }) => {
    return (
        <Col span={size} className="stats">
            <div className="box">
                <HeaderTitle title={title} />
                <Row style={{ padding: 12 }} className="row">
                    {statsInfos.map((item, key) => {
                        return <StatsItem key={key} item={item} />;
                    })}
                </Row>
            </div>
        </Col>
    );
};

export default StatsBlock;
