import { Button, Modal, notification, Select } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import folderServiceApi from '../../../../services/folderServiceApi';
import { getFolder } from '../getFolders';
import { Header } from '../../../Partials/tools';
import { canEditFolder } from '../advisorTreatment';

function PaymentStatus({ value = {} }) {
    const list = [
        { uid: null, value: 'NA' },
        { uid: 'not_paid', value: 'Non payé' },
        { uid: 'paying', value: 'En cours de paiement' },
        { uid: 'paid', value: 'Payé' },
    ];
    const { Option } = Select;
    const [visible, setVisible] = React.useState(false);
    const [uid, setUid] = React.useState(value.paymentState);
    const [title, setTitle] = React.useState('');
    const [confirmProgress, setConfirmProgress] = React.useState(false);
    let currentvalue = value.paymentState;
    const dispatch = useDispatch();

    const handleValidate = async () => {
        setConfirmProgress(true);
        currentvalue = uid;
        const data = new Object();
        data.paymentState = currentvalue;
        await folderServiceApi
            .update(value.uid, data)
            .then(async (response) => {
                const p = list.findIndex((x) => x.uid === currentvalue);
                if (p != -1) {
                    setTitle(list[p].value);
                }
                const rep = await getFolder(response.data.uid);
                setConfirmProgress(false);
                setVisible(false);
                dispatch({ type: 'CURRENT_FOLDER', payload: rep.value });
            })
            .catch(() => {
                setConfirmProgress(false);
            });
    };

    const handleShow = () => {
        if (canEditFolder(value)) {
            setVisible(true);
        } else {
            notification.open({
                message: 'Vous ne pouvez pas modifier ce dossier',
                type: 'warning',
            });
        }
    };
    const handleClose = () => {
        setVisible(false);
    };
    const handleChange = (id) => {
        setUid(id);
    };
    React.useEffect(() => {
        setConfirmProgress(false);
        const p = list.findIndex((x) => x.uid === uid);
        if (p != -1) {
            setTitle(list[p].value);
        }
    }, []);

    return (
        <React.Fragment>
            <Button
                title="Cliquez pour modifier"
                onClick={handleShow}
                style={{ fontWeight: 'bold', marginLeft: 0, paddingLeft: 0, background: 'transparent' }}
                type="text"
            >
                {title}
            </Button>
            <Modal
                visible={visible}
                onCancel={handleClose}
                confirmLoading={confirmProgress}
                onOk={handleValidate}
                cancelButtonProps={{ hidden: true }}
                closeIcon="Fermer"
                wrapClassName="modal"
                okText="Modifier"
                okButtonProps={{
                    style: {
                        background: '#cb5d4b',
                        borderColor: '#cb5d4b',
                        borderRadius: 20,
                        height: 40,
                        marginTop: 21,
                        fontSize: 16,
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold',
                    },
                }}
            >
                <Header title="Statut du paiement" subtitle="Vous pouvez modifier le statut de paiement" className="header" />
                <Select value={uid} style={{ width: '100%' }} showArrow onChange={(v) => handleChange(v)}>
                    {list.map((item) => (
                        <Option key={`payment${item.uid}`} value={item.uid}>
                            {item.value}
                        </Option>
                    ))}
                </Select>
            </Modal>
        </React.Fragment>
    );
}

export { PaymentStatus };
