import { Row, Col, message } from 'antd';
import React, { useEffect } from 'react';
import { Button, Input } from 'antd';
const { default: Text } = require('antd/lib/typography/Text');
import auth from '../../services/authServiceApi';
import { useHistory } from 'react-router';
import userServiceApi from '../../services/userServiceApi';
import { useFormik } from 'formik';
import FormValidationRules from '../organisms/FormValidationRules';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';

const Connexion = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);

    // disconnect
    useEffect(() => {
        auth.logout();
        localStorage.removeItem('user-info');
        dispatch({ type: 'NOTIFICATIONS_LIST', payload: [] });
        dispatch({ type: 'USER_ID', payload: '' });
        dispatch({ type: 'USER_FIRSTNAME', payload: '' });
    }, []);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: FormValidationRules.signinFormValidationRule(),
    });
    const hist = useHistory();
    const signin = async () => {
        setLoading(true);
        let result = await auth.token(formik.values.email, formik.values.password);
        if (result === true) {
            const roles = jwt_decode(auth.getToken()).roles;
            if (roles[0] === 'ROLE_DEV') {
                localStorage.setItem('user-info', JSON.stringify({ role: 'dev' }));
                hist.push('/');
                return;
            }
            if (roles[0] !== 'ROLE_BO') {
                message.error('Utilisateur non-BO');
                return;
            }
            localStorage.setItem('user-info', JSON.stringify({ role: roles[1].replace(/^ROLE_BO_/, '').toLowerCase() }));
            await userServiceApi
                .findByEmail(formik.values.email)
                .then((response) => {
                    const user = userServiceApi.getData(response.data)[0];
                    if (!user) {
                        message.error('Utilisateur BO non trouvé');
                        localStorage.removeItem('user-info');
                        return;
                    }
                    localStorage.setItem('user-info', JSON.stringify(user));
                    hist.push('/');
                    dispatch({ type: 'USER_ID', payload: user['@id'] });
                    dispatch({ type: 'USER_FIRSTNAME', payload: user.firstName });
                })
                .catch(() => {});
        } else {
            message.error('Email ou mot de passe incorrect');
        }
        setLoading(false);
    };
    return (
        <Row className="connexion">
            <Col className="connect-container-centred">
                <div className="container">
                    <img src="https://assets.funecap.org/ihm/serenicare/serenicare-logo-connect.png" alt="logo serenicare" />
                    <form className="form" onSubmit={(e) => e.preventDefault()}>
                        <div className="form-popup">
                            <Row className="form-bloc">
                                <Text style={{ color: '#fff', fontWeight: 'bold' }}>Adresse mail</Text>
                                <Input
                                    name="email"
                                    placeholder="Saisissez votre Adresse mail"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />
                            </Row>
                            <Row className="form-bloc">
                                <Text style={{ color: '#fff', fontWeight: 'bold' }}>Mot de passe</Text>
                                <Input
                                    name="password"
                                    type="password"
                                    placeholder="Saisissez votre mot de passe"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                />
                            </Row>
                            <Row className="form-bloc">
                                <Button loading={loading} onClick={() => signin()} type="submit" className="button red" style={{ marginTop: 40 }}>
                                    Se connecter
                                </Button>
                            </Row>
                        </div>
                    </form>
                </div>
            </Col>
        </Row>
    );
};

export { Connexion };
