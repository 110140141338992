import { Col, Row } from 'antd';
import React from 'react';
import FolderFilters from '../folders/FolderFilters';
import ListFolders from '../folders/ListFolder';
import { Stats } from '../stats/Stats';
// import ButtonAction from '../Partials/ButtonAction';
import HeaderTitle from '../Partials/HeaderTitle';

const HomeLanding = () => {
    return (
        <>
            <FolderFilters screen="home" />
            <div className="box withTitle">
                <HeaderList />
                <ListFolders home={true} />
            </div>
            <Stats />
        </>
    );
};

const HeaderList = () => {
    return (
        <>
            <Row justify="space-between">
                <Col span={12}>
                    <HeaderTitle title="Mes dossiers" />
                </Col>
                {/* <Col span={4}>
                    <ButtonAction label={'Nouveau dossier'} />
                </Col> */}
            </Row>
        </>
    );
};
export { HomeLanding };
