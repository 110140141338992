import React from 'react';
import { ComponentInput, ComponentSelect, getList } from '../../../../Partials/tools';
import ContactDataApi from '../../../../../services/contactServiceApi';
import { isEmpty } from 'lodash';

const SearchContact = ({ formik, setEnabled, step }) => {
    const [list, setList] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState(formik.values.fullName);

    React.useEffect(() => {
        if (isEmpty(search) || search == '') {
            setList([]);
            return;
        }
        setLoading(true);
        const fullName = formik.values.fullName;
        const email = formik.values.email;
        let query = `fullName=${fullName}`;
        if (!isEmpty(email)) {
            query += `&email=${email}`;
        }
        ContactDataApi.getByKeys(query)
            .then((response) => {
                const items = ContactDataApi.getData(response.data);
                setList(getList('@id', 'fullName', items));
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [search]);

    React.useEffect(() => {
        let items = list.filter((p) => p.value == formik.values.fullName);
        if (items.length > 0) {
            formik.setValues({ ...formik.values, name: items[0].title });
        }
        if ((isEmpty(formik.values.fullName) || isEmpty(formik.values.email)) && step == 3) {
            //setEnabled(false);
        } else {
            setEnabled(true);
        }
    }, [formik.values.fullName]);

    return (
        <React.Fragment>
            <ComponentInput label="L'email du contact" type="email" name={'email'} formik={formik} />
            <ComponentSelect
                name="fullName"
                loading={loading}
                search
                formik={formik}
                list={list}
                label="Nom du contact"
                id="secC"
                key="nameC"
                showInput
                placeholder="Rechercher ou ajouter le contact"
                titleBtn="Ajouter un nouveau contact"
                setSearch={setSearch}
            />
        </React.Fragment>
    );
};
export { SearchContact };
