import React from 'react';
import { ComponentInput, ComponentRadio, ComponentSelect } from '../../../../Partials/tools';

function CompleteContact({ formik, organizations = [], setPfName, loading = false, postalCodes = [], loadPostal = false, setPostalCode }) {
    return (
        <React.Fragment>
            <ComponentRadio
                id="civil"
                label="Civilité"
                list={[
                    { value: 'Mme', title: 'Madame' },
                    { value: 'M', title: 'Monsieur' },
                ]}
                key="civility"
                formik={formik}
                name="civility"
            />
            <ComponentInput label="Nom" placeholder="le nom du client" key="last_name" type="text" formik={formik} name="lastName" />
            <ComponentInput label="Prénom" placeholder="le prénom du client" key="first_name" type="text" formik={formik} name="firstName" />
            <ComponentInput
                label="Lien de parenté avec le défunt"
                placeholder=""
                key="relationship"
                type="text"
                formik={formik}
                name="relationship"
            />
            <ComponentInput label="Adresse" placeholder="" key="address" type="text" formik={formik} name="address" />
            <ComponentSelect
                label="Code postal"
                search
                key="postal_code"
                list={postalCodes}
                formik={formik}
                setSearch={setPostalCode}
                name="postalCode"
                loading={loadPostal}
            />
            <ComponentInput label="Pays" enabled={false} key="country" type="text" formik={formik} name="country" />
            <ComponentInput label="N° de sécurité sociale" placeholder="" key="social_umber" type="text" formik={formik} name="socialNumber" />
            <ComponentInput label="E-mail" key="complete_email" type="email" formik={formik} name="email" />
            <ComponentInput label="Téléphone" placeholder="" key="phone" type="tel" formik={formik} name="phone" />
            <ComponentSelect
                id="payment"
                formik={formik}
                name="payment"
                key="payment"
                label="Règlement"
                list={[
                    { value: 'paygreen', title: 'Paygreen' },
                    { value: 'PF', title: 'PF' },
                    { value: 'none', title: 'Aucun paiement' },
                ]}
            />
            <ComponentSelect
                id="organization"
                formik={formik}
                name="organization"
                key="organization"
                label="Organisme de prise en charge"
                list={organizations}
                setSearch={setPfName}
                loading={loading}
                search
            />
            <ComponentInput label="Nom du conseiller funéraire" placeholder="" key="counselor" type="text" formik={formik} name="counselorName" />
        </React.Fragment>
    );
}

export { CompleteContact };
