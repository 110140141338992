import { Button } from 'antd';
import React from 'react';

const AddContact = ({ handleAddContact }) => {
    return (
        <div className="center-el">
            <Button shape="round" className="button secondary small" onClick={handleAddContact}>
                <i className="left icon-plus--new-add"></i>
                Ajouter un contact secondaire
            </Button>
        </div>
    );
};

export default AddContact;
