const USER_FIRSTNAME = 'USER_FIRSTNAME';
const USER_ID = 'USER_ID';
const USER_STATS_FILTER = 'USER_STATS_FILTER';

const initialState = {
    id: '',
    firstname: '',
    filters: '',
};

export default function reducerUser(state = initialState, action) {
    switch (action.type) {
        case USER_FIRSTNAME:
            return { ...state, firstname: action.payload };
        case USER_ID:
            return { ...state, id: action.payload };
        case USER_STATS_FILTER:
            return { ...state, filters: action.payload };
        default:
            return state;
    }
}
