const ADD_FOLDER = 'ADD_FOLDER';
const CURRENT_FOLDER = 'CURRENT_FOLDER';
const SEARCH_FOLDER = 'SEARCH_FOLDER';
const SEARCH_ALL_FOLDER = 'SEARCH_ALL_FOLDER';
const UPDATE_FOLDER_MENU = 'UPDATE_FOLDER_MENU';
const COUNSELOR_FOLDER = 'COUNSELOR_FOLDER';
const COUNSELOR_ALL_FOLDER = 'COUNSELOR_ALL_FOLDER';
const COUNSELOR_PAGE = 'COUNSELOR_PAGE';
const COUNSELOR_FILTER = 'COUNSELOR_FILTER';
const COUNSELOR_SEARCH = 'COUNSELOR_SEARCH';
const COUNSELOR_TOTAL = 'COUNSELOR_TOTAL';
const FOLDER_PAGE = 'FOLDER_PAGE';
const FOLDER_FILTER = 'FOLDER_FILTER';
const FOLDER_SEARCH = 'FOLDER_SEARCH';
const FOLDER_TOTAL = 'FOLDER_TOTAL';
const FOLDER_LOADING = 'FOLDER_LOADING';
const COUNSELOR_LOADING = 'COUNSELOR_LOADING';

const initialState = {
    folders: [],
    getallfolders: [],
    currentFolder: {},
    newFolder: {},
    isFolderMenu: 'home',
    getAllCounselorFolders: [],
    getCounselorFolders: [],
    filterHome: '',
    searchHome: '',
    pageHome: 1,
    totalHome: 0,
    filterFolder: '',
    searchFolder: '',
    pageFolder: 1,
    totalFolder: 0,
    loadingFolder: false,
    loadingCounselor: false,
};

export default function reducerFolder(state = initialState, action) {
    switch (action.type) {
        case ADD_FOLDER:
            return { ...state, folders: [...state.folders, action.payload] };
        case CURRENT_FOLDER:
            return { ...state, currentFolder: action.payload };
        case SEARCH_FOLDER:
            return { ...state, folders: action.payload };
        case UPDATE_FOLDER_MENU:
            return { ...state, isFolderMenu: action.payload };
        case SEARCH_ALL_FOLDER:
            return { ...state, getallfolders: action.payload };
        case COUNSELOR_ALL_FOLDER:
            return { ...state, getAllCounselorFolders: action.payload };
        case COUNSELOR_FOLDER:
            return { ...state, getCounselorFolders: action.payload };
        case COUNSELOR_PAGE:
            return { ...state, pageHome: action.payload };
        case COUNSELOR_TOTAL:
            return { ...state, totalHome: action.payload };
        case COUNSELOR_FILTER:
            return { ...state, filterHome: action.payload };
        case COUNSELOR_SEARCH:
            return { ...state, searchHome: action.payload };
        case FOLDER_PAGE:
            return { ...state, pageFolder: action.payload };
        case FOLDER_TOTAL:
            return { ...state, totalFolder: action.payload };
        case FOLDER_FILTER:
            return { ...state, filterFolder: action.payload };
        case FOLDER_SEARCH:
            return { ...state, searchFolder: action.payload };
        case FOLDER_LOADING:
            return { ...state, loadingFolder: action.payload };
        case COUNSELOR_LOADING:
            return { ...state, loadingCounselor: action.payload };
        default:
            return state;
    }
}
