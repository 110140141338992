const { default: Text } = require('antd/lib/typography/Text');
import React from 'react';

const StyledLabel = ({ label }) => {
    const style = { display: 'block', marginTop: 10 };
    return (
        <label style={style}>
            <Text style={{ color: '#CB5D4B', fontWeight: 'bold' }}>{label}</Text>
        </label>
    );
};

export default StyledLabel;
