import { Row, Typography } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const HeaderViewFolder = () => {
    const { Text } = Typography;
    const label = 'Retour aux dossiers';
    const hist = useHistory();

    return (
        <Row justify="space-between" style={{ margin: 8 }}>
            <Link to="#" style={{ fontWeight: 'bold', color: '#222' }} onClick={() => hist.goBack()}>
                <LeftOutlined />
                <Text style={{ padding: 10, paddingLeft: 5, fontWeight: 'bolder' }} underline>
                    {label}
                </Text>
            </Link>
        </Row>
    );
};

export default HeaderViewFolder;
