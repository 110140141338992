import React, { useState, useEffect } from 'react';
import { Col, Row, List, Typography, Button } from 'antd';
import userServiceApi from '../../services/userServiceApi';
import { useDispatch, useSelector } from 'react-redux';
import { UserManageView } from './UserManageView';
import PersonHelper from '../../Helpers/PersonHelper';
import { canEditUser } from '../folders/Partials/advisorTreatment';

function UserManage() {
    const result = useSelector((state) => state.advisor.advisors);
    const [list, setList] = useState([]);
    const [initList, setInitList] = useState(false);
    const dispatch = useDispatch();
    const [visible, setvisible] = React.useState(false);
    const [visibleajout, setvisibleajout] = React.useState(false);
    const [item, setItem] = React.useState({});
    const [refresh, setRefresh] = React.useState(false);

    const getAllUsers = () => {
        userServiceApi
            .getAll()
            .then((response) => {
                let data = [];
                if (response.status === 200) {
                    data = userServiceApi.getData(response.data);
                }
                setList(data);
                dispatch({ type: 'SEARCH_ADVISOR', payload: data });
            })
            .catch((e) => console.warn('Error ', e));
    };
    useEffect(() => {
        if (refresh === true) {
            setvisibleajout(false);
            setvisible(false);
            getAllUsers();
            setRefresh(false);
        }
    }, [refresh]);
    useEffect(() => {
        if (!initList) {
            getAllUsers();
            setInitList(true);
        } else {
            setList(result);
        }
    }, [result]);

    const styles = {
        padding: 5,
        textAlign: 'left',
    };
    function handleClick(p) {
        setvisible(true);
        setItem(p);
    }
    function handleClose() {
        setvisible(false);
    }

    return (
        <>
            <div className="box list_advisors">
                <Row align="middle" justify="space-between" style={{ marginTop: 20 }}>
                    <Col flex="1">
                        <h2 className="title" style={{ fontWeight: 'bold', color: '#CB5D4B', margin: '20px 0px 20px 15px' }}>
                            {' '}
                            Gestion des droits{' '}
                        </h2>
                    </Col>
                    <Col>
                        <Button shape="round" style={{ marginRight: 15 }} className="button" onClick={() => setvisibleajout(true)}>
                            <i className="left icon-plus--new-add"></i>
                            Nouveau conseiller
                        </Button>
                    </Col>
                </Row>

                <List
                    size="large"
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(advisor) => (
                        <List.Item key={advisor.uid}>
                            <Row align="middle" justify="start" gutter={8} style={{ width: '100%' }}>
                                <Col span={8}>
                                    <Typography.Title level={5} style={styles}>
                                        {advisor.firstName + ' ' + advisor.lastName}
                                    </Typography.Title>
                                </Col>
                                <Col span={8}>
                                    <Typography.Title level={5} style={styles}>
                                        {advisor.email}
                                    </Typography.Title>
                                </Col>
                                <Col flex={1}>
                                    <Typography.Title level={5} style={styles}>
                                        {PersonHelper.getAdvisorStatus(advisor.role)}
                                    </Typography.Title>
                                </Col>
                                <Col style={{ display: 'flex', alignItems: 'center' }}>
                                    {canEditUser(advisor) && (
                                        <i
                                            className="icon-modification"
                                            onClick={() => handleClick(advisor)}
                                            style={{ color: '#cb5d4b', fontSize: 20 }}
                                        ></i>
                                    )}
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
                <UserManageView
                    visible={visible}
                    title="Modification des droits"
                    description="Vous pouvez modifier les droits d’administration de votre conseiller"
                    btn1title="Supprimer"
                    update="Enregistrer"
                    email={item.email}
                    popupmessage="Voulez-vous vraiment supprimer ce conseiller?"
                    lastName={item.lastName}
                    firstName={item.firstName}
                    password={item.password}
                    setRefresh={setRefresh}
                    key="modal-update"
                    handleClose={handleClose}
                    actif="disabled"
                    role={item.role}
                    id={item.uid}
                />
                <UserManageView
                    visible={visibleajout}
                    title="Ajouter un conseiller"
                    description="Vous pouvez ajouter un conseiller"
                    btn2title="Enregistrer"
                    popupmessage="Voulez-vous annuler l'ajout d'un conseiller?"
                    key="modal-insert"
                    handleClose={() => setvisibleajout(false)}
                    setRefresh={setRefresh}
                    email=""
                    password=""
                    role="conseiller"
                />
                <div />
            </div>
        </>
    );
}

export { UserManage };
