import { Col, List, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationHelper from '../../Helpers/NotificationHelper';
const LinkToNotifications = ({ count, resetCounter, clickShowHideNotifs }) => {
    switch (count) {
        case 1:
            return (
                <Link
                    to="/notifications"
                    onClick={() => {
                        resetCounter();
                        clickShowHideNotifs();
                    }}
                    className="see-more"
                >
                    Voir la notification
                </Link>
            );
        default:
            return (
                <Link
                    to="/notifications"
                    onClick={() => {
                        resetCounter();
                        clickShowHideNotifs();
                    }}
                    className="see-more"
                >
                    Voir toutes les notifications
                </Link>
            );
    }
};

const TitleNotifications = ({ count, totalNotifs }) => {
    switch (count) {
        case 0:
            return <span className="title-top">Notifications</span>;
        case 1:
            return <span className="title-top">Vous avez 1 notification</span>;
        default:
            return <span className="title-top">Vous avez {totalNotifs > 30 ? totalNotifs : count} notifications</span>;
    }
};

const ItemNotification = ({ data, type }) => {
    return (
        <Link to={`${data.folder}/view`}>
            <List.Item onClick={(event) => console.log(data, event)} key={data.uid}>
                <Row className="row">
                    <Col className="title-item" span={17}>
                        {type}
                    </Col>
                    <Col className="date-item" span={7}>
                        {NotificationHelper.formatDate(data.sysUpdatedDate)}
                    </Col>
                    <Col span={24}>
                        <span className="name">{NotificationHelper.displayFullName(data, data['@type'], true)}</span>
                    </Col>
                </Row>
            </List.Item>
        </Link>
    );
};

const ListNotifications = ({ data = [] }) => {
    const list = data.filter((p, index) => index < 3 && p.uid != null);
    return (
        <>
            <List
                size="large"
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(folder) => (
                    <ItemNotification className="notifications-items" data={folder} type={NotificationHelper.getTypeValue(folder.type)} />
                )}
            />
        </>
    );
};

const NotificationsPanel = ({ count, showNotifications, clickShowHideNotifs, totalNotifs, totalCount }) => {
    const notificationsList = useSelector((state) => state.notifications.notiticationsList);
    const dispatch = useDispatch();
    const onHandleClick = () => {
        dispatch({ type: 'NOTIFICATIONS_TOTAL', payload: totalCount });
        dispatch({ type: 'NOTIFICATIONS_ALREADY_READ', payload: 0 });
    };

    return (
        <>
            <div className={showNotifications === true ? 'notification-box show' : 'notification-box hide'} align="center">
                <div className="container-panel">
                    <TitleNotifications count={count} totalNotifs={totalNotifs} />
                    <ListNotifications data={notificationsList} />
                    <LinkToNotifications count={count} clickShowHideNotifs={clickShowHideNotifs} resetCounter={onHandleClick} />
                </div>
            </div>
        </>
    );
};

export default NotificationsPanel;
