import { Button } from 'antd';
import React from 'react';

const styles = {
    btn1: {
        marginBottom: 10,
        borderColor: '#cb5d4b',
        color: '#cb5d4b',
        fontWeight: 'bold',
    },
    btn2: {
        margin: 10,
        borderColor: '#675e51',
        background: '#675e51',
        color: '#fff',
        fontWeight: 'bold',
    },
    btn3: {
        margin: 10,
        borderColor: '#cb5d4b',
        color: '#fff',
        fontWeight: 'bold',
        background: '#cb5d4b',
    },
};

const FolderButton = ({ title, type = 1, onClick, loading = false, enabled = true }) => (
    <React.Fragment>
        <Button
            className="cta medium"
            shape="round"
            title={title}
            style={type == 1 ? styles.btn1 : type == 2 ? styles.btn2 : styles.btn3}
            color={type == 1 ? '#cb5d4b' : '#675e51'}
            size="large"
            onClick={onClick}
            loading={loading}
            disabled={!enabled}
        >
            {title}
        </Button>
    </React.Fragment>
);

export { FolderButton };
