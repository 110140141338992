import http from './apiConfig';

const source = 'folder_fo_document_category_group_items';

class FoDocumentGroupItemApi {
    getAll() {
        return http.get(source);
    }

    get(id) {
        return http.get(`${source}/${id}`);
    }

    create(data) {
        return http.post(source, data);
    }

    update(id, data) {
        return http.put(`${source}/${id}`, data);
    }

    delete(id) {
        return http.delete(`${source}/${id}`);
    }

    getData(data) {
        if (data['hydra:member']) return data['hydra:member'];
        return [];
    }

    getByKeys(queryParam) {
        return http.get(`${source}?${queryParam}`);
    }
}

export default new FoDocumentGroupItemApi();
