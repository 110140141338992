import CommentServiceApi from '../../../services/CommentServiceApi';
import contactServiceApi from '../../../services/contactServiceApi';
import deadAVDCServiceApi from '../../../services/deadAVDCServiceApi';
import DeadServiceApi from '../../../services/DeadServiceApi';
import folderBoCategory from '../../../services/folderBoCategory';
import folderBoDocument from '../../../services/folderBoDocument';
import folderFoDocument from '../../../services/folderFoDocument';
import folderFoDocumentCategory from '../../../services/folderFoDocumentCategory';
import folderFoDocumentCategoryGroups from '../../../services/folderFoDocumentCategoryGroups';
import folderFoDocumentCatGroupItems from '../../../services/folderFoDocumentCatGroupItems';
import folderServiceApi from '../../../services/folderServiceApi';
import organismAVDCServiceApi from '../../../services/organismAVDCServiceApi';
import refServiceApi from '../../../services/refServiceApi';
import { isEmpty } from 'lodash';

async function getFolder(uid) {
    let value = {};
    let state = false;
    await folderServiceApi
        .get(uid)
        .then((response) => {
            value = response.data;
        })
        .catch(() => {
            state = false;
        });
    value.dead_complements = await getDecease(value.dead);
    if (value.counselor != null) {
        await DeadServiceApi.getUri(value.counselor + '?evenDeleted=true')
            .then((response) => {
                value.user = response.data;
            })
            .catch(() => (value.user = {}));
    } else {
        value.user = {};
    }
    value.pfName = await getPfName(value.pfAvdcId);
    value.people = await getCustomer(value.customer);
    const comments = await getComments(value['@id']);
    const boDocuments = await getBoDocuments(value['@id']);
    const foDocuments = await getFoCategories(value['@id']);
    value.comments = comments;
    value.boDocuments = boDocuments;
    value.foDocuments = foDocuments;
    return { value, state };
}

export async function getFolderList(query) {
    let data = [];
    let total = 0;
    if (!query.includes('order[')) {
        query += `&order[sysCreatedDate]=desc`;
    }
    await folderServiceApi
        .getByKeys(query)
        .then(async (response) => {
            total = folderServiceApi.getTotal(response.data);
            data = folderServiceApi.getData(response.data);
            await Promise.all(
                data.map(async (value) => {
                    value.pfName = await getPfName(value.pfAvdcId);
                }),
            );
        })
        .catch(() => {
            data = [];
        });
    return { data, total };
}

async function getCustomer(uri) {
    let people = {};
    if (!isEmpty(uri)) {
        await contactServiceApi
            .getUri(uri)
            .then(async (response) => {
                people = response.data;
                if (people.address != null) {
                    const pUid = people.address.postalUid;
                    if (pUid != null) {
                        await refServiceApi
                            .getPostalCodeUid(pUid)
                            .then(async (rep) => {
                                people.postalCode = rep.data.postal_code;
                                people.town = rep.data.division.name;
                                await refServiceApi
                                    .getCountryByCode(rep.data.division.country_code_iso3)
                                    .then((c) => {
                                        people.country = c.data.name;
                                    })
                                    .catch(() => {});
                            })
                            .catch(() => {});
                    }
                }
            })
            .catch(() => (people = {}));
    }
    return people;
}

async function getDecease(value) {
    let dead = {};
    if (!isEmpty(value)) {
        await DeadServiceApi.getUri(value)
            .then(async (response) => {
                dead = response.data;
                if (!isEmpty(dead.avdcId)) {
                    await deadAVDCServiceApi
                        .get(dead.avdcId)
                        .then(async (rep) => {
                            const v = rep.data;
                            const division = await getAdministrativeDivision(v.divisionCode, v.postalCode);
                            dead.firstName = v.firstName;
                            dead.lastName = v.lastName;
                            dead.nameBirth = v.nameBirth;
                            dead.firstNameBirth = v.firstNameBirth;
                            dead.civility = v.civility;
                            dead.postalCode = v.postalCode;
                            dead.division = division;
                            if (!isEmpty(division.country)) {
                                dead.country = division.country.name;
                            } else {
                                dead.country = null;
                            }
                            if (!isEmpty(division.division)) {
                                dead.town = division.division.display_name;
                            } else {
                                dead.town = null;
                            }
                        })
                        .catch(() => {});
                }
            })
            .catch(() => {});
    }
    return dead;
}

async function getAdministrativeDivision(divisionCode, postalCode) {
    let response = {};
    const query = `postal_code=${postalCode}&division_code=${divisionCode}`;
    await refServiceApi
        .getPostalCode(query)
        .then(async (p) => {
            const list = p.data;
            if (list.length > 0) {
                response = list[0];
                const country = await getCountry(response.division.country_code_iso3);
                response.country = country;
            }
        })
        .catch(() => {});
    return response;
}

async function getCountry(code) {
    let response = {};
    await refServiceApi
        .getCountryByCode(code)
        .then((p) => {
            response = p.data;
        })
        .catch(() => {});
    return response;
}

async function getPfName(uid) {
    let value = '';
    if (isEmpty(uid)) {
        return value;
    }
    await organismAVDCServiceApi
        .get(uid)
        .then((response) => {
            const data = response.data;
            value = data.name;
        })
        .catch(() => {});
    return value;
}

async function getComments(folder) {
    let list = [];
    let query = '';
    query += `folder=${folder}&order[sysCreatedDate]=desc`;
    await CommentServiceApi.getByKeys(query)
        .then(async (response) => {
            list = CommentServiceApi.getData(response.data);
            await Promise.all(
                list.map(async (p) => {
                    await CommentServiceApi.getByUri(p.author)
                        .then((rep) => {
                            p.author = rep.data;
                        })
                        .catch(() => (p.author = {}));
                }),
            );
        })
        .catch(() => {});
    return list;
}

async function getBoDocuments(folder) {
    let list = [];
    await folderBoCategory.getByKeys('order[order]=asc').then(async (response) => {
        list = folderBoCategory.getData(response.data);
        await Promise.all(
            list.map(async (p) => {
                const query = `category=${p['@id']}&folder=${folder}`;
                await folderBoDocument
                    .getByKeys(query)
                    .then((response2) => {
                        const count = response2.data['hydra:totalItems'];
                        p.count = count >= 0 ? count : 0;
                    })
                    .catch(() => {});
            }),
        );
    });
    return list;
}

async function getFoCategories(folder) {
    let list = [];
    await folderFoDocumentCategory
        .getByKeys('order[order]=asc')
        .then(async (response) => {
            list = folderFoDocumentCategory.getData(response.data);
            await Promise.all(
                list.map(async (p) => {
                    p.count = await getGroups(p['@id'], folder);
                }),
            );
        })
        .catch(() => {});
    return list;
}

async function getGroups(category, folder) {
    let count = 0;
    await folderFoDocumentCategoryGroups
        .getByKeys(`category=${category}`)
        .then(async (response) => {
            const list = folderFoDocumentCategory.getData(response.data);
            await Promise.all(
                list.map(async (p) => {
                    const item = await getGroupItems(p['@id'], folder);
                    count += item;
                }),
            );
        })
        .catch(() => {});
    return count;
}

async function getGroupItems(group, folder) {
    let count = 0;
    await folderFoDocumentCatGroupItems
        .getByKeys(`group=${group}`)
        .then(async (response) => {
            const list = folderFoDocumentCatGroupItems.getData(response.data);
            await Promise.all(
                list.map(async (p) => {
                    const item = await getFoDocuments(p['@id'], folder);
                    count += item;
                }),
            );
        })
        .catch(() => {});
    return count;
}

async function getFoDocuments(groupItem, folder) {
    let count = 0;
    await folderFoDocument
        .getByKeys(`folder=${folder}&categoryGroupItem=${groupItem}`)
        .then((response) => {
            count = response.data['hydra:totalItems'];
            count = count >= 0 ? count : 0;
        })
        .catch(() => {});
    return count;
}

export { getAdministrativeDivision, getComments, getFolder };
