import React from 'react';
import { EuroCircleFilled } from '@ant-design/icons';

const PaymentPictoLabel = ({ text, picto }) => {
    return (
        <>
            <span className={`payment_picto_label`}>
                <EuroCircleFilled className={picto} />
                {text}
            </span>
        </>
    );
};

export default PaymentPictoLabel;
