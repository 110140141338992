import React from 'react';
import refServiceApi from '../../../../../services/refServiceApi';
import { ComponentInput, ComponentRadio, ComponentSelect } from '../../../../Partials/tools';
import { isEmpty } from 'lodash';

const CompleteDecease = ({ formik }) => {
    const [places, setPlaces] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        initData();
    }, []);

    async function initData() {
        const element = formik?.values?.birthPlace;
        if (isEmpty(element)) {
            return;
        }
        setPlaces([
            { value: '', title: 'Sélectionnez un lieu' },
            { value: element, title: '' },
        ]);
        setLoading(true);
        await refServiceApi
            .getAdministrativeDivisionByUid(element)
            .then((response) => {
                const data = response.data;
                if (!isEmpty(data.uid)) {
                    setPlaces([
                        { value: '', title: 'Sélectionnez un lieu' },
                        { value: data.uid, title: data.display_name },
                    ]);
                }
            })
            .catch((reason) => console.warn(reason));
        setLoading(false);
    }

    React.useEffect(() => {
        searchDivisionByName();
    }, [search]);

    async function searchDivisionByName() {
        if (search.length < 3) {
            return;
        }
        setLoading(true);
        const items = [];
        await refServiceApi
            .getAdministrativeDivision(`name=${search}`)
            .then((response) => {
                const data = response.data;
                data.forEach((p) => {
                    items.push({ value: p.uid, title: p.display_name });
                });
            })
            .catch((reason) => console.warn(reason));
        items.unshift({ value: '', title: 'Sélectionnez un lieu' });
        setPlaces(items);
        setLoading(false);
    }

    return (
        <React.Fragment>
            <ComponentRadio
                id="civil"
                label="Civilité"
                list={[
                    { value: 'Mme', title: 'Madame' },
                    { value: 'M', title: 'Monsieur' },
                ]}
                key="civility"
                formik={formik}
                name="civility"
                enabled={false}
            />
            <ComponentInput
                label="Nom du défunt"
                placeholder=""
                key="decease_last_name"
                formik={formik}
                type="text"
                name="lastName"
                enabled={false}
            />
            <ComponentInput label="Nom de naissance" enabled={false} placeholder="" key="sub_name" formik={formik} type="text" name="subName" />
            <ComponentInput
                label="Prénom du défunt"
                placeholder=""
                key="decease_first_name"
                formik={formik}
                type="text"
                name="firstName"
                enabled={false}
            />
            <ComponentInput label="Date de décès" placeholder="" enabled={true} key="death_date" formik={formik} type="date" name="deathDate" />
            <ComponentInput label="Lieu de décès" placeholder="" enabled={false} key="death_place" formik={formik} type="text" name="deathPlace" />
            <ComponentInput
                label="Date des obsèques"
                placeholder=""
                enabled={true}
                key="funeral_date"
                formik={formik}
                type="date"
                name="funeralDate"
            />
            <ComponentInput label="Adresse" placeholder="" key="deaces_address" formik={formik} type="text" name="address" />
            <ComponentInput
                label="Complément d'adresse"
                placeholder=""
                key="deaces_address_complement"
                formik={formik}
                type="text"
                name="complement"
            />
            <ComponentInput label="Code postal" enabled={false} key="death_postal_code" formik={formik} type="text" name="postalCode" />
            <ComponentInput label="Ville" placeholder="" key="death_town" formik={formik} type="text" name="town" enabled={false} />
            <ComponentInput label="N° de sécurité sociale" placeholder="" key="social_number" formik={formik} type="text" name="socialNumber" />
            <ComponentRadio
                label="Situation matrimoniale"
                id="death_status"
                name="maritalStatus"
                formik={formik}
                list={[
                    'Célibataire', // prettier-ignore
                    'PACS',
                    'Marié(e)',
                    'Divorcé(e)',
                    'Veuf(ve)',
                ].map((s) => ({ value: s, title: s }))}
                withFree={true}
            />
            <ComponentRadio
                label="Situation sociale"
                id="social_status"
                name="socialStatus"
                formik={formik}
                list={[
                    'Activité', // prettier-ignore
                    'Retraité(e)',
                    'Invalidité',
                ].map((s) => ({ value: s, title: s }))}
                withFree={true}
            />
            <ComponentSelect
                label="Lieu de naissance"
                name={'birthPlace'}
                key="birth_place"
                formik={formik}
                list={places}
                setSearch={setSearch}
                search
                loading={loading}
                enabled
            />
        </React.Fragment>
    );
};

export { CompleteDecease };
