import React, { useState, useEffect } from 'react';
import { Col, List, Row, Typography, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import dateHelper from '../../Helpers/DateHelper';
import NotificationHelper from '../../Helpers/NotificationHelper';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsPicto from './Partials/NotificationsPicto';
import folderEventsServiceApi from '../../services/folderEventsServiceApi';
import GenericHelper from '../../Helpers/GenericHelper';

const CellNotificationText = ({ label, value }) => {
    const { Text } = Typography;
    return (
        <div>
            <div>
                <Text style={{ fontWeight: 'bold' }}>{label}</Text>
            </div>
            <div>
                <Text>{value}</Text>
            </div>
        </div>
    );
};

const CellNotificationType = ({ type }) => {
    switch (type) {
        case 'reassigned':
            return <NotificationsPicto picto="double_user" />;
        case 'new_document':
            return <NotificationsPicto picto="document" />;
        case 'new_appointment':
            return <NotificationsPicto picto="calendar" />;
        case 'canceled':
            return <NotificationsPicto picto="calendrier-annuler" />;
        default:
            return <NotificationsPicto picto="envelope" />;
    }
};

const CellNotificationDateLabel = ({ type }) => {
    switch (type) {
        case 'new_appointment':
            return 'Date du rendez-vous';
        default:
            return 'Dernière mise à jour';
    }
};

const CellNotificationDate = ({ type, folder }) => {
    switch (type) {
        case 'new_appointment':
            return dateHelper.getDate(folder.data.datetime, 'LL');
        default:
            return dateHelper.getDate(folder.sysUpdatedDate, 'LL');
    }
};

const NotificationsList = () => {
    const storeNotifsList = useSelector((state) => state.notifications.notiticationsList);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [initList, setInitList] = React.useState(false);
    const counselor = useSelector((state) => state.user.id);
    const [loading, setLoading] = React.useState(false);
    const list = useSelector((state) => state.notifications.list);
    const dispatch = useDispatch();

    async function initNotifs() {
        setLoading(true);
        setCurrentPage(1);
        await axiosGetNotif(1);
        setLoading(false);
    }
    useEffect(async () => {
        await initNotifs();
        setInitList(true);
    }, []);

    useEffect(async () => {
        if (initList) {
            await initNotifs();
        }
    }, [storeNotifsList, counselor]);

    const axiosGetNotif = async (page) => {
        let query = ``;
        const date = GenericHelper.getDateThirtyDaysBefore();
        query += `counselor=${counselor}&sysUpdatedDate[after]=${date}`;
        query += `&page=${page}&itemsPerPage=10&order[sysCreatedDate]=desc`;
        await folderEventsServiceApi
            .getByKeys(query)
            .then((response) => {
                let data = folderEventsServiceApi.getData(response.data);
                dispatch({ type: 'NOTIFICATIONS_CENTER_LIST', payload: data });
                setTotalItems(response.data['hydra:totalItems']);
            })
            .catch(() => {});
    };
    const onChange = async (page) => {
        setLoading(true);
        setCurrentPage(page);
        await axiosGetNotif(page);
        setLoading(false);
    };

    return (
        <>
            <List
                size="large"
                itemLayout="horizontal"
                loading={loading}
                dataSource={list}
                renderItem={(folder) => (
                    <Link to={`${folder.folder}/view`}>
                        <List.Item key={folder.uid}>
                            <Row align="middle" style={{ width: '100%', height: '100%' }}>
                                <Col span={2} style={{ maxWidth: '65px' }}>
                                    <CellNotificationType type={folder.type} />
                                </Col>
                                <Col span={7}>
                                    <CellNotificationText
                                        label={NotificationHelper.getTypeValue(folder.type)}
                                        value={NotificationHelper.displayFullName(folder, folder['@type'], true)}
                                    />
                                </Col>

                                <Col span={7}>
                                    <CellNotificationText
                                        label="Contact"
                                        value={NotificationHelper.displayFullName(folder, folder['@type'], false)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <CellNotificationText
                                        label={<CellNotificationDateLabel type={folder.type} />}
                                        value={<CellNotificationDate type={folder.type} folder={folder} />}
                                    />
                                </Col>
                                <Col span={2}>
                                    <span
                                        style={{
                                            textDecoration: 'underline',
                                            color: '#cb5d4b',
                                            fontSize: '16px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Aller au dossier
                                    </span>
                                </Col>
                            </Row>
                        </List.Item>
                    </Link>
                )}
            />
            <Pagination
                className="pagination"
                current={currentPage}
                onChange={onChange}
                showSizeChanger={false}
                pageSizeOptions={10}
                defaultPageSize={10}
                total={totalItems}
                hideOnSinglePage={true}
                disabled={loading}
            />
        </>
    );
};

export default NotificationsList;
