import http from './authConfig';

const TOKEN = 'auth.token';
const REFRESH_TOKEN = 'auth.refresh_token';

class AuthApi {
    async token(email, password) {
        let rep = false;
        await http
            .post('/auth', { email, password })
            .then((response) => response.data)
            .then((response) => {
                // console.log('token', response);
                if (response.token) {
                    rep = true;
                    localStorage.setItem(TOKEN, response.token);
                    localStorage.setItem(REFRESH_TOKEN, response.refresh_token);
                }
            })
            .catch((e) => console.warn('Error', e));
        return rep;
    }

    async refreshToken() {
        let refresh_token = this.getRefreshToken();

        let rep = false;
        if (refresh_token) {
            await http
                .post('/auth/refresh', { refresh_token })
                .then((response) => response.data)
                .then((response) => {
                    // console.log('refreshToken', response);
                    if (response.token) {
                        rep = true;
                        localStorage.setItem(TOKEN, response.token);
                        localStorage.setItem(REFRESH_TOKEN, response.refresh_token);
                    }
                })
                .catch((e) => console.warn('Error', e));
        }
        return rep;
    }

    logout() {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
    }

    getToken() {
        return localStorage.getItem(TOKEN);
    }

    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN);
    }
}

export default new AuthApi();
