import Helpers from '../../../Helpers/DateHelper';
import { isEmpty } from 'lodash';

function folderValuesCompletion({ contact, decease, appointment, currentFolder }) {
    const {
        deadFullName,
        deadDeathDate,
        deadFuneralDate,
        deadMaritalStatus,
        deadSocialSituation,
        familyRelationship,
        dead,
        customer,
        state,
        paymentMode,
        paymentState,
        customerState,
        pf,
        pfCounselorName,
        misc,
        trackingNumber,
    } = currentFolder;
    const data = {
        deadFullName,
        deadDeathDate,
        deadFuneralDate,
        deadMaritalStatus,
        deadSocialSituation,
        familyRelationship,
        dead,
        customer,
        state,
        paymentMode,
        paymentState,
        customerState,
        pf,
        pfCounselorName,
        misc,
        trackingNumber,
    };
    let payment = contact.payment;
    if (payment != 'paygreen' && payment != 'PF' && payment != 'none') {
        payment = null;
    }
    data.dead = decease.uid;
    data.state = 'pending';
    data.customer = contact.uid;
    data.paymentMode = payment;
    data.deadFullName = decease.name;
    data.deadMaritalStatus = decease.maritalStatus;
    data.deadSocialSituation = decease.socialStatus;
    data.deadDeathDate = Helpers.getDate(decease.deathDate || null, 'YYYY-MM-DD');
    data.deadFuneralDate = Helpers.getDate(decease.funeralDate || null, 'YYYY-MM-DD');
    data.familyRelationship = contact.relationship;
    data.pfAvdcId = contact.organization;
    data.trackingNumber = appointment.trackingNumber;
    return data;
}

function contactValuesCompletion({ contact }) {
    const p = contact.values;
    const data = {
        civility: p.civility,
        lastName: p.lastName,
        firstName: p.firstName,
        email: p.email,
        phone: p.phone,
        address: { street: p.address, postalUid: p.postalCode },
        socialNumber: p.socialNumber,
    };
    return data;
}

function contactValuesUpdate({ contact }) {
    const p = contact.values;
    const data = {
        civility: p.civility,
        lastName: p.lastName,
        firstName: p.firstName,
        email: p.email,
        phone: p.phone,
        address: { street: p.address, postalUid: p.postalCode },
        socialNumber: p.socialNumber,
    };
    return data;
}

function deceaseValuesCompletion({ decease }) {
    const p = decease.values;
    const data = {
        otherNames: p.name,
        socialNumber: p.socialNumber,
        birthDate: p.birthDate,
        birthPlace: p.birthPlace,
        avdcId: p.avdcId,
        address: { street: p.address, complement: p.complement },
    };
    return data;
}

function folderValueUpdate({ currentFolder, decease, contact, appointment }) {
    console.log('contact', contact);
    const {
        deadFullName,
        deadDeathDate,
        deadFuneralDate,
        deadMaritalStatus,
        deadSocialSituation,
        familyRelationship,
        dead,
        customer,
        state,
        paymentMode,
        paymentState,
        customerState,
        pfCounselorName,
        misc,
        trackingNumber,
        pfAvdcId,
    } = currentFolder;
    const data = {
        deadFullName,
        deadDeathDate,
        deadFuneralDate,
        deadMaritalStatus,
        deadSocialSituation,
        familyRelationship,
        dead,
        customer,
        state,
        paymentMode,
        paymentState,
        customerState,
        pfCounselorName,
        misc,
        trackingNumber,
        pfAvdcId,
    };
    console.log('HJ', pfAvdcId);
    console.log('HJ', contact.organization);
    data.deadDeathDate = decease.deathDate ? decease.deathDate : data.deadDeathDate;
    data.deadFuneralDate = decease.funeralDate ? decease.funeralDate : data.deadFuneralDate;
    data.deadMaritalStatus = decease.maritalStatus != '' ? decease.maritalStatus : data.deadMaritalStatus;
    data.familyRelationship = contact.relationship != '' ? contact.relationship : data.familyRelationship;
    data.paymentMode = contact.payment;
    data.deadSocialSituation = decease.socialStatus;
    data.pfAvdcId = !isEmpty(contact.organization) ? contact.organization : pfAvdcId;
    data.pfCounselorName = contact.counselorName != '' ? contact.counselorName : data.pfCounselorName;
    data.trackingNumber = appointment.trackingNumber != '' ? appointment.trackingNumber : data.trackingNumber;
    return data;
}

function deceaseValuesUpdate({ decease }) {
    const p = decease.values;
    const data = {
        otherNames: p.firstName + ' ' + p.lastName,
        socialNumber: p.socialNumber,
        birthDate: isEmpty(p.birthDate) ? null : p.birthDate,
        birthPlace: p.birthPlace,
        address: { street: p.address, complement: p.complement },
    };
    return data;
}

export { contactValuesCompletion, contactValuesUpdate, deceaseValuesCompletion, deceaseValuesUpdate, folderValuesCompletion, folderValueUpdate };
