import { Col, Modal, notification, Row, Spin } from 'antd';
import React from 'react';
import { FolderButton } from './FolderButton';
import folderServiceApi from '../../../../services/folderServiceApi';
import { useDispatch } from 'react-redux';
import { getFolder } from '../getFolders';
import { canEditFolder } from '../advisorTreatment';
import { FolderStatus } from '../../FolderValues';

const FolderStatusMap = Object.fromEntries(FolderStatus.map((p) => [p.value, p.name]));

const stepsBilan = [
    'pending', // prettier-ignore
    'in_progress',
    'completed',
    'canceled',
].map((s) => ({ step: FolderStatusMap[s].split(' / ')[0], uid: s }));

const stepsEntretien = [
    'pending', // prettier-ignore
    'in_progress',
    'to_send',
    'completed',
    'canceled',
].map((s) => ({ step: FolderStatusMap[s].split(' / ').pop(), uid: s }));

const stepsEntretienReopened = stepsEntretien
    .map((p) => (p.uid === 'in_progress' ? 'reopened' : p.uid))
    .map((s) => ({ step: FolderStatusMap[s].split(' / ').pop(), uid: s }));

function isState(state, values = []) {
    return values.includes(state);
}

function StateButton({ folder, setFolder }) {
    const [progress, setProgress] = React.useState(false);
    const dispatch = useDispatch();

    async function handleNext() {
        if (!canEditFolder(folder)) {
            notification.open({
                message: 'Vous ne pouvez pas modifier ce dossier',
                type: 'warning',
            });
            return;
        }
        if (isState(folder.state, ['pending', 'in_progress', 'to_send', 'reopened'])) {
            const data = { state: folder.state };
            setProgress(true);
            switch (folder.type) {
                case 'bilan':
                    switch (folder.state) {
                        case 'pending':
                            data.state = 'in_progress';
                            break;
                        case 'in_progress':
                            data.state = 'completed';
                            break;
                    }
                    break;
                case 'entretien':
                    switch (folder.state) {
                        case 'pending':
                            data.state = 'in_progress';
                            break;
                        case 'in_progress':
                        case 'reopened':
                            data.state = 'to_send';
                            break;
                        case 'to_send':
                            data.state = 'completed';
                            break;
                    }
                    break;
            }
            await folderServiceApi
                .update(folder.uid, data)
                .then(async (response) => {
                    const rep = await getFolder(response.data.uid);
                    dispatch({ type: 'CURRENT_FOLDER', payload: rep.value });
                    setFolder(response.data);
                    setProgress(false);
                })
                .catch((error) => {
                    setProgress(false);
                    console.log('ERROR', error);
                });
        }
    }

    async function handleReopen() {
        if (!canEditFolder(folder)) {
            notification.open({
                message: 'Vous ne pouvez pas modifier ce dossier',
                type: 'warning',
            });
            return;
        }
        if (folder.type == 'entretien' && isState(folder.state, ['completed', 'canceled'])) {
            const data = { state: 'reopened' };
            setProgress(true);
            await folderServiceApi
                .update(folder.uid, data)
                .then(async (response) => {
                    const rep = await getFolder(response.data.uid);
                    dispatch({ type: 'CURRENT_FOLDER', payload: rep.value });
                    setFolder(response.data);
                    setProgress(false);
                })
                .catch((error) => {
                    setProgress(false);
                    console.log('ERROR', error);
                });
        }
    }

    return (
        <React.Fragment>
            <Row>
                {isState(folder.state, ['pending', 'in_progress', 'to_send', 'reopened']) && (
                    <Col style={{ marginTop: 40, marginBottom: 10 }}>
                        <FolderButton title="Passer à l'étape suivante" onClick={() => handleNext()} />
                    </Col>
                )}
                {folder.type == 'entretien' && isState(folder.state, ['completed', 'canceled']) && (
                    <Col style={{ marginTop: 40, marginBottom: 10 }}>
                        <FolderButton title="Rouvrir le dossier" onClick={() => handleReopen()} />
                    </Col>
                )}
            </Row>
            <Modal
                visible={progress}
                cancelButtonProps={{ hidden: true }}
                centered
                closable={false}
                forceRender={null}
                okButtonProps={{ hidden: true }}
            >
                <Row align="middle" justify="center">
                    <Col flex="1" style={{ textAlign: 'center', padding: 30 }}>
                        <Spin size="large" />
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    );
}

export { StateButton, stepsBilan, stepsEntretien, stepsEntretienReopened };
