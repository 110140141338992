import React from 'react';

function Calendar() {
    return (
        <>
            <h4 style={{ fontWeight: 'bold', color: '#CB5D4B' }}>Calendrier </h4>
        </>
    );
}

export { Calendar };
