import * as Yup from 'yup';
import { isEmpty } from 'lodash';

const phoneRegExp = /^((0|\+33([-\s.]?\(0\))?)[-\s.]?[1-9]([-\s.]?[0-9]){8}|[1-9]([-\s.]?[0-9]){1,5}|\+(?!33)[1-9]([-\s.]?[0-9]){1,14})$/;
const emailRegex = /^[a-zA-Z0-9.!#$%&*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
class ValidationRules {
    addFormValidatioNRule() {
        return Yup.object({
            organismEmail: Yup.string().email('Adresse mail invalide'),
            organismTelephone: Yup.string().matches(phoneRegExp, 'Numéro de téléphone invalide'),
            organismName: Yup.string().required('Champ obligatoire'),
            organismTheme: Yup.string().required('Champ obligatoire'),
            organismContacts: Yup.array().of(
                Yup.object({
                    email: Yup.string().email('Adresse mail invalide'),
                    phone: Yup.string().matches(phoneRegExp, 'Numéro de téléphone invalide'),
                    fullName: Yup.string().required('Le nom est requis'),
                }),
            ),
        });
    }
    editFormValidatioNRule() {
        return Yup.object({
            organismEmail: Yup.string().email('Adresse mail invalide').nullable(),
            organismTelephone: Yup.string().matches(phoneRegExp, 'Numéro de téléphone invalide').nullable(),
            organismName: Yup.string().required('Champ obligatoire'),
            organismTheme: Yup.string().required('Champ obligatoire'),
            organismContacts: Yup.array().of(
                Yup.object({
                    email: Yup.string().email('Adresse mail invalide').nullable(),
                    phone: Yup.string().matches(phoneRegExp, 'Numéro de téléphone invalide').nullable(),
                }),
            ),
        });
    }

    signinFormValidationRule() {
        return Yup.object({
            userEmail: Yup.string().email('Adresse mail invalide').required('Adresse mail obligatoire'),
            userPassword: Yup.string().required('Mot de passe obligatoire'),
        });
    }

    isEmail(value) {
        let response = false;
        if (!isEmpty(value)) {
            response = emailRegex.test(value);
        }
        return response;
    }

    isPhoneNumber(value) {
        let response = false;
        if (!isEmpty(value)) {
            response = phoneRegExp.test(value);
        }
        return response;
    }
}

export default new ValidationRules();
