const FolderTypes = [
    {
        name: 'Bilan',
        value: 'bilan',
    },
    {
        name: 'Entretien',
        value: 'entretien',
    },
];

const FolderStatus = [
    {
        name: 'Brouillon',
        value: 'draft',
    },
    {
        name: 'En attente',
        value: 'pending',
    },
    {
        name: 'En cours',
        value: 'in_progress',
    },
    {
        name: 'À envoyer',
        value: 'to_send',
    },
    {
        name: 'Terminé',
        value: 'completed',
    },
    {
        name: 'Refusé / Annulé',
        value: 'canceled',
    },
    {
        name: 'Rouvert',
        value: 'reopened',
    },
];

const FolderTimes = [
    {
        name: 'Hors délai',
        value: 'entretienDatetime[strictly_before]',
    },
    {
        name: 'Dans le délai',
        value: 'entretienDatetime[after]',
    },
];

function getNameFromValue(list, value) {
    return list.find((p) => p.value === value)?.name ?? value;
}

export { FolderStatus, FolderTimes, FolderTypes, getNameFromValue };
