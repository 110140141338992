import { isEmpty } from 'lodash';
import { advisorStatusList } from '../../../Helpers/PersonHelper';

function canEditFolder(folder) {
    let response = false;
    let user = localStorage.getItem('user-info');
    if (!isEmpty(user)) {
        user = JSON.parse(user);
    } else {
        user = {};
    }
    if (user.role == 'proprietaire' || user.role == 'manager') {
        response = true;
    } else if (folder) {
        const counselor = folder.counselor;
        if (counselor == user.uid || counselor == user['@id']) {
            response = true;
        }
    }
    return response;
}

function canAdministrateReferential() {
    const user = localStorage.getItem('user-info');
    if (isEmpty(user)) return false;
    const role = JSON.parse(user).role;
    return role === 'dev' || ['proprietaire', 'manager', 'assistant_manager'].includes(role);
}

function canEditUser(advisor) {
    let user = localStorage.getItem('user-info');
    if (isEmpty(user)) return false;
    user = JSON.parse(user);
    const role = user.role;
    if (role === 'dev') {
        return true;
    }
    if (!advisor) return false;
    if (user.uid === advisor.uid) {
        return true;
    }
    const findAdvisorStatusListValueIndex = (r) => advisorStatusList.findIndex((p) => p.value === r);
    const userRoleIndex = findAdvisorStatusListValueIndex(user.role);
    return userRoleIndex !== -1 && userRoleIndex > findAdvisorStatusListValueIndex(advisor.role);
}

export { canAdministrateReferential, canEditFolder, canEditUser };
