// Layout and UI
import { Col, Layout, Menu, Row, Space, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Switch, useHistory } from 'react-router-dom';
// Routing
import Routes from '../routes';
import HeaderTimer from './Partials/HeaderTimer';
import NotificationAndSetting from './Partials/NotificationAndSetting';
import { useDispatch, useSelector } from 'react-redux';
import { DayCalendar } from './calendar/dayCalendar';
import { Connexion } from './connexion/connexion';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Services

function ContextualMenu() {
    const dispatch = useDispatch();
    const [role, setRole] = useState('');
    const selectMenu = (path) => {
        dispatch({ type: 'UPDATE_FOLDER_MENU', payload: path });
    };
    const location = useLocation();
    useEffect(() => {
        const path = location.pathname;
        if (path.indexOf('folders') != -1) {
            selectMenu('folder');
        } else if (path.indexOf('organisms') != -1) {
            selectMenu('organism');
        } else if (path.indexOf('management') != -1) {
            selectMenu('management');
        } else if (path == '/') {
            selectMenu('home');
        } else {
            selectMenu(null);
        }
    }, [location.pathname]);
    useEffect(() => {
        if (localStorage.getItem('user-info') === null) {
            setRole('none');
        } else {
            setRole(JSON.parse(localStorage.getItem('user-info')).role);
        }
    }, [role]);
    return (
        <Menu defaultSelectedKeys={[4]}>
            <Menu.Item key="0" className="logo">
                <Link to="/" onClick={() => selectMenu('home')}>
                    <img src="https://assets.funecap.org/ihm/serenicare/logo-serenicare.jpg" alt="logo serenicare" />
                </Link>
            </Menu.Item>
            <Menu.Item key="1">
                <Link to="/" onClick={() => selectMenu('home')}>
                    <i className="icon-colonne"></i>
                    <span>Accueil</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Link to="/folders" onClick={() => selectMenu('folder')}>
                    <i className="icon-famille"></i>
                    <span>Dossiers</span>
                </Link>
            </Menu.Item>
            {/* <Menu.Item key="3">
                    <Link to="/documents" onClick={() => selectMenu('document')}>
                        <i className="icon-document"></i>
                        <span>Documents</span>
                    </Link>
                </Menu.Item> */}
            <Menu.Item key="3">
                <Link to="/calendar" onClick={() => window.open('https://calendly.com/event_types/user/me ', '_blank')}>
                    <i className="icon-calendar"></i>
                    <span>Calendrier</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="4">
                <Link to="/organisms" onClick={() => selectMenu('organism')}>
                    <i className="icon-annuaire"></i>
                    <span>Annuaire</span>
                </Link>
            </Menu.Item>
            {role != 'none' && (role === 'proprietaire' || role === 'manager' || role === 'dev') && (
                <Menu.Item key="5">
                    <Link to="/management" onClick={() => selectMenu('management')}>
                        <i className="icon-pilotage"></i>
                        <span>Pilotage</span>
                    </Link>
                </Menu.Item>
            )}
        </Menu>
    );
}

const SearchBar = ({ placeholder, type, screen }) => {
    const [className, setClassName] = React.useState('search-bar');
    const dispatch = useDispatch();
    const [text, setText] = React.useState('');

    const onClickSearchBar = () => {
        setClassName('search-bar extend');
    };

    const onBlurSearchhBar = () => {
        setClassName('search-bar');
    };

    React.useEffect(() => {
        onChange({ target: { value: '' } });
    }, [type]);

    const onChange = (event) => {
        let value = event.target.value;
        setText(value);
        if (type === 'folder' || type === 'home') {
            const query = value ? `deadFullName=${value}` : '';
            switch (type) {
                case 'home':
                    dispatch({ type: 'COUNSELOR_SEARCH', payload: query });
                    break;
                case 'folder':
                    dispatch({ type: 'FOLDER_SEARCH', payload: query });
                    break;
                default:
                    break;
            }
        } else {
            if (screen == 'organization') {
                dispatch({
                    type: 'ORGANIZATION_FILTER',
                    payload: `name=${value}`,
                });
            } else if (screen == 'pf') {
                dispatch({
                    type: 'PF_FILTER',
                    payload: `name=${value}`,
                });
            }
        }
    };

    return (
        <div className={className}>
            <i className="icon-search-loupe"></i>
            <Input
                placeholder={placeholder}
                allowClear
                value={text}
                style={{ outline: 'none' }}
                onClick={onClickSearchBar}
                onBlur={onBlurSearchhBar}
                onChange={onChange}
            />
        </div>
    );
};

const HeaderPanel = () => {
    const isFolderMenu = useSelector((state) => state.folder.isFolderMenu);
    const location = useLocation();
    const counselorName = useSelector((state) => state.user.firstname);
    return (
        <Row className="container">
            <Col span={18} className="left-container">
                <Row className="row box light">
                    <Col className="left">
                        <Space className="date-time" direction="vertical" style={{ lineHeight: 0.1 }} wrap={true}>
                            <HeaderTimer />
                        </Space>
                    </Col>
                    <Col flex="1" className="right">
                        <Space align="middle">
                            <span className="welcome">
                                Bonjour<span>{counselorName}</span>
                            </span>
                        </Space>
                        {location.pathname.includes('folder') && location.pathname.includes('view') ? (
                            <BrandLogo />
                        ) : (
                            <InputSearchBar type={isFolderMenu} />
                        )}
                    </Col>
                </Row>
            </Col>
            <Col span={6} className="calendar">
                <div className="box light" style={{ width: 316, display: 'flex', height: '100%', alignItems: 'center', marginLeft: 3 }}>
                    <NotificationAndSetting />
                </div>
            </Col>
        </Row>
    );
};

const BrandLogo = () => {
    const currentFolder = useSelector((state) => state.folder.currentFolder);

    if (isEmpty(currentFolder)) return <></>;

    const brandCode = currentFolder.brandCode;
    let src;
    switch (brandCode) {
        case 'avis-de-deces':
            src = 'https://assets.funecap.org/ihm/serenicare/avdc-logo.png';
            break;
        case 'roc-eclerc':
            src = 'https://assets.funecap.org/ihm/serenicare/roc-eclerc-formalite-logo.png';
            break;
        default:
            src = 'https://assets.funecap.org/ihm/serenicare/serenicare-white.png';
    }

    return (
        <>
            <img src={src} alt={brandCode} title={`origine : ${brandCode}`} style={{ marginRight: 13 }} />
        </>
    );
};

const InputSearchBar = ({ type }) => {
    const screen = useSelector((state) => state.organism.screen);
    switch (type) {
        case 'home':
            return <SearchBar placeholder="Nom du défunt" type="home" />;
        case 'folder':
            return <SearchBar placeholder="Nom du défunt" type="folder" />;
        case 'organism':
            return (
                <SearchBar
                    placeholder={screen == 'organization' ? "Nom de l'organisme" : 'Nom de la pompe funèbre'}
                    type="organism"
                    screen={screen}
                />
            );
        default:
            return <></>;
    }
};

const FooterWithLogo = ({ image }) => {
    return (
        <>
            <div style={{ textAlign: '' }}>
                <img src={image} alt="logo footer serenicare" />
            </div>
        </>
    );
};

const Components = ({ routes }) => {
    const hist = useHistory();
    return routes.map((route, index) => {
        return (
            <Route
                exact={route.exact}
                key={index}
                path={route.path}
                render={() => {
                    if (localStorage.getItem('user-info') === null) {
                        hist.push('/connexion');
                        return null;
                    }
                    return <route.component />;
                }}
            />
        );
    });
};

const Home = () => {
    const { Header, Footer, Sider, Content } = Layout;

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/connexion" component={Connexion} />
                <Route>
                    <Layout className="layout">
                        <Layout className="layout">
                            <Sider
                                breakpoint="lg"
                                style={{
                                    background: '#fff',
                                    zIndex: 100,
                                    paddingLeft: '20px',
                                    paddingRight: 0,
                                }}
                                className="menu_principal"
                            >
                                <ContextualMenu />
                            </Sider>
                            <Layout style={{ paddingLeft: 8, marginBottom: 54 }}>
                                <Header className="header" style={{ background: '#fff' }}>
                                    <HeaderPanel />
                                </Header>
                                <Content style={{ flex: 1, marginTop: 15, background: '#fff' }}>
                                    <div className="site-layout-content" style={{ minHeight: 360 }}>
                                        <Row gutter={20}>
                                            <Col className="gutter-row" span={18} flex="1" style={{ minHeight: 360 }}>
                                                <div>
                                                    <Components routes={Routes} />
                                                </div>
                                            </Col>
                                            <Col span={6} className="calendar">
                                                <DayCalendar />
                                            </Col>
                                        </Row>
                                    </div>
                                </Content>
                            </Layout>
                        </Layout>
                        <Footer style={{ display: 'none' }}>
                            <FooterWithLogo image={'https://assets.funecap.org/ihm/serenicare/logo-serenicare-footer.png'} />
                        </Footer>
                    </Layout>
                </Route>
            </Switch>
        </BrowserRouter>
    );
};
export default Home;
