import React, { useEffect, useState } from 'react';
import NotificationsButton from './NotificationsButton';
import NotificationsPanel from './NotificationsPanel';
import NotificationsReminder from './NotificationsReminder';
import { useDispatch, useSelector } from 'react-redux';
import folderEventsServiceApi from '../../services/folderEventsServiceApi';
import GenericHelper from '../../Helpers/GenericHelper';

const NotificationsHeader = ({ role }) => {
    const notificationsCounter = useSelector((state) => state.notifications.notificationsCounter);
    const notificationsTotal = useSelector((state) => state.notifications.notificationsTotal);
    const notificationSeen = useSelector((state) => state.notifications.notificationsSeen);
    const counselor = useSelector((state) => state.user.id);
    const [notifsArray, setNotifsArray] = useState([]);
    const [counter, setCounter] = useState(0);
    const [counterTotal, setCounterTotal] = useState(0);
    const dispatch = useDispatch();
    const intervalRef = React.useRef();

    React.useEffect(() => {
        if (notificationsCounter == 0) {
            dispatch({ type: 'TOTAL_NOTIFICATIONS_SEEN', payload: notificationsTotal });
        }
    }, [notificationsCounter]);

    const getNotifLastThirtyDays = async () => {
        let list = [];
        await folderEventsServiceApi
            .getByKeys(
                'counselor=' +
                    counselor +
                    '&sysUpdatedDate[after]=' +
                    GenericHelper.getDateThirtyDaysBefore() +
                    '&page=1&itemsPerPage=10&order[sysCreatedDate]=desc',
            )
            .then((response) => {
                let data = folderEventsServiceApi.getData(response.data);
                list = setNewNotifs(data);
            })
            .catch((e) => console.warn('Error ', e));
        return list;
    };

    function setNewNotifs(data = []) {
        let list = notifsArray.slice();
        data.forEach((p) => {
            const index = list.findIndex((x) => x.uid == p.uid);
            if (index === -1) {
                list.push(p);
            }
        });
        list = list.sort((a, b) => b.sysCreatedDate.localeCompare(a.sysCreatedDate));
        setNotifsArray(list);
        return list;
    }

    const getTotalNotifs = (list) => {
        folderEventsServiceApi
            .getByKeys('counselor=' + counselor + '&itemsPerPage=0')
            .then((response) => {
                let total = response.data['hydra:totalItems'];
                let diff = 0;
                if (notificationSeen == 0) {
                    diff = total - notificationsTotal;
                    dispatch({ type: 'TOTAL_NOTIFICATIONS_SEEN', payload: total });
                } else {
                    diff = total - notificationSeen;
                }
                if (diff < 0) diff = 0;
                setCounterTotal(total);
                setNotifValues(diff, list);
                setCounter(diff);
                dispatch({ type: 'NOTIFICATIONS_TOTAL', payload: total });
            })
            .catch((e) => console.warn('Error ', e));
    };
    const getNotifs = async () => {
        const list = await getNotifLastThirtyDays();
        getTotalNotifs(list);
    };

    function setNotifValues(counter = 0, list = []) {
        if (counter >= 0 && list.length >= counter) {
            dispatch({ type: 'NOTIFICATIONS_LIST', payload: list });
            dispatch({ type: 'NOTIFICATIONS_ALREADY_READ', payload: counter });
        }
    }

    useEffect(() => {
        setNotifValues(counter, notifsArray);
    }, [notifsArray]);

    useEffect(() => {
        if (counselor) {
            getNotifs();
            intervalRef.current = setInterval(getNotifs, 60000);
            return () => clearInterval(intervalRef.current);
        }
    }, [counselor]);

    const [showNotifications, setShowNotifications] = useState(false);
    const onReset = () => {
        dispatch({ type: 'NOTIFICATIONS_ALREADY_READ', payload: 0 });
    };
    const showHideNotifsHeader = () => {
        setShowNotifications(!showNotifications);
        onReset();
    };

    return (
        <div className={`notifs_roles ${role}`}>
            <NotificationsButton clickShowHideNotifs={showHideNotifsHeader} showNotifications={showNotifications} />
            <NotificationsPanel
                clickShowHideNotifs={showHideNotifsHeader}
                showNotifications={showNotifications}
                count={notificationsCounter}
                totalNotifs={counter}
                totalCount={counterTotal}
            />
            <NotificationsReminder notifsList={notifsArray} />
        </div>
    );
};

export { NotificationsHeader };
