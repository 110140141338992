const NOTIFICATIONS_ALREADY_READ = 'NOTIFICATIONS_ALREADY_READ';
const NOTIFICATIONS_LIST = 'NOTIFICATIONS_LIST';
const NOTIFICATIONS_REMINDER = 'NOTIFICATIONS_REMINDER';
const NOTIFICATIONS_TOTAL = 'NOTIFICATIONS_TOTAL';
const NOTIFICATIONS_CENTER_LIST = 'NOTIFICATIONS_CENTER_LIST';
const TOTAL_NOTIFICATIONS_SEEN = 'TOTAL_NOTIFICATIONS_SEEN';

const initialState = {
    notificationsCounter: 0,
    notificationsTotal: 0,
    notificationsSeen: 0,
    notiticationsList: [],
    notiticationsReminder: false,
    list: [],
};

export default function reducerNotification(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATIONS_ALREADY_READ:
            return { ...state, notificationsCounter: action.payload };
        case NOTIFICATIONS_TOTAL:
            return { ...state, notificationsTotal: action.payload };
        case NOTIFICATIONS_LIST:
            return { ...state, notiticationsList: action.payload };
        case NOTIFICATIONS_REMINDER:
            return { ...state, notiticationsReminder: action.payload };
        case NOTIFICATIONS_CENTER_LIST:
            return { ...state, list: action.payload };
        case TOTAL_NOTIFICATIONS_SEEN:
            return { ...state, notificationsSeen: action.payload };
        default:
            return state;
    }
}
