const ADD_ADIVSOR = 'ADD_ADVISOR';
const CURRENT_ADVISOR = 'CURRENT_ADVISOR';
const SEARCH_ADVISOR = 'SEARCH_ADVISOR';
const SEARCH_ALL_ADVISOR = 'SEARCH_ALL_ADVISOR';
const UPDATE_ADVISOR = 'SEARCH_ADVISOR';

const initialState = {
    advisors: [],
    getallAdvisors: [],
    currentAdvisors: {},
    newAdvisors: {},
    updateAdvisor: {},
};

export default function reducerAdvisor(state = initialState, action) {
    switch (action.type) {
        case ADD_ADIVSOR:
            return { ...state, advisors: [...state.advisors, action.payload] };
        case CURRENT_ADVISOR:
            return { ...state, currentAdvisors: action.payload };
        case SEARCH_ADVISOR:
            return { ...state, advisors: action.payload };
        case SEARCH_ALL_ADVISOR:
            return { ...state, getallAdvisors: action.payload };
        case UPDATE_ADVISOR:
            return { ...state, updateAdvisor: action.payload };
        default:
            return state;
    }
}
