const statsLabels = {
    balance_sheet: {
        title: 'Bilan',
        labels: {
            done: 'Nombre de bilans effectués',
            transformed: 'Nombre de bilans transformés',
            not_transformed: 'Nombre de bilans non transformés',
        },
    },
    rate: {
        title: 'Taux',
        labels: {
            satisfaction: 'Taux de satisfaction',
            transform_rate: 'Taux de transformation des bilans',
        },
    },
    meeting: {
        title: 'Entretien',
        labels: {
            done: 'Nombre d’entretiens effectués',
            canceled: 'Nombre d’entretiens annulés',
            done_direct: 'Entretiens directs',
            done_online: 'Entretiens achetés en ligne',
        },
    },
    folders: {
        title: 'Dossiers',
        labels: {
            express: 'Nombre de dossiers express',
            classic: 'Nombre de dossiers classiques',
            reopened: 'Nombre de dossiers rouverts',
        },
    },
    stats: {
        title: 'Statistiques',
        labels: {
            draft: 'Nombre de dossiers en attente',
            done_last_month: 'Nombre de dossiers réalisés sur le mois',
            conversion_rate: 'Taux de transformation des bilans',
        },
    },
};

export { statsLabels };
