import http from './apiConfig';

const userApiSource = 'bo_users';

class BoUserDataApi {
    getAll() {
        return http.get(userApiSource);
    }
    create(data) {
        return http.post(userApiSource, data);
    }
    update(id, data) {
        return http.put(`${userApiSource}/${id}`, data);
    }
    get(id) {
        return http.get(`${userApiSource}/${id}`);
    }

    findByEmail(email) {
        return http.get(`${userApiSource}?email=${email}`);
    }
    getData(data) {
        if (data['hydra:member']) return data['hydra:member'];
        return [];
    }
    getByKeys(queryParam) {
        return http.get(`${userApiSource}?${queryParam}`);
    }
    delete(id) {
        return http.delete(`${userApiSource}/${id}`);
    }
}

export default new BoUserDataApi();
