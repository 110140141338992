import { useDispatch } from 'react-redux';
import http from './apiConfig';

const apiSource = 'organizations';

class OrganismDataApi {
    getAll() {
        return http.get(apiSource);
    }

    get(id) {
        return http.get(`${apiSource}/${id}`);
    }

    getByName(queryParam) {
        const url = `${apiSource}?name=${queryParam}`;
        return http.get(url);
    }

    create(data) {
        return http.post(apiSource, data);
    }

    update(id, data) {
        return http.put(`${apiSource}/${id}`, data);
    }

    delete(id) {
        return http.delete(`${apiSource}/${id}`);
    }

    getData(data) {
        return data['hydra:member'];
    }

    getTotalItems(data) {
        return data['hydra:totalItems'];
    }

    getByType(queryParam) {
        const url = `${apiSource}?type=${queryParam}`;
        return http.get(url);
    }

    getByOrder(queryParam) {
        const url = `${apiSource}?order[name]=${queryParam}`;
        return http.get(url);
    }

    getByPostalCode(queryParam) {
        const url = `${apiSource}?postalCode=${queryParam}`;
        return http.get(url);
    }

    getAndStoreOrganisms(value) {
        const dispatch = useDispatch();
        const getOrganisms = value ? this.getByName(value) : this.getAll();

        getOrganisms
            .then((response) => {
                console.log('response', response);
                console.log('response list', this.getData(response.data));
                dispatch({
                    type: 'ORGANISM_SEARCH',
                    payload: this.getData(response.data),
                });
            })
            .catch((error) => {
                console.warn('error', error);
            });
    }

    getByKeys(queryParam) {
        return http.get(`${apiSource}?${queryParam}`);
    }
}

export default new OrganismDataApi();
