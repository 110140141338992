import { AutoComplete } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

const InputAutoCompletion = ({ placeholder, initialValue }) => {
    const [value, setValue] = useState(initialValue);
    const [options, setOptions] = useState([]);
    const screen = useSelector((state) => state.organism.screen);
    const dispatch = useDispatch();

    const getOrganism = (data) => {
        if (screen == 'organization') {
            dispatch({
                type: 'ORGANIZATION_FILTER',
                payload: `postalCode=${data}`,
            });
        } else if (screen == 'pf') {
            dispatch({
                type: 'PF_FILTER',
                payload: `postalCode=${data}`,
            });
        }
    };

    const onChange = (data) => {
        setValue(data);
    };

    React.useEffect(() => {
        if (isEmpty(value)) {
            if (screen == 'organization') {
                dispatch({
                    type: 'ORGANIZATION_FILTER',
                    payload: '',
                });
            } else if (screen == 'pf') {
                dispatch({
                    type: 'PF_FILTER',
                    payload: '',
                });
            }
        }
    }, [value]);

    const OnUpdateSearchableValue = (searchText) => {
        if (searchText.length >= 4) {
            getOrganism(searchText);
        } else {
            setOptions([]);
        }
    };

    return (
        <>
            <AutoComplete
                value={value}
                options={options}
                onSearch={OnUpdateSearchableValue}
                onChange={onChange}
                style={{
                    width: '100%',
                }}
                placeholder={placeholder}
            />
        </>
    );
};

export default InputAutoCompletion;
