import { Button, Col, Row, Typography, DatePicker, Space, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import userServiceApi from '../../services/userServiceApi';
import { groupBy } from 'lodash';
import { useDispatch } from 'react-redux';
import GenericHelper from '../../Helpers/GenericHelper';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const clearType = (list = [], types = []) => {
    return list.filter((p) => !types.includes(p.type));
};

const getData = (items) => {
    let list = groupBy(items, 'type');
    return list;
};

const dateFormat = 'YYYY-MM-DD';

const getDefaultDateRange = () => {
    const today = moment();
    const lastThirtyDays = moment(GenericHelper.getDateThirtyDaysBefore());
    return [lastThirtyDays, today].map((m) => moment(m.format(dateFormat), dateFormat));
};

const updateDateFilters = (items, dateRange) => {
    let list = clearType(items, ['typeDatetime[after]', 'typeDatetime[strictly_before]']);
    if (dateRange) {
        if (dateRange[0]) list.push({ type: 'typeDatetime[after]', value: dateRange[0].format(dateFormat) });
        if (dateRange[1]) list.push({ type: 'typeDatetime[strictly_before]', value: dateRange[1].clone().add(1, 'day').format(dateFormat) });
    }
    return list;
};

const DateRangeFilter = ({ label, showDateRange, setShowDateRange, items = [], setItem }) => {
    const { Text } = Typography;
    const onChangeDate = (dateRange) => {
        setItem(updateDateFilters(items, dateRange));
    };

    useEffect(() => {
        onChangeDate(getDefaultDateRange());
    }, []);

    return (
        <>
            <span className="hastooltip">
                <Button onClick={() => setShowDateRange(!showDateRange)}>
                    <Text style={{ fontWeight: 'bold' }}>{label}</Text>
                </Button>
                <span className={showDateRange ? 'tooltip-filters open' : 'tooltip-filters'}>
                    <Space direction="vertical" size={12}>
                        <RangePicker format="DD/MM/YYYY" defaultValue={getDefaultDateRange} placeholder={['Du', 'Au']} onChange={onChangeDate} />
                    </Space>
                </span>
            </span>
        </>
    );
};

const TextFilter = ({ label, loading = false, placeholder, list = [], items = [], setItem, value, setValue, showTextFilter, setShowTextFilter }) => {
    function handleChange(v) {
        const list = clearType(items, ['counselor']);
        list.push({ type: 'counselor', value: v });
        setValue(v);
        setItem(list);
    }

    return (
        <>
            <span className="hastooltip">
                <Button onClick={() => setShowTextFilter(!showTextFilter)}>
                    <Text style={{ fontWeight: 'bold' }} onClick={null}>
                        {label}
                    </Text>
                </Button>

                <span className={showTextFilter ? 'tooltip-filters open' : 'tooltip-filters'}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={placeholder}
                        optionFilterProp="children"
                        value={value}
                        onChange={(v) => handleChange(v)}
                        loading={loading}
                    >
                        {list.map((p) => (
                            <Select.Option value={p.uid} key={`counselor${p.uid}`}>
                                {`${p.firstName} ${p.lastName}`}
                            </Select.Option>
                        ))}
                    </Select>
                </span>
            </span>
        </>
    );
};

const ManagementFilters = () => {
    const dispatch = useDispatch();
    const [showDateRange, setShowDateRange] = useState(false);
    const [showTextFilter, setShowTextFilter] = useState(false);
    const elements = [setShowDateRange, setShowTextFilter];
    const [counselors, setCouncelor] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [items, setItem] = React.useState([]);
    const [initItems, setInitItems] = useState(false);
    const [value, setValue] = React.useState('');
    const [lastReset, setLastReset] = React.useState(new Date().getTime());
    useEffect(() => {
        if (showDateRange) onClear(0);
    }, [showDateRange]);
    useEffect(() => {
        if (showTextFilter) onClear(1);
    }, [showTextFilter]);
    useEffect(() => {
        if (counselors.length == 0) {
            setLoading(true);
            userServiceApi
                .getByKeys('evenDeleted=true&exists[folders]=true&itemsPerPage=999')
                .then((response) => {
                    setCouncelor(userServiceApi.getData(response.data));
                })
                .catch((error) => {
                    console.warn('error ', error);
                });
            setLoading(false);
        }
    }, []);
    function onClear(position) {
        elements.forEach((p, index) => {
            if (position != index) p(false);
        });
    }
    useEffect(() => {
        if (!initItems) {
            setInitItems(true);
            return;
        }
        const keys = ['typeDatetime[after]', 'typeDatetime[strictly_before]', 'counselor'];
        const list = getData(items);
        let data = '';
        keys.forEach((p) => {
            const elements = list[p];
            if (elements != null && elements != undefined) {
                elements.forEach((item) => {
                    data += `&${item.type}=${item.value}`;
                });
            }
        });
        onLoading(data, dispatch);
    }, [items]);
    return (
        <>
            <Row justify="space-between" className="filters">
                <Col>
                    <DateRangeFilter
                        label={'Période'}
                        setShowDateRange={setShowDateRange}
                        showDateRange={showDateRange}
                        items={items}
                        setItem={setItem}
                        key={lastReset}
                    />
                    <TextFilter
                        list={counselors}
                        label={'Nom du conseiller'}
                        placeholder={'Nom du conseiller'}
                        items={items}
                        value={value}
                        setValue={setValue}
                        setItem={setItem}
                        loading={loading}
                        setShowTextFilter={setShowTextFilter}
                        showTextFilter={showTextFilter}
                    />
                </Col>
                <Col>
                    <ResetFilters label={'Réinitialiser tous les filtres'} setItem={setItem} setValue={setValue} setLastReset={setLastReset} />
                </Col>
            </Row>
        </>
    );
};

const ResetFilters = ({ label, setItem, setValue, setLastReset }) => {
    const { Text } = Typography;
    const onResetFilters = () => {
        setItem(updateDateFilters([], getDefaultDateRange()));
        setValue('');
        setLastReset(new Date().getTime());
    };
    return (
        <Button onClick={onResetFilters}>
            <Text style={{ fontWeight: 'bold' }} underline>
                {label}
            </Text>
        </Button>
    );
};

const onLoading = (data, dispatch) => {
    dispatch({ type: 'USER_STATS_FILTER', payload: data });
};

export default ManagementFilters;
