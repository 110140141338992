// Layout and UI
import './App.css';
import 'antd/dist/antd.css';
import './assets/styles/style.scss';
import React from 'react';

import Home from './components/Home';

function App() {
    return (
        <div id="app-serenicare-wrapper">
            <Home />
        </div>
    );
}

export default App;
