import React, { useEffect, useState } from 'react';
import { Card, List, Typography } from 'antd';
import './styles/calendar.scss';
import GenericHelper from '../../Helpers/GenericHelper';
import DateHelper from '../../Helpers/DateHelper';
import { useSelector } from 'react-redux';
import folderServiceApi from '../../services/folderServiceApi';
import { FolderTypes, getNameFromValue } from '../folders/FolderValues';

const { Title, Text } = Typography;

const HeaderCalendar = () => (
    <Title level={4} className="text-primary-color bg-grey-light" style={{ textAlign: 'center', padding: 10, paddingBottom: 20, paddingTop: 20 }}>
        <i className="left icon-calendar" style={{ padding: 5 }}></i>
        Calendrier
    </Title>
);

const EventType = ({ item }) => {
    return getNameFromValue(FolderTypes, item.type);
};

const renderItem = (item) => {
    return (
        <List.Item>
            <Title level={5} className="text-primary-color" style={{ marginBottom: -25 }}>
                {DateHelper.getDate(item.typeDatetime, 'HH[h]mm')}
            </Title>
            <Title level={5} style={{ color: '#675E51', fontSize: 17, marginBottom: -2 }}>
                {GenericHelper.capitalize(item.customerFullName)}
            </Title>
            <Text>
                <EventType item={item} />
            </Text>
        </List.Item>
    );
};

const ProgramList = () => {
    const [list, setList] = useState([]);
    const [calendarDate, setCalendarDate] = useState('');
    const counselor = useSelector((state) => state.user.id);

    useEffect(() => {
        setCalendarDate(GenericHelper.getHeaderShortDateString());
        if (!counselor) {
            setList([]);
            return;
        }
        folderServiceApi
            .getByKeys('counselor=' + counselor + '&typeDatetime[after]=now&typeDatetime[strictly_before]=tomorrow')
            .then((response) => {
                let data = folderServiceApi.getData(response.data);
                setList(data.sort(DateHelper.sortFolderTypeDatetime));
            })
            .catch((e) => console.warn('Error ', e));
    }, [counselor]);

    return (
        <>
            <Title level={4} className="text-primary-color" style={{ marginLeft: 15, marginTop: 0, marginBottom: -5 }}>
                {calendarDate}
            </Title>
            {list.length > 0 ? (
                <List dataSource={list} renderItem={renderItem} itemLayout="vertical" />
            ) : (
                <div
                    style={{
                        height: 150,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    Vous n&apos;avez plus de rendez-vous pour aujourd&apos;hui
                </div>
            )}
        </>
    );
};

function DayCalendar() {
    return (
        <>
            <Card bodyStyle={{ padding: 0 }} className="box" style={{ width: 315 }}>
                <HeaderCalendar />
                <ProgramList />
            </Card>
        </>
    );
}

export { DayCalendar };
