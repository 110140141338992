import { Divider, Input } from 'antd';
import React from 'react';
import FormikErrorSection from '../Partials/FormikErrorSection';
import StyledLabel from '../Partials/StyledLabel';
import { isEmpty } from 'lodash';

const ContactForm = ({ contact, onChange, values, formik }) => {
    const key = contact.id;

    let contactLabel = key === 0 ? 'Contact principal' : 'Contact secondaire';

    const getErrors = (field, index, fullName) => {
        let rep = null;
        if (!isEmpty(field)) {
            if (!isEmpty(field[index])) {
                rep = field[index][fullName];
            }
        }
        return rep;
    };
    const [focused, setFocused] = React.useState(false);

    return (
        <div style={{ marginBottom: 20 }}>
            {key != 0 && <Divider />}
            <StyledLabel label={contactLabel} />
            <Input
                name={`organismContacts[${key}].fullName`}
                value={values.fullName}
                onChange={onChange}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            />
            <FormikErrorSection field={getErrors(formik.errors.organismContacts, key, 'fullName')} show={focused} />
            <StyledLabel label={`Adresse mail du ${contactLabel.toLowerCase()}`} />
            <Input name={`organismContacts[${key}].email`} value={values.email} onChange={onChange} />
            <FormikErrorSection field={getErrors(formik.errors.organismContacts, key, 'email')} />
            <StyledLabel label={`Téléphone du ${contactLabel.toLowerCase()}`} />
            <Input name={`organismContacts[${key}].phone`} value={values.phone} onChange={onChange} />
            <FormikErrorSection field={getErrors(formik.errors.organismContacts, key, 'phone')} />
        </div>
    );
};

export default ContactForm;
