import React from 'react';

const NotificationsPicto = ({ picto }) => {
    return (
        <>
            <i className={`notifications-picto icon-${picto}`}></i>
        </>
    );
};

export default NotificationsPicto;
