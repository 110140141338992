import { Modal, notification, Typography } from 'antd';
import React from 'react';
import { FolderButton } from '../Button/FolderButton';
import { filterValue, Header, getList, StateLine, ActionButton } from '../../../../components/Partials/tools';
import { CompleteContact } from './completeFolder/CompleteContact';
import { UpdateAppointment } from './completeFolder/CompleteAppointment';
import { useFormik } from 'formik';
import OrganismDataApi from '../../../../services/OrganismDataApi';
import { contactValuesUpdate, deceaseValuesUpdate, folderValueUpdate } from '../folderValues';
import folderServiceApi from '../../../../services/folderServiceApi';
import { getFolder } from '../getFolders';
import { useDispatch } from 'react-redux';
import { canEditFolder } from '../advisorTreatment';
import { CompleteDecease } from './completeFolder/CompleteDecease';
import DeadServiceApi from '../../../../services/DeadServiceApi';
import contactServiceApi from '../../../../services/contactServiceApi';
import organismAVDCServiceApi from '../../../../services/organismAVDCServiceApi';
import DateHelper from '../../../../Helpers/DateHelper';
import refServiceApi from '../../../../services/refServiceApi';
import { isEmpty } from 'lodash';

const { Title } = Typography;

const StepButton = ({ step }) => (
    <div>
        <StateLine list={[{ step: 'Information du défunt' }, { step: 'Suivi du rendez-vous' }, { step: 'Information du client' }]} current={step} />
    </div>
);

const UpdateTitle = ({ step }) => (
    <React.Fragment>
        {step == 2 && (
            <Title style={{ color: '#cb5d4b' }} level={4}>
                Informations du client
            </Title>
        )}
        {step == 1 && (
            <Title style={{ color: '#cb5d4b' }} level={4}>
                Suivi du rendez-vous
            </Title>
        )}
        {step == 0 && (
            <Title style={{ color: '#cb5d4b' }} level={4}>
                Informations du défunt
            </Title>
        )}
    </React.Fragment>
);

const UpdateFolderView = ({ type, folder }) => {
    const [visible, setVisible] = React.useState(false);
    const [organizations, SetOrganization] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const handleShow = () => {
        if (canEditFolder(folder)) {
            setVisible(true);
        } else {
            notification.open({
                message: 'Vous ne pouvez pas modifier ce dossier',
                type: 'warning',
            });
        }
    };
    const handleClose = () => {
        setVisible(false);
    };
    const [step, setStep] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    async function handleForward() {
        if (step < 2) {
            setStep(step + 1);
        } else {
            setLoading(true);
            const data = folderValueUpdate({
                contact: formikContact.values,
                currentFolder: folder,
                decease: formikDead.values,
                appointment: formikAppoint.values,
            });
            const dataDecease = deceaseValuesUpdate({ decease: formikDead });
            const dataContact = contactValuesUpdate({ contact: formikContact });
            await folderServiceApi
                .update(folder.uid, data)
                .then(async () => {
                    await DeadServiceApi.update(folder.dead_complements.uid, dataDecease)
                        .then(() => {})
                        .catch((reason) => {
                            const update = reason.response;
                            if (update.status == 400 || update.status == 422) {
                                notification.warning({
                                    message: update.data['hydra:description'],
                                });
                            }
                        });
                    await contactServiceApi
                        .update(folder.people.uid, dataContact)
                        .then(() => {})
                        .catch((reason) => {
                            const update = reason.response;
                            if (update.status == 400 || update.status == 422) {
                                notification.warning({
                                    message: update.data['hydra:description'],
                                });
                            }
                        });
                    const rep = await getFolder(folder.uid);
                    setLoading(false);
                    setVisible(false);
                    dispatch({ type: 'CURRENT_FOLDER', payload: rep.value });
                    notification.open({
                        message: 'Vous avez modifié le dossier',
                        type: 'success',
                    });
                    setStep(0);
                })
                .catch((reason) => {
                    const update = reason.response;
                    if (update.status == 403) {
                        notification.open({
                            message: 'Vous ne pouvez pas modifier ce dossier',
                            type: 'info',
                        });
                    } else if (update.status == 401) {
                        notification.open({
                            message: 'Une erreur liée à la requête, veuillez réessayer',
                            type: 'info',
                        });
                    } else if (update.status == 400 || update.status == 422) {
                        notification.open({
                            message: 'Vérifiez vos insertions, SVP!',
                            type: 'error',
                        });
                        notification.warning({
                            message: update.data['hydra:description'],
                        });
                    } else {
                        notification.open({
                            message: 'Erreur réseau',
                            type: 'error',
                        });
                    }
                    setLoading(false);
                });
        }
    }
    function handleReturn() {
        if (step > 0) {
            setStep(step - 1);
        }
    }
    function initialize(folder = {}) {
        const address_people = folder.people.address;
        const initialValuesContact = {
            civility: folder.people.civility,
            firstName: filterValue(folder.customerFirstName),
            lastName: filterValue(folder.customerLastName),
            relationship: folder.familyRelationship,
            address: !isEmpty(address_people) ? address_people.street : '',
            postalCode: !isEmpty(address_people) ? address_people.postalUid : null,
            town: '',
            phone: folder.people.phone,
            email: folder.people.email,
            country: folder.people.country,
            socialNumber: filterValue(folder.people.socialNumber),
            payment: folder.paymentMode,
            organization: folder.pfAvdcId,
            counselorName: filterValue(folder.pfCounselorName),
        };

        let date = folder.typeDatetime;
        let time = null;
        if (!isEmpty(date)) {
            [date, time] = DateHelper.getDate(date, 'YYYY-MM-DD HH:mm').split(' ');
        }
        let user = folder.user;
        let counselor = '';
        if (!isEmpty(user)) {
            counselor = user.lastName + ' ' + user.firstName;
        }
        const initialValuesAppoint = {
            counselorName: filterValue(counselor),
            folderType: filterValue(folder.type),
            appointDate: date,
            time: time,
            trackingNumber: filterValue(folder.trackingNumber),
        };

        const address = folder.dead_complements.address;

        const initDeceases = {
            civility: folder.dead_complements.civility,
            firstName: filterValue(folder.dead_complements.firstName),
            lastName: filterValue(folder.dead_complements.lastName),
            maritalStatus: filterValue(folder.deadMaritalStatus),
            address: !isEmpty(address) ? address.street : '',
            postalCode: filterValue(folder.dead_complements.postalCode),
            town: filterValue(folder.dead_complements.town),
            socialNumber: filterValue(folder.dead_complements.socialNumber),
            subName: filterValue(folder.dead_complements.nameBirth),
            deathDate: filterValue(folder.deadDeathDate),
            deathPlace: '',
            funeralDate: filterValue(folder.deadFuneralDate),
            birthDate: filterValue(folder.dead_complements.birthDate),
            birthPlace: filterValue(folder.dead_complements.birthPlace),
            socialStatus: folder.deadSocialSituation,
            complement: !isEmpty(address) ? address.complement : '',
        };

        formikContact.setValues(initialValuesContact);
        formikAppoint.setValues(initialValuesAppoint);
        formikDead.setValues(initDeceases);
    }

    const validateContact = (values) => {
        const errors = {};
        console.log(values);
        return errors;
    };

    const validateAppoint = (values) => {
        const errors = {};
        console.log('use here the validate rules', values);
        return errors;
    };

    const validateDeceases = (values) => {
        const errors = {};
        console.log('use here the validate rules', values);
        return errors;
    };

    const formikContact = useFormik({
        initialValues: {},
        validate: validateContact,
    });

    const formikAppoint = useFormik({
        initialValues: {},
        validate: validateAppoint,
    });

    const formikDead = useFormik({
        initialValues: {},
        validate: validateDeceases,
    });

    const [pfName, setPfName] = React.useState(folder.pfName);
    const [postCode, setPostalCode] = React.useState(folder.people.postalCode);
    const [loadPostal, setLoadPostal] = React.useState(false);
    const [postalCodes, setPostalCodes] = React.useState([]);

    React.useEffect(async () => {
        setIsLoading(true);
        if (isEmpty(pfName)) {
            SetOrganization([]);
            setIsLoading(false);
            return;
        }
        const query = `name=${pfName}`;
        await organismAVDCServiceApi
            .getByKeys(query)
            .then((response) => {
                let list = OrganismDataApi.getData(response.data);
                list = getList('id', 'name', list);
                SetOrganization(list);
            })
            .catch(() => {});
        setIsLoading(false);
    }, [pfName]);

    React.useEffect(async () => {
        setLoadPostal(true);
        if (isEmpty(postCode)) {
            setPostalCodes([]);
            setLoadPostal(false);
            return;
        }
        const query = `postal_code=${postCode}`;
        await refServiceApi
            .getPostalCode(query)
            .then((response) => {
                let list = getList('uid', 'display_name', response.data);
                setPostalCodes(list);
            })
            .catch(() => {});
        setLoadPostal(false);
    }, [postCode]);

    React.useEffect(() => {
        initialize(folder);
    }, [folder]);

    return (
        <React.Fragment>
            <FolderButton onClick={handleShow} type={type} title="Modifier" />
            {visible && (
                <Modal
                    visible={visible}
                    onCancel={handleClose}
                    centered
                    style={{ marginTop: 20, marginBottom: 20 }}
                    okButtonProps={{ hidden: true }}
                    cancelButtonProps={{ hidden: true }}
                    closeIcon="Fermer"
                    wrapClassName="modal"
                    footer={[
                        <ActionButton
                            enabled={true}
                            title={'Suivant'}
                            step={step}
                            key="btn-footer"
                            handleForward={handleForward}
                            handleReturn={handleReturn}
                            loading={loading}
                        />,
                    ]}
                >
                    <Header onClose={handleClose} title="Modifier le dossier" subtitle="Vous pouvez modifier le dossier" />
                    <div style={{ padding: 10, maxWidth: '80%', margin: '0 auto' }}>
                        <StepButton step={step} />
                        <UpdateTitle step={step} />
                        {step == 0 && <CompleteDecease formik={formikDead} />}
                        {step == 2 && (
                            <CompleteContact
                                setPfName={setPfName}
                                organizations={organizations}
                                loading={isLoading}
                                formik={formikContact}
                                setPostalCode={setPostalCode}
                                loadPostal={loadPostal}
                                postalCodes={postalCodes}
                            />
                        )}
                        {step == 1 && <UpdateAppointment formik={formikAppoint} />}
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

export { UpdateFolderView };
