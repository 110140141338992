import DateHelper from './DateHelper';

class GenericHelper {
    capitalize(word) {
        if (word === null) return null;
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    getHeaderDateString() {
        return DateHelper.getDate('dddd DD MMMM YYYY').split(' ').map(this.capitalize).join(' ');
    }

    getHeaderShortDateString() {
        return DateHelper.getDate('dddd DD MMMM').split(' ').map(this.capitalize).join(' ');
    }

    getDateThirtyDaysBefore() {
        let date = new Date();
        date.setDate(date.getDate() - 30);
        return DateHelper.getDate(date, null);
    }
}

export default new GenericHelper();
