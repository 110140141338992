import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Input, List, Row, Typography } from 'antd';
import React from 'react';
import { isEmpty } from 'lodash';
import CommentServiceApi from '../../../services/CommentServiceApi';
import DateHelpers from '../../../Helpers/DateHelper';
import PersonHelper from '../../../Helpers/PersonHelper';

const { Title, Text } = Typography;

function CommentRender({ item, setRefresh, text, setText, reading, setLoading, setReading, loading, setVisible }) {
    const getAvatar = (firstName = '', lastName) => {
        let rep = '';
        if (!isEmpty(firstName)) rep += firstName.substr(0, 1).toUpperCase();
        if (!isEmpty(lastName)) rep += lastName.substr(0, 1).toUpperCase();
        return rep;
    };

    async function handleUpdate(p) {
        if (isEmpty(text)) {
            return;
        }
        setLoading(true);
        const data = { text };
        await CommentServiceApi.update(p.uid, data)
            .then(() => {
                setRefresh(true);
                setReading(true);
            })
            .catch(() => {});
        setLoading(false);
    }

    async function handleDelete(p) {
        setLoading(true);
        await CommentServiceApi.delete(p.uid)
            .then(() => {
                setRefresh(true);
                setReading(null);
                setVisible(true);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const isAuthor = (item) => {
        let rep = false;
        let user = localStorage.getItem('user-info');
        if (user != null) user = JSON.parse(user);
        if (user != null) {
            rep = user['@id'] === item.author['@id'];
        }
        return rep;
    };

    const dateFormat = 'D MMMM YYYY, HH:mm';

    return (
        <List.Item key={`comment${item.uid}`} style={{ borderLeft: '2px solid #cb5d4b', marginBottom: 10, borderBottom: 'none' }}>
            <Row justify="space-between" align="top" gutter={24}>
                <Col>
                    <Avatar size={60} style={{ background: '#f7f7f7', color: '#cb5d4b', fontWeight: 'bold' }}>
                        {getAvatar(item.author.firstName, item.author.lastName)}
                    </Avatar>
                </Col>
                <Col flex="1">
                    <Title level={5} style={{ color: '#cb5d4b', marginBottom: 0 }}>
                        {PersonHelper.getFullName(item.author.firstName, item.author.lastName)}
                    </Title>
                    <Text style={{ color: '#cb5d4b' }}>
                        {DateHelpers.getDate(item.sysCreatedDate, dateFormat)}
                        {item.sysUpdatedDate !== item.sysCreatedDate && (
                            <span>
                                {' '}
                                <i title={DateHelpers.getDate(item.sysUpdatedDate, dateFormat)}>(modifié)</i>
                            </span>
                        )}
                    </Text>
                    {reading != item.uid && (
                        <div>
                            <Input.TextArea readOnly value={item.text} style={{ border: 'none' }} autoSize />
                            {isAuthor(item) && (
                                <Row gutter={16} align="middle" justify="start">
                                    <Col>
                                        <Button
                                            onClick={() => {
                                                setText(item.text);
                                                setReading(item.uid);
                                            }}
                                            type="text"
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button type="text" onClick={() => handleDelete(item)}>
                                            <DeleteOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    )}
                    {reading == item.uid && (
                        <div>
                            <Input.TextArea
                                rows={2}
                                value={text}
                                onChange={(v) => {
                                    setText(v.target.value);
                                }}
                                disabled={loading}
                                autoSize
                            />
                            <Row align="middle" gutter={16} justify="start" style={{ marginTop: 10 }}>
                                <Col>
                                    <Button onClick={() => handleUpdate(item)} loading={loading} style={{ background: '#cb5d4b', color: '#fff' }}>
                                        Modifier
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        disabled={loading}
                                        onClick={() => {
                                            setReading(true);
                                        }}
                                        className="btn3"
                                    >
                                        Annuler
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
            </Row>
        </List.Item>
    );
}

export { CommentRender };
