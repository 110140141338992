import { Avatar, Button, Col, Input, List, Row, Skeleton, Tag, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import HeaderViewFolder from './Partials/Headers/headerViewFolder';
import { LinkedFolder } from './Partials/Modal/linkedFolder';
import { UpdateFolder } from './Partials/Modal/updateFolder';
import { ContactStatus } from './Partials/Modal/ContactStatus';
import { PaymentStatus } from './Partials/Modal/PaymentStatus';
import { StateButton, stepsBilan, stepsEntretien, stepsEntretienReopened } from './Partials/Button/FolderAriane';
import DateHelper from '../../Helpers/DateHelper';
import { FolderAddDocs } from './Partials/Button/FolderAddDocs';
import { FolderConsultDocs } from './Partials/Button/FolderConsultDoc';
import { isEmpty } from 'lodash';
import { getComments, getFolder } from './Partials/getFolders';
import CommentServiceApi from '../../services/CommentServiceApi';
import { CommentRender } from './Partials/commentView';
import { DeleteComment } from './Partials/Modal/DeleteComment';
import { FolderTypes, getNameFromValue } from './FolderValues';

const { Title, Text, Paragraph } = Typography;

const styles = {
    step1: {
        textAlign: 'center',
        color: '#cb5d4b',
        fontSize: 12,
        marginBottom: -5,
        fontWeight: 'bold',
        marginTop: 0,
    },
    step2: {
        textAlign: 'center',
        color: '#675e51',
        fontSize: 12,
        marginBottom: -5,
        fontWeight: 'bold',
        marginTop: 0,
    },
};

const checkDate = (value) => {
    let rep = '';
    const date = DateHelper.addMonth(6, value)?.toDate();
    const dateToday = DateHelper.getDateOnly().toDate();
    const time1 = date?.getTime();
    const timeToday = dateToday.getTime();
    if (time1 > timeToday) {
        rep = 'Dans le délai';
    } else {
        rep = 'Hors délai';
    }
    return rep;
};

const StateComponent = ({ value, step = '', current = 0 }) => (
    <Row justify="center">
        <Col flex="1">
            <Avatar style={{ background: current ? '#cb5d4b' : '#675e51', fontWeight: 'bolder' }} size={60}>
                {value + 1}
            </Avatar>
            <div style={{ position: 'absolute', marginTop: 5 }}>
                <div style={{ position: 'relative', width: 60, textAlign: 'center' }}>
                    <Title level={5} style={current !== value ? styles.step1 : styles.step2} className={current ? 'label-step active' : 'label-step'}>
                        {step}
                    </Title>
                </div>
            </div>
        </Col>
    </Row>
);

const StateLine = ({ list = [], level = -1 }) => {
    return (
        <Row justify="center" align="middle" style={{ paddingLeft: 20, paddingRight: 20, maxWidth: 730, margin: '0 auto 30px' }}>
            {list.map((item, index) => {
                if (index != 0) {
                    return (
                        <React.Fragment key={`stateLine${index}`}>
                            <Col flex="1">
                                <div style={{ height: 2, background: level >= index ? '#cb5d4b' : '#675e51' }} />
                            </Col>
                            <Col>
                                <StateComponent current={level >= index} step={item.step} key={`state${index}`} value={index} />
                            </Col>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <Col key={`stateline${index}`}>
                            <StateComponent current={level >= index} step={item.step} key={`state${index}`} value={index} />
                        </Col>
                    );
                }
            })}
        </Row>
    );
};

const HeadetTitle = ({ label }) => {
    return (
        <Row style={{ borderBottom: '1px solid #FFEAE4', maxWidth: 1000, marginBottom: 20, marginTop: 20 }}>
            <Col flex="1">
                <Title style={{ color: '#cb5d4b' }} level={4}>
                    {label}
                </Title>
            </Col>
        </Row>
    );
};

const HeadetTitleWithButton = ({ label, folder, type }) => {
    return (
        <Row align="middle" justify="center" style={{ borderBottom: '1px solid #FFEAE4', marginBottom: 20, display: 'flex', alignItems: 'baseline' }}>
            <Col flex="1">
                <Title style={{ color: '#cb5d4b' }} level={4}>
                    {label}
                </Title>
            </Col>
            {type == 'consultDoc' && (
                <Col style={{ marginBottom: 2 }}>
                    <FolderConsultDocs folder={folder} />
                </Col>
            )}
            {type == 'addDoc' && (
                <Col style={{ marginBottom: 10 }}>
                    <FolderAddDocs folder={folder} />
                </Col>
            )}
        </Row>
    );
};

const ItemValue = ({ title, value }) => (
    <React.Fragment>
        <Title level={5} style={{ marginBottom: 0 }}>
            {title}
        </Title>
        <Text level={5}>{value}</Text>
    </React.Fragment>
);

const FolderViewHeader = ({ folder, currentUser = {} }) => {
    return (
        <React.Fragment>
            {folder && (
                <div>
                    <Row>
                        <Col>
                            <div>
                                <Title level={3} style={{ color: '#cb5d4b', marginBottom: 0 }}>
                                    Fiche Dossier
                                </Title>
                            </div>
                            <div>
                                <Text style={{ marginBottom: 0 }}>
                                    Statut du contact: <ContactStatus value={folder} />
                                </Text>
                            </div>
                            <div>
                                <Text style={{ marginBottom: 0 }}>
                                    Statut de paiement: <PaymentStatus value={folder} />
                                </Text>
                            </div>
                        </Col>
                        <Col flex="1">
                            <Row gutter={0} justify="end">
                                <Col>
                                    <UpdateFolder user={currentUser} folder={folder} type={folder.state} btnType={2} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={0} justify="start" style={{ marginTop: 10 }}>
                        {/*<Col>
                            <FolderButton type={3} title="Démarrer le questionnaire" />
                        </Col>*/}
                        {folder.state != 'draft' && (
                            <Col>
                                <LinkedFolder btnTitle="Voir les dossiers liés" btnType={1} folder={folder} />
                            </Col>
                        )}
                        {/*<Col>
                            <FolderButton type={1} title="Ouvrir un autre dossier" />
                        </Col>*/}
                    </Row>
                </div>
            )}
        </React.Fragment>
    );
};

const FolderViewCustomer = ({ folder }) => {
    let payment = '';
    if (folder.paymentMode === 'paygreen') {
        payment = 'Paygreen';
    } else if (folder.paymentMode === 'PF') {
        payment = 'Paiement en PF';
    } else {
        payment = 'Pas de paiement';
    }
    return (
        <React.Fragment>
            <HeadetTitle label="Informations du client" />
            <Row justify="space-between">
                <Col flex="1">
                    <ItemValue title="Nom" value={folder.customerLastName} />
                    <ItemValue title="Lien de parenté avec le défunt" value={folder.familyRelationship} />
                    <ItemValue title="Code postal" value={folder.people.postalCode} />
                    <ItemValue title="Numéro de dossier" value={folder.num} />
                    <ItemValue title="Organisme de prise en charge" value={folder.pfName} />
                    <ItemValue title="Numéro de sécurité sociale du client" value={folder.people.socialNumber} />
                    <ItemValue title="Adresse email" value={folder.customerEmail} />
                </Col>
                <Col flex="1">
                    <ItemValue title="Prénom" value={folder.customerFirstName} />
                    <ItemValue title="Adresse" value={!isEmpty(folder.people.address) ? folder.people.address.street : ''} />
                    <ItemValue title="Pays" value={folder.people.country} />
                    <ItemValue title="Ville" value={folder.people.town} />
                    <ItemValue title="Règlement" value={payment} />
                    <ItemValue title="Nom du conseiller funéraire" value={folder.pfCounselorName} />
                    <ItemValue title="Numéro de téléphone" value={folder.customerPhone} />
                </Col>
            </Row>
        </React.Fragment>
    );
};

const getCounselorName = (lastName, firstName) => {
    let rep = '';
    if (!isEmpty(lastName)) rep += lastName + ' ';
    if (!isEmpty(firstName)) rep += firstName;
    return rep;
};

const getFolderType = (folder) => {
    return (
        <>
            {getNameFromValue(FolderTypes, folder.type)}
            {folder.direct && <span className="text-primary-color"> (pris par la famille)</span>}
        </>
    );
};

const FolderViewFollow = ({ folder, dateFormat = 'DD MMMM YYYY [à] HH[h]mm' }) => (
    <React.Fragment>
        <HeadetTitle label="Suivi du rendez-vous" />
        <Row justify="space-between">
            <Col flex="1">
                <ItemValue title="Nom du conseiller" value={getCounselorName(folder.user.lastName, folder.user.firstName)} />
                <ItemValue title="Date du bilan" value={DateHelper.getDate(folder.bilanDatetime, dateFormat)} />
            </Col>
            <Col flex="1">
                <ItemValue title="Type de dossier" value={getFolderType(folder)} />
                <ItemValue title="Date de l’entretien" value={DateHelper.getDate(folder.entretienDatetime, dateFormat)} />
            </Col>
        </Row>
        {folder.type === 'entretien' && folder.state === 'reopened' && (
            <div>
                <Title level={5} style={{ opacity: 0.8, marginTop: 20 }}>
                    Délai de suivi si réouverture
                </Title>
                <Title level={5} style={{ opacity: 0.6, fontWeight: 'normal', marginTop: -10 }}>
                    {checkDate(folder.entretienDatetime)}
                </Title>
            </div>
        )}
        <Row justify="space-between">
            <Col flex="1">
                <React.Fragment>
                    <Title level={5} style={{ marginTop: 20 }}>
                        Informations diverses
                    </Title>
                    <Paragraph level={5} style={{ whiteSpace: 'pre-wrap' }}>
                        {folder.misc}
                    </Paragraph>
                </React.Fragment>
            </Col>
            <Col flex="1">
                {folder.direct && (
                    <React.Fragment>
                        <Title level={5} style={{ marginTop: 20 }}>
                            Comment le contact a découvert le service
                        </Title>
                        <Paragraph level={5} style={{ whiteSpace: 'pre-wrap' }}>
                            {folder.howDiscovered}
                        </Paragraph>
                    </React.Fragment>
                )}
            </Col>
        </Row>
        <Row justify="space-between">
            <Col flex="1">
                <ItemValue title="Numéro de suivi La Poste" value={folder.trackingNumber} />
            </Col>
        </Row>
    </React.Fragment>
);

const FolderViewDeceased = ({ folder, dateFormat = 'DD/MM/YYYY' }) => (
    <React.Fragment>
        <HeadetTitle label="Informations du défunt" />
        <Row justify="space-between">
            <Col flex="1">
                <ItemValue title="Nom du défunt" value={folder.deadFullName} />
                <ItemValue
                    title="Nom de naissance"
                    value={
                        !isEmpty(folder.dead_complements.nameBirth)
                            ? folder.dead_complements.nameBirth
                            : '' + ' ' + !isEmpty(folder.dead_complements.firstNameBirth)
                            ? folder.dead_complements.firstNameBirth
                            : ''
                    }
                />
                <ItemValue title="Situation sociale" value={folder.deadSocialSituation} />
                <ItemValue title="Date des obsèques" value={DateHelper.getDate(folder.deadFuneralDate, dateFormat)} />
            </Col>
            <Col flex="1">
                <ItemValue title="Situation matrimoniale" value={folder.deadMaritalStatus} />
                <ItemValue title="Numéro de sécurité sociale du défunt" value={folder.dead_complements.socialNumber} />
                <ItemValue title="Date de décès" value={DateHelper.getDate(folder.deadDeathDate, dateFormat)} />
            </Col>
        </Row>
    </React.Fragment>
);

const FolderViewDeceasedState = ({ folder, setFolder }) => {
    const [list, setList] = React.useState([]);
    const [step, setStep] = React.useState(-1);
    let index = -1;
    React.useEffect(() => {
        let steps = null;
        switch (folder.type) {
            case 'bilan':
                steps = stepsBilan;
                break;
            case 'entretien':
                if (folder.state == 'reopened') {
                    steps = stepsEntretienReopened;
                } else {
                    steps = stepsEntretien;
                }
                break;
        }
        if (steps !== null) {
            setList(steps);
            index = steps.findIndex((p) => p.uid == folder.state);
            setStep(index);
        }
    }, [folder]);
    return (
        <React.Fragment>
            <HeadetTitle label="État du dossier" />
            <StateLine list={list} level={step} />
            <Row justify="center" align="middle">
                <Col>
                    <StateButton folder={folder} setFolder={setFolder} />
                </Col>
            </Row>
        </React.Fragment>
    );
};

const FolderViewComments = ({ folder, setFolder }) => {
    const [value, setValue] = React.useState('');
    const [disabled, setDisabled] = React.useState(false);
    const [comments, setComments] = React.useState(folder.comments);
    const [avatar, setAvatar] = React.useState('');
    const [refresh, setRefresh] = React.useState(false);
    const [text, setText] = React.useState('');
    const [reading, setReading] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        let user = localStorage.getItem('user-info');
        if (user != null) {
            user = JSON.parse(user);
            let rep = '';
            if (!isEmpty(user.firstName)) rep += user.firstName.substr(0, 1).toUpperCase();
            if (!isEmpty(user.lastName)) rep += user.lastName.substr(0, 1).toUpperCase();
            setAvatar(rep);
        }
        setComments(folder.comments);
        setRefresh(false);
    }, []);

    React.useEffect(() => {
        if (refresh == true) {
            getComments(folder.uid).then((list) => {
                const p = folder;
                p.comments = list;
                setFolder(p);
                setComments(list);
                setRefresh(false);
                setLoading(false);
            });
        }
    }, [refresh]);

    async function handleSave() {
        if (isEmpty(value)) {
            return;
        }
        setDisabled(true);
        const data = {};
        data.folder = folder['@id'];
        data.text = value;
        await CommentServiceApi.create(data)
            .then(async () => {
                const list = await getComments(folder.uid);
                const p = folder;
                p.comments = list;
                setFolder(p);
                setValue('');
                setComments(list);
            })
            .catch(() => {
                setDisabled(false);
            });
        setDisabled(false);
    }

    return (
        <React.Fragment>
            <div style={{ paddingLeft: 15, paddingTop: 15 }}>
                <Title level={4} style={{ color: '#cb5d4b', marginBottom: 20 }}>
                    Commentaires
                </Title>
                <Row align="middle" gutter={8} style={{ marginLeft: 15, marginBottom: 20 }}>
                    <Col>
                        <Avatar size="large" style={{ background: '#f7f7f7', color: '#cb5d4b', fontWeight: 'bold' }}>
                            {avatar}
                        </Avatar>
                    </Col>
                    <Col flex="1" style={{ maxWidth: 600 }}>
                        <Row align="middle">
                            <Col flex="1">
                                <Input.TextArea
                                    type="text"
                                    placeholder="Laisser un message"
                                    disabled={disabled}
                                    value={value}
                                    onChange={(v) => setValue(v.target.value)}
                                    style={{
                                        borderRadius: 60,
                                        paddingLeft: 21,
                                        paddingRight: 55,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                />
                            </Col>
                            <Col style={{ marginLeft: -50 }}>
                                <Button
                                    shape="round"
                                    title="Envoyer"
                                    disabled={disabled}
                                    style={{
                                        background: '#cb5d4b',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        height: 50,
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                    }}
                                    color="#cb5d4b"
                                    onClick={handleSave}
                                    size="large"
                                    loading={disabled}
                                >
                                    Envoyer
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <List
                dataSource={comments}
                renderItem={(v) => (
                    <CommentRender
                        item={v}
                        reading={reading}
                        setLoading={setLoading}
                        setRefresh={setRefresh}
                        setReading={setReading}
                        setText={setText}
                        setVisible={setVisible}
                        text={text}
                        loading={loading}
                        key={`item${v.uid}`}
                    />
                )}
                style={{ border: 'none' }}
                itemLayout="vertical"
                locale={{ emptyText: 'Aucun commentaire' }}
            />
            <DeleteComment handleClose={() => setVisible(false)} visible={visible} />
        </React.Fragment>
    );
};

const FolderTransmitted = ({ list = [], folder }) => {
    return (
        <React.Fragment>
            <div className="box" style={{ padding: 20 }}>
                <HeadetTitleWithButton label="Documents transmis par le client" folder={folder} type="consultDoc" />
                <Row>
                    {list.map((item, index) => (
                        <Col key={`docT${index}`}>
                            <Tag style={{ fontWeight: 'bolder', margin: 8, padding: 5, paddingLeft: 8, paddingRight: 5, borderRadius: 20 }}>
                                {`${item.name} `}
                                <Text style={{ fontWeight: 'normal' }}>{`${item.count}`}</Text>
                            </Tag>
                        </Col>
                    ))}
                </Row>
            </div>
        </React.Fragment>
    );
};

const FolderToProvide = ({ list = [], folder }) => {
    return (
        <React.Fragment>
            <div className="box" style={{ padding: 20 }}>
                <HeadetTitleWithButton label="Documents à fournir au client" type="addDoc" folder={folder} />
                <Row>
                    {list.map((item, index) => (
                        <Col key={`docP${index}`}>
                            <Tag style={{ fontWeight: 'bolder', margin: 8, padding: 5, paddingLeft: 8, paddingRight: 5, borderRadius: 20 }}>
                                {`${item.name} `}
                                <Text style={{ fontWeight: 'normal' }}>{`${item.count}`}</Text>
                            </Tag>
                        </Col>
                    ))}
                </Row>
            </div>
        </React.Fragment>
    );
};

function FolderView() {
    const { uid } = useParams();
    const dispatch = useDispatch();
    const [selectedFolder, setSelectedFolder] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const folderStore = useSelector((state) => state);
    const [folder, setFolder] = React.useState(null);
    const currentFolder = useSelector((state) => state.folder.currentFolder);
    const [currentUser, setCurrentUser] = React.useState({});

    const handleDisplayFolder = (value) => {
        try {
            setSelectedFolder(value);
            dispatch({ type: 'CURRENT_FOLDER', payload: value });
        } catch (error) {
            console.log('error', error);
            console.log(selectedFolder);
            console.log(folderStore);
        }
    };

    useEffect(async () => {
        try {
            let user = localStorage.getItem('user-info');
            if (user != null && user != undefined) {
                setCurrentUser(JSON.parse(user));
            }
            let value = {};
            if (currentFolder.uid != uid) {
                setIsLoading(true);
                const response = await getFolder(uid);
                value = response.value;
                setFolder(value);
                handleDisplayFolder(value);
            } else {
                setFolder(currentFolder);
            }
        } catch (error) {
            console.log('ERROR', error);
            setFolder(undefined);
        }
        setIsLoading(false);
    }, [currentFolder, uid]);

    return (
        <React.Fragment>
            {folder === undefined && <Redirect to="/folders" />}
            {isLoading === true || folder === null ? (
                <Row>
                    <Col flex="1">
                        <Skeleton active />
                        <Skeleton active />
                    </Col>
                </Row>
            ) : folder !== null && folder !== undefined ? (
                <React.Fragment>
                    <HeaderViewFolder key="f" handleBackToList={() => {}} />
                    <div className="box" style={{ padding: 15 }}>
                        <FolderViewHeader currentUser={currentUser} folder={folder} />
                        <FolderViewCustomer folder={folder} />
                        <FolderViewFollow folder={folder} />
                        <FolderViewDeceased folder={folder} />
                        <FolderViewDeceasedState folder={folder} setFolder={setFolder} />
                    </div>
                    <FolderTransmitted list={folder.foDocuments} folder={folder} />
                    <FolderToProvide list={folder.boDocuments} folder={folder} />
                    <div className="box" style={{ paddingRight: 20, marginTop: 20, paddingBottom: 20 }}>
                        <FolderViewComments folder={folder} setFolder={setFolder} />
                    </div>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
}

export { FolderView };
