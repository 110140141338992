import { Modal, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import StepProgress from '../Partials/StepProgress';
import EditOrganismForm from './editOrganism';
import { getThemes } from './Partials/getOrganism';

const EditOrganismButton = ({ label, organism, setRefresh }) => {
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [step, setStep] = React.useState(0);

    const [themes, setThemes] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const showModal = () => {
        setVisible(true);
    };

    const HandleCancel = () => {
        setVisible(false);
    };

    const handleOk = () => {
        if (step === 1) {
            setConfirmLoading(true);
            setTimeout(() => {
                setVisible(false);
                setConfirmLoading(false);
            }, 2000);
        } else {
            setStep((1 + step) % 2);
        }
    };

    const onStepChange = (step) => {
        setStep(step || 0);
    };

    React.useEffect(() => {
        getThemes()
            .then((response) => {
                setThemes(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    // step progress items
    const stepProgressItems = ['Informations de l’organisme', 'Ajout des contacts'];

    return (
        <>
            <Button shape="round" className="button" onClick={showModal}>
                {label}
            </Button>
            <Modal
                title={
                    <div>
                        <h2 style={{ fontWeight: 'bold', color: '#CB5D4B', marginTop: 5, textAlign: 'center', fontSize: 20 }}>
                            Modifier un organisme
                        </h2>
                        <h3 style={{ textAlign: 'center', fontSize: 14, margin: '0 auto 32px' }}>Vous pouvez modifier un organisme</h3>
                    </div>
                }
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={HandleCancel}
                footer={null}
                closeIcon="Fermer"
                onOk={handleOk}
                wrapClassName="modal"
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                okText={<FormAction step={step} onStepChange={onStepChange} />}
            >
                {!loading && (
                    <>
                        <StepProgress items={stepProgressItems} active={'active'} step={step} />
                        <EditOrganismForm
                            setVisible={setVisible}
                            organismItem={organism}
                            themes={themes}
                            step={step}
                            onStepChange={onStepChange}
                            visible={visible}
                            HandleCancel={HandleCancel}
                            setRefresh={setRefresh}
                        />
                    </>
                )}
            </Modal>
        </>
    );
};

const FormAction = ({ step }) => {
    const label = step === 0 ? 'Suivant' : 'Valider';

    return <Text style={{ color: '#FFF', fontWeight: 'bold' }}>{label}</Text>;
};

export default EditOrganismButton;
