import http from './apiConfig';

const deadApiSource = 'dead_complements';

class DeadDataApi {
    getAll() {
        return http.get(deadApiSource);
    }

    get(id) {
        return http.get(`${deadApiSource}/${id}`);
    }

    getUri(id) {
        return http.get(id);
    }

    create(data) {
        return http.post(deadApiSource, data);
    }

    update(id, data) {
        return http.put(`${deadApiSource}/${id}`, data);
    }

    delete(id) {
        return http.delete(`${deadApiSource}/${id}`);
    }

    getByKeys(queryParam) {
        return http.get(`${deadApiSource}?${queryParam}`);
    }

    getData(data) {
        if (data['hydra:member']) return data['hydra:member'];
        return [];
    }
}

export default new DeadDataApi();
