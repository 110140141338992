import React from 'react';
import { Typography } from 'antd';
import DateHelper from './DateHelper';

export const eventTypeList = [
    { value: 'new_appointment', label: 'Nouveau rendez-vous' },
    { value: 'canceled', label: 'Rendez-vous annulé' },
    { value: 'reassigned', label: 'Dossier réassigné' },
    { value: 'new_document', label: 'Documents transmis' },
];
class NotificationHelper {
    displayFullName(dataNotifs, type, dead) {
        // console.log(dataNotifs);
        const { Text } = Typography;
        if (type === 'Folder') {
            const fullName = dead ? <Text strong>{dataNotifs.deadFullName}</Text> : <Text>{dataNotifs.customerFullName}</Text>;
            return <>{fullName}</>;
        } else {
            const fullName = dead ? <Text>{dataNotifs.data.deadFullName}</Text> : <Text>{dataNotifs.data.customerFullName}</Text>;
            return <>{fullName}</>;
        }
    }

    formatDate(date) {
        return new Date(date).toLocaleDateString();
    }

    allMeetingsList(data) {
        const allMeetings = [];
        data.flat().forEach(function (el) {
            allMeetings.push({
                id: el.uid,
                type: el['@type'],
                name: el.data.customerFullName,
                date: new Date(el.data.datetime).toLocaleDateString(),
                hour: DateHelper.getDate(el.data.datetime, 'HH[h]mm'),
                folderType: el.data.type,
            });
        });
        return allMeetings;
    }

    allToSendList(data) {
        const allToSend = [];
        data.flat().forEach(function (el) {
            allToSend.push({
                date: new Date(el.data.datetime).toLocaleDateString(),
                hour: DateHelper.getDate(el.data.datetime, 'HH[h]mm'),
            });
        });
        return allToSend;
    }

    getTypeValue(value) {
        return eventTypeList.find((p) => p.value === value)?.label ?? value;
    }
}

export default new NotificationHelper();
