import { isEmpty } from 'lodash';
import organismAVDCServiceApi from '../../../services/organismAVDCServiceApi';
import OrganismDataApi from '../../../services/OrganismDataApi';
import OrganizationThemeServiceApi from '../../../services/OrganizationThemeServiceApi';

async function getOrganizations(page, data) {
    let list = [];
    let total = 0;
    let query = `page=${page}&itemsPerPage=10`;
    if (!isEmpty(data)) {
        query += `&${data}`;
        if (data.indexOf('order[name]') == -1) {
            query += `&order[name]=asc`;
        }
    } else {
        query += `&order[name]=asc`;
    }
    await OrganismDataApi.getByKeys(query)
        .then(async (response) => {
            list = OrganismDataApi.getData(response.data);
            total = OrganismDataApi.getTotalItems(response.data);
        })
        .catch(() => {});
    return { list, total };
}

async function getPF(page, data) {
    let list = [];
    let total = 0;
    let query = `page=${page}&limit=10`;
    if (!isEmpty(data)) {
        query += `&${data}`;
        if (data.indexOf('order[name]') == -1) {
            query += `&order[name]=asc`;
        }
    } else {
        query += `&order[name]=asc`;
    }
    await organismAVDCServiceApi
        .getByKeys(query)
        .then(async (response) => {
            list = organismAVDCServiceApi.getData(response.data);
            total = organismAVDCServiceApi.getTotalItems(response.data);
        })
        .catch(() => {});
    return { list, total };
}

async function getThemes() {
    let list = [];
    await OrganizationThemeServiceApi.getByKeys(`order[name]=asc`)
        .then((response) => {
            list = OrganizationThemeServiceApi.getData(response.data);
        })
        .catch(() => {});
    return list;
}

export { getOrganizations, getPF, getThemes };
