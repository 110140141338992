import { Viewer, Worker } from '@react-pdf-viewer/core';
import { Modal, Skeleton, Typography, Progress, Row, Col, Image, Spin } from 'antd';
import React from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';

function PdfReader({ visible = false, url = null, handleClose, load = false, type = null }) {
    const [progression, setProgression] = React.useState(0);
    React.useEffect(() => {
        if (url == null) setProgression(0);
    }, [url]);

    return (
        <React.Fragment>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Modal
                    visible={visible}
                    onCancel={handleClose}
                    okButtonProps={{ hidden: true }}
                    cancelButtonProps={{ hidden: true }}
                    centered
                    style={{ margin: 20 }}
                    wrapClassName="modal"
                    closeIcon="Fermer"
                >
                    <div style={{ paddingTop: 20 }}>
                        {url != null &&
                            (type === 'application/pdf' ? (
                                <Viewer
                                    fileUrl={url}
                                    renderLoader={(rep) => {
                                        setProgression(rep);
                                    }}
                                    renderError={() => (
                                        <Typography.Title style={{ textAlign: 'center' }}>
                                            Erreur lors de la lecture du document. Veuillez réessayer!
                                        </Typography.Title>
                                    )}
                                />
                            ) : (
                                <Image
                                    src={url}
                                    placeholder={
                                        <Row align="middle" justify="center" style={{ minHeight: 200, width: '100%' }}>
                                            <Spin size="large" />
                                        </Row>
                                    }
                                    onError={() => (
                                        <Typography.Title style={{ textAlign: 'center' }}>
                                            {`Erreur lors de l'affichage de l'image. Veuillez réessayer!`}
                                        </Typography.Title>
                                    )}
                                />
                            ))}
                        {load == true && url == null && <Skeleton active />}
                        {progression > 0 && progression < 100 && !load && (
                            <Row style={{ width: '100%' }} align="center">
                                <Col>
                                    <Progress type="circle" percent={progression.toFixed(0)} status={progression == 0 ? 'normal' : 'active'} />
                                </Col>
                            </Row>
                        )}
                    </div>
                </Modal>
            </Worker>
        </React.Fragment>
    );
}
export { PdfReader };
