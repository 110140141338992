import { Modal, Typography, List, Col, Row } from 'antd';
import React from 'react';
import { FolderButton } from '../Button/FolderButton';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import folderServiceApi from '../../../../services/folderServiceApi';
import { getFolder } from '../getFolders';

const { Title, Text } = Typography;

const Header = () => {
    return (
        <React.Fragment>
            <Title
                level={5}
                style={{
                    color: '#CB5D4B',
                    fontSize: 18,
                    display: 'block',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 12,
                }}
            >
                Voir les dossiers liés
            </Title>
            <div style={{ textAlign: 'center', maxWidth: 500, marginBottom: 20 }}>
                <Text style={{ textAlign: 'center', fontSize: 14 }}>Vous pouvez voir les dossiers des autres défunts liés à ce client</Text>
            </div>
        </React.Fragment>
    );
};

const Folders = ({ list = [], renderItem, isLoading = true }) => {
    return (
        <List
            renderItem={renderItem}
            dataSource={list}
            style={{ maxHeight: 250, overflow: 'auto', marginTop: 30, margin: 20 }}
            loading={isLoading}
            locale={{ emptyText: 'Aucun autre dossier lié au contact' }}
        />
    );
};

function LinkedFolder({ folder, btnType, btnTitle }) {
    const [visible, setVisible] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [list, setList] = React.useState([]);
    let history = useHistory();
    const dispatch = useDispatch();

    const handleOpenFolder = async (value) => {
        const response = await getFolder(value.uid);
        dispatch({ type: 'CURRENT_FOLDER', payload: response.value });
        history.push('/folders');
        history.push(`/folders/${value.uid}/view`);
        setVisible(false);
    };
    const handleClose = () => {
        setVisible(false);
    };
    const handleShow = () => {
        setVisible(true);
    };

    const RenderLinkedFolder = (item) => {
        return (
            <Row align="middle" justify="space-between">
                <Col flex="1">
                    <div>
                        <Title level={5} style={{ marginBottom: 0 }}>
                            {item.deadFullName}
                        </Title>
                        <Text>{item.familyRelationship}</Text>
                    </div>
                </Col>
                <Col>
                    <FolderButton title="Voir le dossier" type={1} onClick={() => handleOpenFolder(item)} />
                </Col>
            </Row>
        );
    };

    async function onLoading(customer, uid) {
        setIsLoading(true);
        const data = `customer=${customer}`;
        await folderServiceApi
            .getByKeys(data)
            .then((response) => response.data)
            .then((response) => {
                let items = folderServiceApi.getData(response);
                items = items.filter((p) => p.uid != uid);
                setList(items);
            })
            .catch((e) => console.warn('Error ', e));
    }

    React.useEffect(async () => {
        await onLoading(folder.customer, folder.uid);
        setIsLoading(false);
    }, [folder]);

    return (
        <React.Fragment>
            <FolderButton onClick={handleShow} type={btnType} title={btnTitle} />
            <Modal
                visible={visible}
                onCancel={handleClose}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                closeIcon="Fermer"
                wrapClassName="modal"
                centered
            >
                <div>
                    <Header onClose={handleClose} />
                    <Folders list={list} renderItem={RenderLinkedFolder} isLoading={isLoading} />
                </div>
            </Modal>
        </React.Fragment>
    );
}

export { LinkedFolder };
