import { Button, Col, Row, Typography, DatePicker, Checkbox, Select } from 'antd';
import React, { useState } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FolderStatus, FolderTimes, FolderTypes } from './FolderValues';
import { useDispatch } from 'react-redux';
import userServiceApi from '../../services/userServiceApi';
import { groupBy } from 'lodash';
import DateHelper from '../../Helpers/DateHelper';

const { Text } = Typography;

const getData = (items) => {
    let list = groupBy(items, 'type');
    return list;
};

const clearType = (list = [], type) => {
    return list.filter((p) => p.type !== type);
};

const TypeFilter = ({ label, items = [], setItem, types, setType, showTypeFilter, setShowTypeFilter }) => {
    function handleChange(value) {
        let list = clearType(items, 'type[]');
        const elements = [];
        if (value.length > 0) {
            value.forEach((p) => {
                list.push({ type: 'type[]', value: p });
                elements.push(p);
            });
        }
        setItem(list);
        setType(elements);
    }

    return (
        <>
            <span className="hastooltip">
                <Button onClick={() => setShowTypeFilter(!showTypeFilter)}>
                    <Text style={{ fontWeight: 'bold' }} onClick={null}>
                        {label}
                    </Text>
                </Button>
                <span className={showTypeFilter ? 'tooltip-filters open' : 'tooltip-filters'}>
                    <Checkbox.Group
                        options={FolderTypes.map((item) => ({ label: item.name, value: item.value }))}
                        onChange={handleChange}
                        value={types}
                    />
                </span>
            </span>
        </>
    );
};

const StatusFilter = ({ label, items, setItem, states, setState, showStatusFilter, setShowStatusFilter }) => {
    function handleChange(value) {
        const list = clearType(items, 'state[]');
        const elements = [];
        if (value.length > 0) {
            value.forEach((p) => {
                list.push({ type: 'state[]', value: p });
                elements.push(p);
            });
        }
        setItem(list);
        setState(elements);
    }

    return (
        <>
            <span className="hastooltip">
                <Button onClick={() => setShowStatusFilter(!showStatusFilter)}>
                    <Text style={{ fontWeight: 'bold' }}>{label}</Text>
                </Button>
                <span className={showStatusFilter ? 'tooltip-filters open' : 'tooltip-filters'}>
                    <Checkbox.Group
                        options={FolderStatus.map((item) => ({ label: item.name, value: item.value }))}
                        onChange={handleChange}
                        value={states}
                    />
                </span>
            </span>
        </>
    );
};

const DateFilter = ({ label, showDatePicker, setShowDatePicker, value, setValue, items, setItem }) => {
    const onChangeDate = (date, dateString) => {
        let list = items;
        list = clearType(list, 'bilanOrEntretienDate');
        if (dateString) {
            list.push({ type: 'bilanOrEntretienDate', value: dateString });
        }
        setItem(list);
        setValue(date);
    };
    const onClickDateFilter = () => {
        setShowDatePicker(!showDatePicker);
    };

    return (
        <>
            <span className="hastooltip">
                <Button onClick={onClickDateFilter}>
                    <Text style={{ fontWeight: 'bold' }}>{label}</Text>
                </Button>
                <span className={showDatePicker ? 'tooltip-filters open' : 'tooltip-filters'}>
                    <DatePicker value={value} onChange={onChangeDate} />
                </span>
            </span>
        </>
    );
};

const TextFilter = ({ label, loading = false, placeholder, list = [], items = [], setItem, value, setValue, showTextFilter, setShowTextFilter }) => {
    function handleChange(v) {
        const list = clearType(items, 'counselor');
        list.push({ type: 'counselor', value: v });
        setValue(v);
        setItem(list);
    }

    return (
        <>
            <span className="hastooltip">
                <Button onClick={() => setShowTextFilter(!showTextFilter)}>
                    <Text style={{ fontWeight: 'bold' }} onClick={null}>
                        {label}
                    </Text>
                </Button>

                <span className={showTextFilter ? 'tooltip-filters open' : 'tooltip-filters'}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={placeholder}
                        optionFilterProp="children"
                        value={value}
                        onChange={(v) => handleChange(v)}
                        loading={loading}
                    >
                        {list.map((p) => (
                            <Select.Option value={p.uid} key={`counselor${p.uid}`}>
                                {`${p.firstName} ${p.lastName}`}
                            </Select.Option>
                        ))}
                    </Select>
                </span>
            </span>
        </>
    );
};

const AlphabeticalFilter = ({ items, setItem, orderFilter, setOrderFilter }) => {
    function handleChange(type) {
        setOrderFilter(type);
        const list = clearType(items, 'order[deadFullName]');
        list.push({ type: 'order[deadFullName]', value: type });
        setItem(list);
    }

    return (
        <>
            {orderFilter === 'ASC' && (
                <Button onClick={() => handleChange('DESC')} name="ASC">
                    <Text style={{ fontWeight: 'bold' }}>{'A'}</Text>
                    <ArrowRightOutlined />
                    <Text style={{ fontWeight: 'bold' }}>{'Z'}</Text>
                </Button>
            )}
            {orderFilter === 'DESC' && (
                <Button onClick={() => handleChange('ASC')} name="DESC">
                    <Text style={{ fontWeight: 'bold' }}>{'A'}</Text>
                    <ArrowLeftOutlined />
                    <Text style={{ fontWeight: 'bold' }}>{'Z'}</Text>
                </Button>
            )}
        </>
    );
};

const FolderFilters = ({ screen = null }) => {
    const dispatch = useDispatch();
    const [items, setItem] = React.useState([]);
    //counselors
    const [counselors, setCouncelor] = React.useState([]);
    //order
    const [orderFilter, setOrderFilter] = useState('ASC');
    //filter by type
    const [typeFilter, setTypeFilter] = useState([]);
    //filter by state
    const [stateFilter, setStateFilter] = useState([]);
    //filter counselor
    const [value, setValue] = React.useState(null);
    //filter the time line
    const [timeLine, setTimeLine] = React.useState(null);
    //appointment date filter
    const [appointment, setAppointment] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [showDateFilter, setShowDateFilter] = React.useState(false);
    const [showTimeFilter, setShowTimeFilter] = React.useState(false);
    const [showTypeFilter, setShowTypeFilter] = React.useState(false);
    const [showStatusFilter, setShowStatusFilter] = React.useState(false);
    const [showTextFilter, setShowTextFilter] = React.useState(false);
    const elements = [setShowDateFilter, setShowTimeFilter, setShowTypeFilter, setShowTextFilter, setShowStatusFilter];
    React.useEffect(() => {
        if (showDateFilter) onClear(0);
    }, [showDateFilter]);
    React.useEffect(() => {
        if (showTimeFilter) onClear(1);
    }, [showTimeFilter]);
    React.useEffect(() => {
        if (showTypeFilter) onClear(2);
    }, [showTypeFilter]);
    React.useEffect(() => {
        if (showTextFilter) onClear(3);
    }, [showTextFilter]);
    React.useEffect(() => {
        if (showStatusFilter) onClear(4);
    }, [showStatusFilter]);
    function onClear(position) {
        elements.forEach((p, index) => {
            if (position != index) p(false);
        });
    }

    React.useEffect(() => {
        if (counselors.length == 0) {
            setLoading(true);
            userServiceApi
                .getByKeys('evenDeleted=true&exists[folders]=true&itemsPerPage=999')
                .then((response) => {
                    setCouncelor(userServiceApi.getData(response.data));
                })
                .catch((error) => {
                    console.warn('error ', error);
                });
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        const keys = [
            'order[deadFullName]',
            'state[]',
            'type[]',
            'counselor',
            'entretienDatetime[strictly_before]',
            'entretienDatetime[after]',
            'bilanOrEntretienDate',
        ];
        const list = getData(items);
        let data = '';
        let first = true;
        keys.forEach((p) => {
            const elements = list[p];
            if (elements != null && elements != undefined) {
                elements.forEach((item) => {
                    if (!first) {
                        data += `&${item.type}=${item.value}`;
                    } else {
                        data += `${item.type}=${item.value}`;
                        first = false;
                    }
                });
            }
        });
        onLoading(data, dispatch, screen);
    }, [items]);

    return (
        <>
            <Row justify="space-between" className="filters">
                <Col>
                    {screen == 'home' && (
                        <DateFilter
                            label={'Date de rdv'}
                            setShowDatePicker={setShowDateFilter}
                            showDatePicker={showDateFilter}
                            setValue={setAppointment}
                            value={appointment}
                            items={items}
                            setItem={setItem}
                        />
                    )}
                    {screen == 'folder' && (
                        <TextFilter
                            list={counselors}
                            label={'Nom du conseiller'}
                            placeholder={'Nom du conseiller'}
                            items={items}
                            value={value}
                            setValue={setValue}
                            setItem={setItem}
                            loading={loading}
                            setShowTextFilter={setShowTextFilter}
                            showTextFilter={showTextFilter}
                        />
                    )}
                    <TypeFilter
                        types={typeFilter}
                        setType={setTypeFilter}
                        items={items}
                        setItem={setItem}
                        label={'Type de dossier  '}
                        type={'A'}
                        showTypeFilter={showTypeFilter}
                        setShowTypeFilter={setShowTypeFilter}
                    />
                    <StatusFilter
                        label={'Statut de dossier'}
                        items={items}
                        setItem={setItem}
                        states={stateFilter}
                        setState={setStateFilter}
                        setShowStatusFilter={setShowStatusFilter}
                        showStatusFilter={showStatusFilter}
                    />
                    {screen == 'folder' && (
                        <TimeFilter
                            value={timeLine}
                            items={items}
                            setItem={setItem}
                            setValue={setTimeLine}
                            label="Délai de suivi"
                            setShowTime={setShowTimeFilter}
                            showTime={showTimeFilter}
                        />
                    )}
                    <AlphabeticalFilter orderFilter={orderFilter} setOrderFilter={setOrderFilter} items={items} setItem={setItem} />
                </Col>
                <Col>
                    <ResetFilters
                        setItem={setItem}
                        label={'Réinitialiser tous les filtres'}
                        setOrderFilter={setOrderFilter}
                        setStateFilter={setStateFilter}
                        setTypeFilter={setTypeFilter}
                        setValue={setValue}
                        dispatch={dispatch}
                        setTimeFilter={setTimeLine}
                        screen={screen}
                        actions={elements}
                        setAppointment={setAppointment}
                    />
                </Col>
            </Row>
        </>
    );
};

const ResetFilters = ({
    screen,
    dispatch,
    setTimeFilter,
    label,
    setItem,
    setOrderFilter,
    setTypeFilter,
    setStateFilter,
    setValue,
    actions = [],
    setAppointment,
}) => {
    const onResetFilters = () => {
        setItem([]);
        setOrderFilter('ASC');
        setStateFilter([]);
        setTypeFilter([]);
        setValue(null);
        setTimeFilter(null);
        setAppointment(null);
        actions.forEach((p) => p(false));
        if (screen == 'home') {
            dispatch({ type: 'COUNSELOR_FILTER', payload: '' });
            dispatch({ type: 'COUNSELOR_SEARCH', payload: '' });
            dispatch({ type: 'COUNSELOR_PAGE', payload: 1 });
        } else if (screen == 'folder') {
            dispatch({ type: 'FOLDER_FILTER', payload: '' });
            dispatch({ type: 'FOLDER_SEARCH', payload: '' });
            dispatch({ type: 'FOLDER_PAGE', payload: 1 });
        }
    };
    return (
        <Button onClick={onResetFilters}>
            <Text style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{label}</Text>
        </Button>
    );
};

const TimeFilter = ({ items, setItem, value, setValue, label = '', showTime, setShowTime }) => {
    function handleChange(v) {
        let list = items;
        FolderTimes.forEach((p) => {
            list = clearType(list, p.value);
        });
        let date = DateHelper.addMonth(-6).toDate();
        date = DateHelper.getDate(date, 'YYYY-MM-DD HH:mm:ss');
        list.push({ type: v[0], value: date });
        setItem(list);
        setValue(v[0]);
    }

    return (
        <React.Fragment>
            <span className="hastooltip">
                <Button onClick={() => setShowTime(!showTime)}>
                    <Text style={{ fontWeight: 'bold' }} onClick={null}>
                        {label}
                    </Text>
                </Button>
                <span className={showTime ? 'tooltip-filters open' : 'tooltip-filters'}>
                    <Checkbox.Group
                        options={FolderTimes.map((item) => ({ label: item.name, value: item.value }))}
                        onChange={handleChange}
                        value={value}
                    />
                </span>
            </span>
        </React.Fragment>
    );
};

const onLoading = (data, dispatch, screen) => {
    if (screen === 'home') {
        dispatch({ type: 'COUNSELOR_FILTER', payload: data });
    } else if (screen === 'folder') {
        dispatch({ type: 'FOLDER_FILTER', payload: data });
    }
};

export default FolderFilters;
