import React from 'react';
import { UpdateFolderView } from './updateCurrentFolder';
import { CompleteFolderView } from './completeFolder';
import { canEditFolder } from '../advisorTreatment';

function UpdateFolder({ btnType = 1, type = null, folder, user = {} }) {
    if (!canEditFolder(folder)) return null;
    return type == 'draft' ? (
        <CompleteFolderView className="cta medium" folder={folder} type={btnType} />
    ) : (
        <UpdateFolderView user={user} className="cta medium" type={btnType} folder={folder} />
    );
}

export { UpdateFolder };
