import { Col, List, Radio, Row, Skeleton, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom';

import DateHelper from '../../Helpers/DateHelper';
import OrganismFilters from './OrganismFilters';
import HeaderListOrganisms from './Partials/Headers/headerListOrganism';
import HeaderViewOrganism from './Partials/Headers/headerViewOrganism';
import EditOrganismButton from './EditOrganismButton';
import OrganismDataApi from '../../services/OrganismDataApi';
import { getOrganizations, getPF } from './Partials/getOrganism';
import { OrganizationPagination, PfPagination } from './Partials/Pagination';
import { isEmpty } from 'lodash';
import organismAVDCServiceApi from '../../services/organismAVDCServiceApi';
import { canAdministrateReferential } from '../folders/Partials/advisorTreatment';

const ShowContact = ({ contact }) => {
    if (undefined === contact) return null;
    return (
        <>
            <div className="" style={{ fontWeight: 'bold' }}>
                <span>&#8226;</span> {contact.fullName}
            </div>
            <div style={{ marginLeft: 20 }}>
                <div>{contact.email}</div>
                <div>{contact.phone}</div>
            </div>
        </>
    );
};

const OrganismView = () => {
    const currentOrganism = useSelector((state) => state.organism.currentOrganism);
    const [organism, setOrganism] = React.useState(null);
    const { uid, screen } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [contacts, setContacts] = React.useState({ list: [], status: false });
    const [contact, setContact] = React.useState({ value: null, status: false });
    const [refresh, setRefresh] = React.useState(true);
    const { Title } = Typography;
    const dispatch = useDispatch();

    const handleDisplayOrganism = (event, organism) => {
        dispatch({
            type: 'ORGANISM_CURRENT',
            payload: organism,
        });
    };

    async function getCurrentOrganization(uid) {
        let value = {};
        await OrganismDataApi.get(uid)
            .then((response) => {
                value = response.data;
            })
            .catch((error) => {
                console.warn('error get organism', error);
                setLoading(false);
            });
        return value;
    }
    async function getCurrentPf(uid) {
        let value = {};
        await organismAVDCServiceApi
            .get(uid)
            .then((response) => {
                value = response.data;
            })
            .catch((error) => {
                console.warn('error get organism', error);
                setLoading(false);
            });
        return value;
    }

    useEffect(async () => {
        if (uid != null && refresh) {
            let value = {};
            if (screen == 'organization') {
                value = await getCurrentOrganization(uid);
            } else if (screen == 'pf') {
                value = await getCurrentPf(uid);
            }
            handleDisplayOrganism(null, value);
            let data = getPrimaryContact(value);
            setContact({ value: data.response, status: data.status });
            data = getSecondaryContact(value);
            setContacts({ list: data.response, status: data.status });
            setOrganism(value);
            setLoading(false);
            setRefresh(false);
        }
    }, [currentOrganism]);

    function getTown(value = {}) {
        let response = '';
        if (value.postalName != undefined) {
            response = value.postalName;
        } else if (!isEmpty(value.town)) {
            response = value.town.name;
        }
        return response;
    }

    function getEmail(value = {}) {
        let response = '';
        if (!isEmpty(value.email)) {
            response = value.email;
        } else if (!isEmpty(value.emailContact)) {
            response = value.emailContact;
        }
        return response;
    }

    function getPhoneNumber(value = {}) {
        let response = '';
        if (!isEmpty(value.phone)) {
            response = value.phone;
        } else if (isEmpty(value.phone)) {
            response = value.phoneNumber;
        }
        return response;
    }

    function getPrimaryContact(value = {}) {
        let status = false;
        let response = null;
        if (!isEmpty(value.contacts)) {
            response = value.contacts;
            if (!isEmpty(response)) {
                if (response.length > 0) {
                    response = response[0];
                    status = true;
                }
            }
        }
        return { response, status };
    }

    function getSecondaryContact(value = {}) {
        let status = false;
        let response = [];
        if (!isEmpty(value.contacts)) {
            if (value.contacts.length > 1) {
                status = true;
                value.contacts.forEach((p, i) => {
                    if (i > 0) {
                        response.push(p);
                    }
                });
            }
        }
        return { response, status };
    }

    if (organism === undefined || organism === null) <Redirect to="/organisms" />;

    return (
        <React.Fragment>
            <HeaderViewOrganism organism={organism} />
            {!loading && (
                <div className="box">
                    <Row justify="space-between" style={{ borderBottom: '1px solid  #FFEAE4' }} className="container_header_list_annuaire">
                        <Col span={16}>
                            <div style={{ fontWeight: 'bold', color: '#CB5D4B', fontSize: '18px' }}>{organism.name} </div>
                            {!isEmpty(organism.recipient) && <Typography.Text strong>{organism.recipient}</Typography.Text>}
                        </Col>
                        {screen == 'organization' && canAdministrateReferential() && (
                            <Col span={4} offset={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <EditOrganismButton label="Modifier" organism={organism} setRefresh={setRefresh} />
                            </Col>
                        )}
                    </Row>

                    <Row>
                        <Col span={12} style={{ padding: '0px 16px 16px' }}>
                            <div style={{ fontWeight: 'bold' }}>Adresse : </div>
                            <p>{organism.address}</p>
                            {!isEmpty(organism.addressComplement) && <p>{organism.addressComplement}</p>}
                            <Title style={{ marginTop: -10, fontSize: 12, fontWeight: 'normal' }} level={5}>
                                {organism.postalCode} {getTown(organism)}
                            </Title>

                            <div style={{ fontWeight: 'bold' }}>Adresse Email :</div>
                            <p>{getEmail(organism)}</p>

                            <div style={{ fontWeight: 'bold' }}>Numéro de téléphone :</div>
                            <p>{getPhoneNumber(organism)}</p>
                        </Col>
                        <Col span={12} style={{ padding: '0px 16px 16px' }}>
                            {contact.status == true && (
                                <React.Fragment>
                                    <div style={{ fontWeight: 'bold' }}>Contact principal :</div>
                                    <ShowContact contact={contact.value} />
                                </React.Fragment>
                            )}
                            {contacts.status && (
                                <React.Fragment>
                                    <div style={{ fontWeight: 'bold' }}>Contact(s) secondaire(s) :</div>
                                    {contacts.list.map((p, key) => {
                                        return <ShowContact contact={p} key={key} />;
                                    })}
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>
                </div>
            )}
            {loading && <Skeleton active />}
        </React.Fragment>
    );
};

const ListOrganisms = ({ data, screen, loading = true }) => {
    const { Text } = Typography;

    const renderItem = (organism) => {
        return (
            <Link to={`/organism/${screen}/${organism.uid == undefined ? organism.id : organism.uid}/view`}>
                <List.Item key={organism.uid} style={{ borderBottom: '1px solid #eee' }}>
                    <Row align="middle" style={{ width: '100%', height: '100%' }}>
                        <Col span={8}>
                            <div>
                                <Text style={{ fontWeight: 'bold' }}>{organism.name}</Text>
                            </div>
                            <div>{organism.email}</div>
                            <div>{organism.phone}</div>
                        </Col>
                        <Col span={10} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                            {!isEmpty(organism.recipient) && <Typography.Text strong>{organism.recipient}</Typography.Text>}
                            <div>{organism.address}</div>
                            <div>
                                {organism.postalCode} {screen == 'organization' ? organism.postalName : organism.town.name}
                            </div>
                        </Col>
                        {screen == 'organization' && (
                            <Col flex="1">
                                <div>Mise à jour le {DateHelper.getDate(organism.sysUpdatedDate, 'LL')}</div>
                            </Col>
                        )}
                    </Row>
                </List.Item>
            </Link>
        );
    };

    return (
        <React.Fragment>
            <List
                size="large"
                itemLayout="horizontal"
                dataSource={data}
                renderItem={renderItem}
                loading={loading}
                locale={{ emptyText: 'La liste est vide' }}
            />
            {screen == 'organization' && <OrganizationPagination loading={loading} />}
            {screen == 'pf' && <PfPagination loading={loading} />}
        </React.Fragment>
    );
};

function Organisms() {
    const organizations = useSelector((state) => state.organism.organisms);
    const pfs = useSelector((state) => state.organism.pfs);
    const dispatch = useDispatch();
    const currentScreen = useSelector((state) => state.organism.screen);
    const [screen, setScreen] = React.useState(currentScreen);
    const loadingOrg = useSelector((state) => state.organism.loadingOrganization);
    const loadingPF = useSelector((state) => state.organism.loadingPF);

    useEffect(async () => {
        if (organizations.length == 0) {
            const rep = await getOrganizations(1);
            dispatch({
                type: 'ORGANISM_SEARCH',
                payload: rep.list,
            });
            dispatch({
                type: 'ORGANIZATION_TOTAL',
                payload: rep.total,
            });
            dispatch({
                type: 'ORGANIZATION_LOADING',
                payload: false,
            });
            dispatch({
                type: 'PF_LOADING',
                payload: true,
            });
        }
        if (pfs.length == 0) {
            const rep = await getPF(1);
            dispatch({
                type: 'PF_SEARCH',
                payload: rep.list,
            });
            dispatch({
                type: 'PF_TOTAL',
                payload: rep.total,
            });
            dispatch({
                type: 'PF_LOADING',
                payload: false,
            });
        }
    }, []);

    function handleScreen(v) {
        setScreen(v);
        dispatch({
            type: 'ANNUAIRE_SCREEN',
            payload: v,
        });
    }

    return (
        <div style={{ flex: 1 }}>
            <Radio.Group value={screen} style={{ padding: '20px 0' }}>
                <div className="toggle-menu">
                    <Radio.Button
                        className={`btn bg-grey-light ${screen == 'organization' ? 'btn3' : ''}`}
                        style={screen == 'organization' ? { paddingRight: 20 } : { paddingRight: 40 }}
                        onClick={() => handleScreen('organization')}
                        value="organization"
                    >
                        Organismes
                    </Radio.Button>
                    <Radio.Button
                        className={`btn bg-grey-light ${screen == 'pf' ? 'btn3' : ''}`}
                        style={screen == 'pf' ? { marginLeft: -20, paddingLeft: 20 } : { marginLeft: -20, paddingLeft: 40 }}
                        onClick={() => handleScreen('pf')}
                        value="pf"
                    >
                        Pompes funèbres
                    </Radio.Button>
                </div>
            </Radio.Group>
            <div>
                <OrganismFilters showTheme={screen == 'organization'} />
                <div className="box">
                    <HeaderListOrganisms screen={screen} />
                    {screen == 'organization' && <ListOrganisms screen={screen} data={organizations} loading={loadingOrg} />}
                    {screen == 'pf' && <ListOrganisms screen={screen} data={pfs} loading={loadingPF} />}
                </div>
            </div>
        </div>
    );
}

export { Organisms, OrganismView };
