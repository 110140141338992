import http from './avdcConfig';

const source = 'v2/institutions';

class organismAVDCDataApi {
    getAll() {
        return http.get(source);
    }

    get(id) {
        return http.get(`${source}/${id}`);
    }

    getData(data) {
        if (data['hydra:member']) return data['hydra:member'];
        return [];
    }

    getTotalItems(data) {
        return data['hydra:totalItems'];
    }

    getByKeys(queryParam) {
        return http.get(`${source}?${queryParam}`);
    }

    getUri(uid) {
        return http.get(`${uid}`);
    }
}

export default new organismAVDCDataApi();
