import React from 'react';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { FolderButton } from './FolderButton';
import HeaderReturn from '../Headers/headerViewBack';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, List, Progress, Row, Skeleton, Typography, Upload } from 'antd';
import { CloseOutlined, EyeFilled, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { PdfReader } from '../Modal/PdfReader';
import folderBoCategory from '../../../../services/folderBoCategory';
import folderBoFile from '../../../../services/folderBoFile';
import folderBoDocument from '../../../../services/folderBoDocument';
import config from '../../../../config';
import { getFolder } from '../getFolders';
import { canEditFolder } from '../advisorTreatment';

const { Title } = Typography;

const HeadetTitle = ({ label }) => {
    return (
        <Row style={{ borderBottom: '1px solid #FFEAE4', marginBottom: 20 }}>
            <Col flex="1">
                <Title style={{ color: '#cb5d4b' }} level={3}>
                    {label}
                </Title>
            </Col>
        </Row>
    );
};

const Waiting = () => (
    <React.Fragment>
        <Skeleton active />
        <Skeleton active />
    </React.Fragment>
);

function AddDocScreen() {
    const [folder, setFolder] = React.useState({});
    const currentfolder = useSelector((state) => state.folder.currentFolder);
    const [isLoading, setLoading] = React.useState(true);
    const { uid } = useParams();
    const dispatch = useDispatch();
    const hist = useHistory();
    const [categories, setCategory] = React.useState([]);
    const [documents, setDocuments] = React.useState([]);
    const [title, setTitle] = React.useState('');
    const [enabled, setEnabled] = React.useState(true);

    function getTitle(folder) {
        let info = '';
        const gender = folder.people.civility;
        info = `Liste de documents à fournir à `;
        if (gender == 'M') {
            info += `Monsieur ${folder.customerFullName}`;
        } else if (gender == 'Mme') {
            info += `Madame ${folder.customerFullName}`;
        } else {
            info += `${folder.customerFullName}`;
        }
        return info;
    }

    async function getCategories() {
        let list = [];
        await folderBoCategory
            .getByKeys('order[order]=asc')
            .then(async (response) => {
                list = folderBoCategory.getData(response.data);
            })
            .catch(() => (list = []));
        return list;
    }

    async function getFile(uid) {
        let rep = {};
        await folderBoFile
            .getByUrl(uid)
            .then((response) => {
                rep = response.data;
            })
            .catch(() => (rep = {}));
        return rep;
    }

    async function getDocuments(uid, folder) {
        let list = [];
        let query = `folder=${folder}`;
        if (uid != null && uid != undefined) {
            query = `&category=${uid}`;
        }
        await folderBoDocument.getByKeys(query).then(async (response) => {
            list = folderBoDocument.getData(response.data);
            await Promise.all(
                list.map(async (p) => {
                    const file = await getFile(p.file);
                    const { mimeType, originalName, size, uid } = file;
                    p.name = originalName;
                    p.size = size;
                    p.mimeType = mimeType;
                    p.fileUid = uid;
                    //p.file=file;
                }),
            );
        });
        return list;
    }

    React.useEffect(async () => {
        let element = currentfolder;
        if (currentfolder == null || currentfolder.uid == undefined) {
            await getFolder(uid)
                .then((response) => {
                    setFolder(response.value);
                    element = response.value;
                    const info = getTitle(element);
                    setTitle(info);
                    dispatch({ type: 'CURRENT_FOLDER', payload: element });
                })
                .catch((error) => {
                    console.warn('error during api loading', error);
                });
        } else {
            setFolder(currentfolder);
            const info = getTitle(currentfolder);
            setTitle(info);
        }
        if (uid == undefined || uid == 'undefined' || uid == null) {
            hist.goBack();
        } else {
            setLoading(true);
            await getCategories().then(async (data) => {
                let list = await getDocuments(null, element['@id']);
                if (list.length == 0) {
                    list = await getDocuments(null, element['@id']);
                }
                setDocuments(list);
                setCategory(data);
            });
        }
        setLoading(false);
    }, []);

    function handleReturn() {
        hist.goBack();
    }

    const renderItem = (item) => {
        return (
            <DocList
                label={item.name}
                list={documents}
                category={item['@id']}
                folder={folder}
                setList={setDocuments}
                getList={getDocuments}
                setEnabled={setEnabled}
                enabled={enabled}
            />
        );
    };

    return (
        <React.Fragment>
            {folder == undefined && <Redirect to="/folders" />}
            {!isLoading && folder != null ? (
                <React.Fragment>
                    <HeaderReturn handleBackToList={handleReturn} />
                    <div className="box" style={{ padding: 15 }}>
                        <HeadetTitle label={title} />
                        <List dataSource={categories} locale={{ emptyText: 'Erreur de chargment' }} loading={isLoading} renderItem={renderItem} />
                    </div>
                </React.Fragment>
            ) : (
                <Waiting />
            )}
        </React.Fragment>
    );
}

const DocList = ({ list = [], setList, label, category, folder, getList, enabled = true, setEnabled }) => {
    const [currentFile, setCurrentFile] = React.useState(null);
    const [currentFileType, setCurrentFileType] = React.useState(null);
    const [visible, setVisible] = React.useState(false);
    const [loadUrl, setLoadUrl] = React.useState(true);

    async function handleDelete(item) {
        const folderId = folder['@id'];
        const items = list;
        setEnabled(false);
        items.forEach((p) => {
            if (p.fileUid == item.fileUid) {
                p.loading = true;
            }
        });
        setList(items.filter((p) => p.fileUid != item.fileUid));
        setTimeout(() => {
            setList(items);
        }, 1);
        await folderBoDocument
            .delete(item.uid)
            .then(async () => {
                await folderBoFile
                    .delete(item.fileUid)
                    .then(async () => {
                        const elements = await getList(null, folderId);
                        setList(elements);
                        setEnabled(true);
                    })
                    .catch(async () => {
                        const elements = await getList(null, folderId);
                        setList(elements);
                        setEnabled(true);
                    });
            })
            .catch(async () => {
                const elements = await getList(null, folderId);
                setList(elements);
                setEnabled(true);
            });
    }

    async function handleRead(item) {
        setLoadUrl(true);
        setVisible(true);
        setCurrentFile(null);
        setCurrentFileType(item.mimeType);
        const uid = item.fileUid;
        let url = null;
        await folderBoFile
            .getUrl(uid)
            .then((response) => {
                url = config.URL_API + response.data.url;
            })
            .catch(() => {});
        if (url != null) {
            setCurrentFile(url);
            setLoadUrl(false);
        } else {
            setVisible(false);
        }
    }

    async function handleChange(info, category, folder) {
        setEnabled(false);
        const items = info.fileList;
        const item = items[items.length - 1];
        item.category = category;
        item.loading = true;
        setList(items);
        if (item.status == undefined && item.percent == 0) {
            const data = new FormData();
            data.append('file', item.originFileObj);
            await folderBoFile
                .create(data)
                .then(async (response) => {
                    const fileId = response.data['@id'];
                    const dataBo = { folder, category, file: fileId };
                    await folderBoDocument
                        .create(dataBo)
                        .then(async () => {
                            const elements = await getList(null, folder);
                            setList(elements);
                            setEnabled(true);
                        })
                        .catch(() => setEnabled(true));
                })
                .catch(async () => {
                    const elements = await getList(null, folder);
                    setList(elements);
                    setEnabled(true);
                });
        }
    }

    const renderFiles = (item) => {
        return (
            <List.Item>
                <Row align="middle">
                    {item.percent > 0 && (
                        <Col>
                            <Progress
                                status="active"
                                type="circle"
                                width={40}
                                percent={item.percent.toFixed(0)}
                                style={{ paddingLeft: 20, paddingRight: 20, position: 'relative' }}
                            />
                        </Col>
                    )}
                    {item.loading === true && (
                        <Col>
                            <LoadingOutlined style={{ color: '#cb5d4b', padding: 5 }} />
                        </Col>
                    )}
                    <Col flex="1">
                        <div style={{ maxWidth: '120px' }}>
                            <Typography.Paragraph style={{ paddingTop: 12, width: '100%' }} ellipsis={{ rows: 1 }}>
                                {item.name}
                            </Typography.Paragraph>
                        </div>
                    </Col>
                    <Col>
                        {canEditFolder(folder) && (
                            <Button type="text" style={{ padding: 5 }} onClick={() => handleDelete(item)} disabled={item.percent > 0 || !enabled}>
                                <CloseOutlined style={{ fontSize: 15, fontWeight: 'bold' }} />
                            </Button>
                        )}
                    </Col>
                    <Col>
                        <Button style={{ padding: 5 }} onClick={() => handleRead(item)} type="text" disabled={item.percent > 0 || !enabled}>
                            <EyeFilled style={{ fontSize: 25, color: '#a49f99' }} />
                        </Button>
                    </Col>
                </Row>
            </List.Item>
        );
    };

    return (
        <React.Fragment>
            <Row style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <Col flex="1">
                    <Title level={5}>{label}</Title>
                </Col>
                <Col>
                    {canEditFolder(folder) && (
                        <Upload
                            style={{ marginTop: 50 }}
                            beforeUpload={() => false}
                            onChange={(info) => handleChange(info, category, folder['@id'])}
                            showUploadList={false}
                            defaultFileList={list}
                            fileList={list}
                            accept="application/pdf"
                        >
                            <Button shape="circle" disabled={!enabled} style={{ background: '#a49f99', position: 'absolute', right: 0, top: -5 }}>
                                <PlusOutlined style={{ fontSize: 20, color: '#fff' }} />
                            </Button>
                        </Upload>
                    )}
                </Col>
            </Row>
            {list.filter((p) => p.category == category).length > 0 && (
                <List
                    dataSource={list.filter((p) => p.category == category)}
                    renderItem={renderFiles}
                    key={`${list.filter((p) => p.loading == true).length}`}
                    grid={{ gutter: 16, column: 2 }}
                    style={{ marginRight: 60, marginLeft: 20 }}
                />
            )}
            <PdfReader
                handleClose={() => {
                    setVisible(false);
                    setCurrentFile(null);
                }}
                load={loadUrl}
                url={currentFile}
                visible={visible}
                type={currentFileType}
            />
        </React.Fragment>
    );
};

function FolderAddDocs({ folder }) {
    console.log('FOLDER', folder);
    return (
        <React.Fragment>
            <Link to={`/docs/${folder.uid}/create`}>
                <FolderButton title="Ajouter les documents" type={1} />
            </Link>
        </React.Fragment>
    );
}
export { AddDocScreen, FolderAddDocs };
