import { Pagination } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getFolderList } from './getFolders';

function HomeFolderPagination() {
    const page = useSelector((state) => state.folder.pageHome);
    const total = useSelector((state) => state.folder.totalHome);
    const filter = useSelector((state) => state.folder.filterHome);
    const search = useSelector((state) => state.folder.searchHome);
    const loading = useSelector((state) => state.folder.loadingCounselor);
    const counselor = useSelector((state) => state.user.id);
    const dispatch = useDispatch();
    const [init, setInit] = React.useState(true);

    React.useEffect(async () => {
        if (init) {
            setInit(false);
            handleChange(1);
            if (filter) {
                // will be called again with empty filter
                return;
            }
        }
        const doDispatch = (response) => {
            dispatch({ type: 'COUNSELOR_TOTAL', payload: response.total });
            dispatch({ type: 'COUNSELOR_FOLDER', payload: response.data });
        };
        if (!counselor) {
            doDispatch({ data: [], total: 0 });
            return;
        }
        let query = `page=${page}&counselor=${counselor}&itemsPerPage=10`;
        if (!isEmpty(filter)) {
            query += `&${filter}`;
        }
        if (!isEmpty(search)) {
            query += `&${search}`;
        }
        dispatch({ type: 'COUNSELOR_LOADING', payload: true });
        await getFolderList(query).then((response) => {
            doDispatch(response);
        });
        dispatch({ type: 'COUNSELOR_LOADING', payload: false });
    }, [page, filter, search, counselor]);

    function handleChange(v) {
        dispatch({ type: 'COUNSELOR_PAGE', payload: v });
    }
    return (
        <React.Fragment>
            {total > 10 && (
                <Pagination
                    current={page}
                    defaultCurrent={1}
                    total={total}
                    onChange={(v) => handleChange(v)}
                    showSizeChanger={false}
                    pageSize={10}
                    className="pagination"
                    showLessItems={false}
                    disabled={loading}
                />
            )}
        </React.Fragment>
    );
}

function FolderPagination() {
    const page = useSelector((state) => state.folder.pageFolder);
    const total = useSelector((state) => state.folder.totalFolder);
    const filter = useSelector((state) => state.folder.filterFolder);
    const search = useSelector((state) => state.folder.searchFolder);
    const loading = useSelector((state) => state.folder.loadingFolder);
    const dispatch = useDispatch();
    const [init, setInit] = React.useState(true);

    React.useEffect(async () => {
        if (init) {
            setInit(false);
            handleChange(1);
            if (filter) {
                // will be called again with empty filter
                return;
            }
        }
        let query = `page=${page}&itemsPerPage=10`;
        if (!isEmpty(filter)) {
            query += `&${filter}`;
        }
        if (!isEmpty(search)) {
            query += `&${search}`;
        }
        dispatch({ type: 'FOLDER_LOADING', payload: true });
        await getFolderList(query).then((response) => {
            dispatch({ type: 'FOLDER_TOTAL', payload: response.total });
            dispatch({ type: 'SEARCH_FOLDER', payload: response.data });
        });
        dispatch({ type: 'FOLDER_LOADING', payload: false });
    }, [page, filter, search]);

    function handleChange(v) {
        dispatch({ type: 'FOLDER_PAGE', payload: v });
    }
    return (
        <React.Fragment>
            {total > 10 && (
                <Pagination
                    current={page}
                    defaultCurrent={1}
                    total={total}
                    onChange={(v) => handleChange(v)}
                    showSizeChanger={false}
                    pageSize={10}
                    className="pagination"
                    showLessItems={false}
                    disabled={loading}
                />
            )}
        </React.Fragment>
    );
}

export { FolderPagination, HomeFolderPagination };
