import { Row, notification } from 'antd';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import OrganismDataApi from '../../services/OrganismDataApi';
import AddContact from '../contacts/addContact';
import ContactForm from '../contacts/contact';
import FormValidationRules from './FormValidationRules';
import OrganismHelper from './../../Helpers/OrganismHelper';
import CTAForm from './Partials/CTAForm';
import { isEmpty } from 'lodash';
import { CreateForm } from './Partials/createForm';

const EditOrganismForm = ({ organismItem, step, onStepChange, HandleCancel, setRefresh, themes = [] }) => {
    const [organism, setOrganism] = useState(organismItem);
    const [contacts, setContacts] = useState(organism.contacts);
    const dispatch = useDispatch();

    const handleChangeStep0 = async () => {
        if (step == 0) {
            const items = formik.values.organismContacts;
            if (OrganismHelper.isValid(formik, ['organismName', 'organismTheme', 'organismEmail', 'organismTelephone'])) {
                onStepChange(1);
                if (items.length === 0) {
                    const contact = { fullName: '', email: '', phone: '' };
                    items.push(contact);
                    setContacts(items);
                }
            } else {
                notification.open({
                    message: 'Veillez à remplir tous les champs obligatoires',
                    type: 'error',
                });
            }
        }
    };
    const handleValidate = () => {
        if (!formik.isValid) {
            notification.error({
                message: 'Veillez à remplir tous les champs obligatoires',
            });
            return;
        } else {
            formik.handleSubmit();
            setRefresh(true);
            HandleCancel();
        }
    };
    const formik = useFormik({
        initialValues: OrganismHelper.getObjectFromOrganism(organism),
        // enableReinitialize: true,
        validationSchema: FormValidationRules.editFormValidatioNRule(),
        onSubmit: (values) => {
            console.log('vam', organismItem.uid, setOrganism);
            let organization = OrganismHelper.getOrganismObjectProperties(values);
            OrganismDataApi.update(organismItem.uid, organization)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: 'ORGANISM_CURRENT',
                            payload: response.data,
                        });
                        OrganismDataApi.getAll()
                            .then((response) => {
                                dispatch({
                                    type: 'ORGANISM_SEARCH',
                                    payload: OrganismDataApi.getData(response.data),
                                });
                            })
                            .catch((error) => {
                                console.warn('error', error);
                            });
                        onStepChange(0);
                    }
                })
                .catch((error) => console.warn('error', error));
        },
    });

    const handleAddContactClick = () => {
        const items = formik.values.organismContacts;
        let item = null;
        if (items.length > 0) {
            item = items[items.length - 1];
        }
        if (item != null) {
            if (!isEmpty(item.fullName)) {
                const contact = { fullName: '', email: '', phone: '' };
                items.push(contact);
                setContacts(items);
                onStepChange(-1);
                setTimeout(() => {
                    onStepChange(1);
                }, 10);
            } else {
                notification.warning({
                    message: "Le dernier contact n'a pas de nom",
                });
            }
        }
    };

    React.useEffect(() => {
        const values = formik.values;
        formik.values = { ...values, organismName: values.organismName };
    }, [contacts]);

    return (
        <form className="form-popup" onSubmit={formik.handleSubmit}>
            {step === 0 && (
                <>
                    <CreateForm formik={formik} themes={themes} />
                </>
            )}
            {step === 1 && (
                <>
                    {contacts.map((contact, key) => {
                        return (
                            <ContactForm
                                contact={{ ...contact, id: key }}
                                key={key}
                                values={formik.values.organismContacts[key]}
                                onChange={formik.handleChange}
                                formik={formik}
                            />
                        );
                    })}
                    <AddContact handleAddContact={handleAddContactClick} />
                </>
            )}
            <Row justify="space-around" style={{ marginTop: 20 }}>
                <CTAForm handleValidate={handleValidate} handleClick={handleChangeStep0} step={step} onStepChange={onStepChange} />
            </Row>
        </form>
    );
};

export default EditOrganismForm;
