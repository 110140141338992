import http from './apiConfig';

const folderApiSource = 'folders';

class FolderDataApi {
    getAll() {
        return http.get(folderApiSource);
    }

    get(id) {
        return http.get(`${folderApiSource}/${id}`);
    }

    update(id, data) {
        return http.put(`${folderApiSource}/${id}`, data);
    }

    findByTitle(title) {
        return http.get(`${folderApiSource}?title=${title}`);
    }

    searchFolder(queryParam) {
        return http.get(folderApiSource, {
            params: {
                search: queryParam,
            },
        });
    }

    getData(data) {
        if (data['hydra:member']) return data['hydra:member'];
        return [];
    }

    getTotal(data) {
        if (data['hydra:totalItems']) return data['hydra:totalItems'];
        return 0;
    }

    getByKeys(queryParam) {
        return http.get(`${folderApiSource}?${queryParam}`);
    }
}

export default new FolderDataApi();
