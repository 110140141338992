import { Col, Modal, Row, Result } from 'antd';
import React from 'react';

function ValidationModal({ visible, handleClik, message, loading }) {
    return (
        <>
            {visible && (
                <Modal
                    visible={visible}
                    closeIcon="Fermer"
                    wrapClassName="modal success"
                    onOk={handleClik}
                    onCancel={handleClik}
                    cancelButtonProps={{ hidden: true }}
                    okText="Valider"
                    confirmLoading={loading}
                    okButtonProps={{
                        style: {
                            background: '#cb5d4b',
                            borderColor: '#cb5d4b',
                            borderRadius: 20,
                            height: 40,
                            marginTop: 21,
                            fontSize: 16,
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 'bold',
                        },
                    }}
                >
                    <Row align="middle" justify="center" style={{ padding: 20 }}>
                        <Col style={{ textAlign: 'center' }}>
                            <Result status="success" title={message} />
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
}

export { ValidationModal };
