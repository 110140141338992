import http from './apiConfig';

const folderApiSource = 'folder_events';

class FolderEventsDataApi {
    getAll() {
        return http.get(folderApiSource);
    }

    get(id) {
        return http.get(`${folderApiSource}/${id}`);
    }

    getData(data) {
        if (data['hydra:member']) return data['hydra:member'];
        return [];
    }

    getByKeys(queryParam) {
        return http.get(`${folderApiSource}?${queryParam}`);
    }
}

export default new FolderEventsDataApi();
