const ORGANISM_ADD = 'ORGANISM_ADD';
const ORGANISM_CURRENT = 'ORGANISM_CURRENT';
const ORGANISM_SEARCH = 'ORGANISM_SEARCH';
const PF_SEARCH = 'PF_SEARCH';
const PF_CURRENT = 'PF_CURRENT';
const PF_PAGE = 'PF_PAGE';
const PF_TOTAL = 'PF_TOTAL';
const PF_FILTER = 'PF_FILTER';
const ORGANIZATION_PAGE = 'ORGANIZATION_PAGE';
const ORGANIZATION_TOTAL = 'ORGANIZATION_TOTAL';
const ORGANIZATION_FILTER = 'ORGANIZATION_FILTER';
const ORGANIZATION_LOADING = 'ORGANIZATION_LOADING';
const PF_LOADING = 'PF_LOADING';
const SCREEN = 'ANNUAIRE_SCREEN';

const initialState = {
    organisms: [],
    currentOrganism: null,
    newOrganism: null,
    pageOrganization: 1,
    totalOrganization: 0,
    filterOrganization: '',
    pfs: [],
    currentPF: null,
    pagePF: 1,
    totalPF: 0,
    filterPF: '',
    loadingOrganization: true,
    loadingPF: true,
    screen: 'organization',
};

function reducerOrganism(state = initialState, action) {
    switch (action.type) {
        case ORGANISM_ADD:
            return { ...state, newOrganism: action.payload };
        case ORGANISM_CURRENT:
            return { ...state, currentOrganism: action.payload };
        case ORGANISM_SEARCH:
            return { ...state, organisms: action.payload };
        case PF_SEARCH:
            return { ...state, pfs: action.payload };
        case PF_CURRENT:
            return { ...state, currentPF: action.payload };
        case ORGANIZATION_PAGE:
            return { ...state, pageOrganization: action.payload };
        case ORGANIZATION_TOTAL:
            return { ...state, totalOrganization: action.payload };
        case ORGANIZATION_FILTER:
            return { ...state, filterOrganization: action.payload };
        case PF_PAGE:
            return { ...state, pagePF: action.payload };
        case PF_TOTAL:
            return { ...state, totalPF: action.payload };
        case PF_FILTER:
            return { ...state, filterPF: action.payload };
        case ORGANIZATION_LOADING:
            return { ...state, loadingOrganization: action.payload };
        case PF_LOADING:
            return { ...state, loadingPF: action.payload };
        case SCREEN:
            return { ...state, screen: action.payload };
        default:
            return state;
    }
}

export default reducerOrganism;
