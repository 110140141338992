import http from './avdcConfig';

const deadApiSource = 'v2/deads';

class DeadAVDCDataApi {
    getAll() {
        return http.get(deadApiSource);
    }

    get(id) {
        return http.get(`${deadApiSource}/${id}`);
    }

    getData(data) {
        if (data['hydra:member']) return data['hydra:member'];
        return [];
    }

    getByKeys(queryParam) {
        return http.get(`${deadApiSource}?${queryParam}`);
    }
}

export default new DeadAVDCDataApi();
