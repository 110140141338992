// Layout and UI
import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import ManagementFilters from './ManagementFilters';
import StatsBlock from '../stats/StatsBlock';
import { statsLabels } from '../stats/StatsLabels';
import { useSelector } from 'react-redux';
// Statistiques KPI

const Management = () => {
    const [blockStats, setBlockStats] = useState([]);
    const filters = useSelector((state) => state.user.filters);
    const qTotalBilan = 'exists[bilanDatetime]=true';

    useEffect(() => {
        //BILAN
        const totalSheetsDone = {
            label: statsLabels.balance_sheet.labels.done,
            params: {
                q1: 'type=bilan&state=completed',
                q2: qTotalBilan + '&type=entretien',
            },
            sizeCell: 8,
        };
        const totalBalanceSheetTransformed = {
            label: statsLabels.balance_sheet.labels.transformed,
            params: {
                q1: qTotalBilan + '&type=entretien&state=completed',
            },
            sizeCell: 8,
        };
        const totalBalanceSheetNotTransformed = {
            label: statsLabels.balance_sheet.labels.not_transformed,
            params: {
                q1: 'type=bilan&state=completed&customerState=not_interested',
            },
            sizeCell: 8,
            warning: true,
        };
        //TAUX - STAND-BY
        // const satisfactionRateData  = {
        //     label: statsLabels.rate.labels.satisfaction,
        //     params: '',
        //     // number : satisfactionRate(resultSearchFolder),
        //     sizeCell: 12
        // }
        const transformationRateData = {
            label: statsLabels.rate.labels.transform_rate,
            params: {
                q1: qTotalBilan + '&type=entretien&state=completed',
                q2: qTotalBilan,
                rate: true,
            },
            sizeCell: 24,
        };

        //ENTRETIEN
        const meetingDoneData = {
            label: statsLabels.meeting.labels.done,
            params: {
                q1: 'type=entretien&state=completed',
            },
            sizeCell: 12,
        };
        const meetingCanceledData = {
            label: statsLabels.meeting.labels.canceled,
            params: {
                q1: 'type=entretien&state=canceled',
            },
            sizeCell: 12,
            warning: true,
        };
        const meetingDoneDirectData = {
            label: statsLabels.meeting.labels.done_direct,
            params: {
                q1: meetingDoneData.params.q1 + '&exists[bilanDatetime]=false',
            },
            sizeCell: 12,
        };
        const meetingDoneOnlineData = {
            label: statsLabels.meeting.labels.done_online,
            params: {
                q1: meetingDoneData.params.q1 + '&direct=true&brandCode=serenicare',
            },
            sizeCell: 12,
        };
        // //DOSSIERS - STAND-BY V1
        // const totalExpressFolders = {
        //     label : statsLabels.folders.labels.express,
        //     number : expressFolders(resultSearchFolder),
        //     sizeCell: 8
        // }
        // const totalClassicFolders = {
        //     label : statsLabels.folders.labels.classic,
        //     number : classicFolders(resultSearchFolder),
        //     sizeCell: 8
        // }
        const totalReOpenFolders = {
            label: statsLabels.folders.labels.reopened,
            params: {
                q1: 'type=entretien&state=reopened',
            },
            sizeCell: 24,
        };

        const statsList = [
            {
                label: statsLabels.balance_sheet.title,
                size: 16,
                stats: [totalSheetsDone, totalBalanceSheetTransformed, totalBalanceSheetNotTransformed],
            },
            {
                label: statsLabels.rate.title,
                size: 8,
                stats: [transformationRateData],
            },
            {
                label: statsLabels.meeting.title,
                size: 16,
                stats: [meetingDoneData, meetingCanceledData, meetingDoneDirectData, meetingDoneOnlineData],
            },
            {
                label: statsLabels.folders.title,
                size: 8,
                stats: [totalReOpenFolders],
            },
        ];
        statsList.forEach((o) => {
            o.stats.forEach((s) => {
                s.params.q1 += filters;
                if (s.params.q2 !== undefined) {
                    s.params.q2 += filters;
                }
            });
        });
        setBlockStats(statsList);
    }, [filters]);

    return (
        <div>
            <ManagementFilters />
            <Row gutter={16} style={{ marginTop: -32 }}>
                {blockStats.map((block, key) => {
                    return <StatsBlock key={key} size={block.size} statsInfos={block.stats} title={block.label} />;
                })}
            </Row>
        </div>
    );
};

export { Management };
