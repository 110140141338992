import { message, Modal, notification } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { FolderButton } from '../Button/FolderButton';
import { ActionButton, filterValue, Header, StateLine, getList, isEmpty } from '../../../Partials/tools';
import DeadComplementDataApi from '../../../../services/DeadServiceApi';
import deadAVDCServiceApi from '../../../../services/deadAVDCServiceApi';
import ContactDataApi from '../../../../services/contactServiceApi';
import folderServiceApi from '../../../../services/folderServiceApi';
import { UpdateAppointment } from './completeFolder/CompleteAppointment';
import { CompleteDecease } from './completeFolder/CompleteDecease';
import { CompleteContact } from './completeFolder/CompleteContact';
import { SearchDecease } from './completeFolder/SearchDecease';
import { SearchContact } from './completeFolder/SearchContact';
import { useFormik } from 'formik';
import OrganismDataApi from '../../../../services/OrganismDataApi';
import { ValidationModal } from './validation';
import { contactValuesCompletion, deceaseValuesCompletion, folderValuesCompletion } from '../folderValues';
import DateHelper from '../../../../Helpers/DateHelper';
import FormValidationRules from '../../../organisms/FormValidationRules';
import organismAVDCServiceApi from '../../../../services/organismAVDCServiceApi';
import refServiceApi from '../../../../services/refServiceApi';
import { getAdministrativeDivision, getFolder } from '../getFolders';
import { canEditFolder } from '../advisorTreatment';

async function dataProcessing({ formikContact, formikDecease, formikAppointment, folder, setVisible, setViewValidation }) {
    let rep = false;
    const decease = formikDecease.values;
    const contact = formikContact.values;
    const appointment = formikAppointment.values;
    const data = folderValuesCompletion({ contact, appointment, currentFolder: folder, decease });
    await folderServiceApi
        .update(folder.uid, data)
        .then(async () => {
            rep = true;
            setViewValidation(true);
            setVisible(false);
        })
        .catch(() => {
            rep = false;
        });
    return rep;
}

function serverError() {
    message.warning('Erreur au niveau du serveur');
}

async function contactTreatment({ formik }) {
    let state = false;
    const data = contactValuesCompletion({ contact: formik });
    await ContactDataApi.create(data)
        .then((response) => {
            state = true;
            formik.values.uid = response.data['@id'];
        })
        .catch((reason) => {
            state = false;
            const response = reason.response;
            if (response.status == 422 || response.status == 400) {
                const description = response.data['hydra:description'];
                notification.warning({
                    message: description,
                });
            }
        });
    return state;
}
async function AppointmenetTreatment({ formik }) {
    console.log('formik', formik);
}
async function DeceaseTreatment({ formik }) {
    if (formik.values.uid != null) {
        //test the
    } else {
        const data = deceaseValuesCompletion({ decease: formik });
        await DeadComplementDataApi.create(data)
            .then((response) => {
                formik.values.uid = response.data['@id'];
            })
            .catch(function (reason) {
                const response = reason.response;
                if (response.status == 400 || response.status == 422) {
                    notification.warning({
                        message: response.data['hydra:description'],
                    });
                }
            });
    }
}

const CompleteFolderView = ({ type, folder }) => {
    const [visible, setVisible] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [enabled, setEnabled] = React.useState(false);
    const [title, setTitle] = React.useState('Suivant');
    const [organizations, SetOrganization] = React.useState([]);
    const [loadBtn, setLoadBtn] = React.useState(false);
    const [viewValidation, setViewValidation] = React.useState(false);
    const dispatch = useDispatch();
    let date = folder.typeDatetime;
    let time = null;
    if (date != null) {
        [date, time] = DateHelper.getDate(date, 'YYYY-MM-DD HH:mm').split(' ');
    }
    let user = folder.user;
    let counselor = '';
    if (!isEmpty(user)) {
        counselor = user.lastName + ' ' + user.firstName;
    }
    const initialValuesAppoint = {
        counselorName: filterValue(counselor),
        folderType: filterValue(folder.type),
        appointDate: date,
        time: time,
        trackingNumber: filterValue(folder.trackingNumber),
    };

    const initDeceases = {
        civility: folder.dead_complements.civility,
        firstName: filterValue(folder.deadFullName),
        lastName: filterValue(folder.deadFullName),
        name: filterValue(folder.deadFullName),
        maritalStatus: filterValue(folder.deadMaritalStatus),
        address: '',
        postalCode: '',
        town: '',
        socialNumber: filterValue(folder.dead_complements.socialNumber),
        subName: '',
        deathDate: filterValue(folder.deadDeathDate),
        deathPlace: null,
        funeralDate: filterValue(folder.deadFuneralDate),
        birthDate: filterValue(folder.dead_complements.birthDate),
        birthPlace: filterValue(folder.dead_complements.birthPlace),
        fullName: folder.deadFullName,
        uid: null,
        socialStatus: folder.deadSocialSituation,
        firstNameBirth: null,
        nameBirth: null,
        id: null,
        avdcId: null,
        complement: null,
    };
    const validateDeceases = (values) => {
        const errors = {};
        if (isEmpty(values.deathDate)) errors.deathDate = 'La date de decès est requise';
        if (isEmpty(values.firstName)) errors.firstName = 'Le prénom est requis';
        if (isEmpty(values.lastName)) errors.lastName = 'Le nom est requis';
        return errors;
    };

    const address_people = folder.people.address;
    const initialValuesContact = {
        civility: null,
        uid: null,
        firstName: filterValue(folder.customerFirstName),
        lastName: filterValue(folder.customerLastName),
        relationship: filterValue(folder.familyRelationship),
        address: !isEmpty(address_people) ? address_people.street : '',
        postalCode: !isEmpty(address_people) ? address_people.postalUid : null,
        phone: filterValue(folder.customerPhone),
        town: null,
        socialNumber: filterValue(folder.people.socialNumber),
        payment: filterValue(folder.paymentMode),
        email: filterValue(folder.customerEmail),
        country: null,
        organization: filterValue(folder.pf),
        counselorName: filterValue(folder.pfCounselorName),
        fullName: filterValue(folder.customerFullName),
        name: filterValue(folder.customerFullName),
    };

    const formikAppoint = useFormik({
        initialValues: initialValuesAppoint,
    });

    const formikDecease = useFormik({
        initialValues: initDeceases,
        validate: validateDeceases,
    });

    const validateContact = (values) => {
        const errors = {};
        if (values.civility == null) errors.civility = 'Choisir une civilité';
        if (isEmpty(values.firstName)) errors.firstName = 'Le prénom est requis';
        if (isEmpty(values.lastName)) errors.lastName = 'Le nom est requis';
        if (isEmpty(values.email)) {
            errors.email = "l'E-mail est requis";
        } else if (!FormValidationRules.isEmail(values.email)) {
            errors.email = 'E-mail incorrect';
        }
        if (isEmpty(values.phone)) errors.phone = 'Le téléphone est requis';
        else if (!FormValidationRules.isPhoneNumber(values.phone)) errors.phone = "Le téléphone n'est pas correct";
        return errors;
    };

    const formikContact = useFormik({
        initialValues: initialValuesContact,
        validate: validateContact,
    });

    const handleShow = () => {
        if (canEditFolder(folder)) {
            setVisible(true);
        } else {
            notification.open({
                message: 'Vous ne pouvez pas modifier ce dossier',
                type: 'warning',
            });
        }
    };

    const handleUpdate = async () => {
        setIsLoading(true);
        const response = await getFolder(folder.uid);
        dispatch({ type: 'CURRENT_FOLDER', payload: response.value });
        notification.open({
            message: 'Vous avez complété le dossier',
            type: 'success',
        });
        setViewValidation(false);
    };
    const handleClose = () => {
        formikAppoint.resetForm();
        formikContact.resetForm();
        formikDecease.resetForm();
        setVisible(false);
        setStep(0);
        setLoadBtn(false);
    };

    const [step, setStep] = React.useState(0);

    const checkDeceaseAVDC = async (formik) => {
        let state = false;
        const fullName = formik.values.name;
        let data = '';
        data = `fullName=${fullName}`;
        await formik.handleSubmit();
        const value = formik.values;
        formik.values = { ...value, uid: null, avdcId: null };
        await formik.setValues({ ...value, uid: null, avdcId: null });
        await deadAVDCServiceApi
            .getByKeys(data)
            .then(async (response) => {
                const responses = deadAVDCServiceApi.getData(response.data);
                let rep = null;
                if (responses.length > 0) rep = responses[0];
                if (rep != null) {
                    const {
                        divisionCode,
                        firstName,
                        lastName,
                        birthDate,
                        birthPlace,
                        civility,
                        deathPlace,
                        firstNameBirth,
                        nameBirth,
                        postalCode,
                        id,
                    } = rep;
                    let town = null;
                    const division = await getAdministrativeDivision(divisionCode, postalCode);
                    if (!isEmpty(division.division)) {
                        town = division.division.name;
                    }
                    let subName = !isEmpty(nameBirth) ? nameBirth : '';
                    subName += !isEmpty(firstNameBirth) ? firstNameBirth : '';
                    const item = {
                        ...value,
                        firstName,
                        lastName,
                        firstNameBirth,
                        birthPlace,
                        birthDate,
                        nameBirth,
                        town,
                        civility: civility == 'X' ? null : civility,
                        name: rep.fullName,
                        deathPlace,
                        postalCode,
                        subName,
                        id,
                        uid: null,
                        fullName: rep.fullName,
                    };
                    await formik.setValues(item);
                    formik.values = item;
                    state = await checkDecease(formik, id);
                } else {
                    await formik.setValues({ ...value, avdcId: null, uid: null });
                    formik.values = { ...value, avdcId: null, uid: null };
                }
            })
            .catch(() => {
                state = false;
                serverError();
            });
        return state;
    };

    const checkDecease = async (formik, id) => {
        let rep = false;
        if (id != null) {
            await DeadComplementDataApi.getByKeys(`avdcId=${id}`)
                .then(async (response) => {
                    const data = DeadComplementDataApi.getData(response.data);
                    if (data.length > 0) {
                        const v = data[0]['@id'];
                        const { avdcId } = data[0];
                        rep = await deceaseUsedInFolder(v);
                        const item = { ...formik.values, id, avdcId, uid: v };
                        await formik.setValues(item);
                        formik.values = item;
                    } else {
                        const item = { ...formik.values, id, avdcId: id };
                        formik.setValues(item);
                        formik.values = item;
                    }
                })
                .catch(() => {
                    rep = false;
                    serverError();
                });
        }
        return rep;
    };

    const deceaseUsedInFolder = async (uid) => {
        let rep = false;
        await folderServiceApi
            .getByKeys(`dead=${uid}`)
            .then((response) => {
                const responses = folderServiceApi.getData(response.data);
                if (responses.length > 0) rep = true;
            })
            .catch(() => {
                rep = false;
            });
        return rep;
    };

    const checkContact = async (formik) => {
        let state = false;
        //const fullName = formik.values.fullName;
        const emailValue = formik.values.email;
        formik.values = { ...formik.values, uid: null };

        let data = '';
        //data = `fullName=${fullName}`;
        if (!isEmpty(emailValue)) {
            data += `email=${emailValue}`;
        }

        await ContactDataApi.getByKeys(data)
            .then((response) => {
                const responses = ContactDataApi.getData(response.data);
                state = true;
                if (responses.length > 0) {
                    const rep = responses[0];
                    const uid = rep['@id'];
                    const { civility, lastName, firstName, phone, address, socialNumber, email } = rep;
                    const item = {
                        ...formik.values,
                        address: !isEmpty(address) ? address.street : null,
                        civility,
                        lastName,
                        firstName,
                        phone,
                        socialNumber,
                        uid,
                        email,
                        fullName: rep.fullName,
                        name: rep.fullName,
                    };
                    formik.values = item;
                    formik.setValues(item);
                }
            })
            .catch(() => {
                state = false;
            });
        return state;
    };
    async function handleForward(folder) {
        if (step == 0) {
            setLoadBtn(true);
            const state = await checkDeceaseAVDC(formikDecease);
            if (state) {
                notification.open({
                    message: 'Le défunt est déjà utilisé dans un autre dossier',
                    type: 'warning',
                });
                setLoadBtn(false);
                setEnabled(true);
                return;
            }
            if (formikDecease.values.avdcId == null) {
                if (formikDecease.values.uid == null) {
                    notification.open({
                        message: 'Le défunt n’est pas trouvé dans AVDC. Pour compléter ce dossier, vous devez le créer.',
                        type: 'warning',
                    });
                    setLoadBtn(false);
                    setEnabled(true);
                    return;
                }
            }
            setLoadBtn(false);
            setEnabled(true);
        } else if (step == 1) {
            await formikDecease.handleSubmit();
            if (!formikDecease.isValid) {
                return;
            }
            if (formikDecease.values.uid == null) {
                setLoadBtn(true);
                //create the decease in serenicare by the avdcid
                await DeceaseTreatment({ formik: formikDecease });
                if (formikDecease.values.uid == null) {
                    notification.open({
                        message: 'La création du défunt a échoué',
                        type: 'error',
                    });
                    setLoadBtn(false);
                    return;
                }
                setLoadBtn(false);
            }
        } else if (step == 2) {
            AppointmenetTreatment({ formik: formikAppoint });
        } else if (step == 3) {
            setLoadBtn(true);
            await formikContact.handleSubmit();
            if (isEmpty(formikContact.values.email)) {
                setLoadBtn(false);
                return;
            }
            if (formikContact.errors.email != null) {
                setLoadBtn(false);
                return;
            }
            checkContact(formikContact).then(() => {
                setLoadBtn(false);
            });
        } else if (step == 4) {
            setLoadBtn(true);
            await formikContact.handleSubmit();
            if (!formikContact.isValid) {
                notification.open({
                    message: "S'il vous plaît veuillez remplir les champs obligatoires",
                });
                setLoadBtn(false);
                return;
            }
            if (formikContact.values.uid == null) {
                await contactTreatment({ formik: formikContact });
                if (formikContact.values.uid == null) {
                    notification.open({
                        message: 'La création du contact a échoué',
                        type: 'error',
                    });
                    setLoadBtn(false);
                    return;
                }
            }
            const rep = await dataProcessing({
                formikAppointment: formikAppoint,
                formikContact: formikContact,
                formikDecease: formikDecease,
                folder: folder,
                setVisible: setVisible,
                setViewValidation: setViewValidation,
            });
            if (!rep) {
                notification.open({
                    message: 'La complétion du dossier a échoué',
                    type: 'error',
                });
            }
            setLoadBtn(false);
        }
        if (step < 4) {
            setStep(step + 1);
        }
    }
    function handleReturn() {
        if (step > 0) {
            setEnabled(true);
            setLoadBtn(false);
            setStep(step - 1);
        }
    }

    const [pfName, setPfName] = React.useState(folder.pfName);
    const [loadPostal, setLoadPostal] = React.useState(false);
    const [postalCodes, setPostalCodes] = React.useState([]);
    const [postCode, setPostalCode] = React.useState(folder.people.postalCode);

    React.useEffect(async () => {
        setIsLoading(true);
        if (isEmpty(pfName)) {
            SetOrganization([]);
            setIsLoading(false);
            return;
        }
        const query = `name=${pfName}`;
        await organismAVDCServiceApi
            .getByKeys(query)
            .then((response) => {
                let list = OrganismDataApi.getData(response.data);
                list = getList('id', 'name', list);
                SetOrganization(list);
            })
            .catch(() => {});
        setIsLoading(false);
    }, [pfName]);

    React.useEffect(async () => {
        setLoadPostal(true);
        if (isEmpty(postCode)) {
            setPostalCodes([]);
            setLoadPostal(false);
            return;
        }
        const query = `postal_code=${postCode}`;
        await refServiceApi
            .getPostalCode(query)
            .then((response) => {
                let list = getList('uid', 'display_name', response.data);
                setPostalCodes(list);
            })
            .catch(() => {});
        setLoadPostal(false);
    }, [postCode]);

    React.useEffect(() => {
        if (step == 4) {
            setTitle('Valider');
        } else {
            setTitle('Suivant');
        }
    }, [step]);

    return (
        <React.Fragment>
            <FolderButton onClick={handleShow} type={type} title="Compléter" />
            <Modal
                visible={visible}
                onCancel={handleClose}
                centered
                closeIcon="Fermer"
                okButtonProps={{ hidden: true }}
                wrapClassName="modal"
                cancelButtonProps={{ hidden: true }}
                footer={[
                    <ActionButton
                        enabled={enabled}
                        title={title}
                        step={step}
                        key="btn-footer"
                        handleForward={() => handleForward(folder)}
                        handleReturn={handleReturn}
                        loading={loadBtn}
                    />,
                ]}
            >
                {' '}
                <div style={{ margin: '0 auto' }}>
                    <Header title="Compléter le dossier" subtitle="Vous pouvez compléter le dossier" className="header" />
                    <div style={{ padding: 10 }}>
                        <StateLine
                            list={[{ step: 'Informations du défunt' }, { step: 'Suivi du rendez-vous' }, { step: 'Informations du contact' }]}
                            current={step == 0 || step == 1 ? 0 : step == 2 ? 1 : step == 3 || step == 4 ? 2 : -1}
                        />
                        {step == 0 && <SearchDecease step={step} setEnabled={setEnabled} formik={formikDecease} />}
                        {step == 1 && <CompleteDecease formik={formikDecease} />}
                        {step == 4 && (
                            <CompleteContact
                                setPfName={setPfName}
                                loading={isLoading}
                                organizations={organizations}
                                formik={formikContact}
                                loadPostal={loadPostal}
                                postalCodes={postalCodes}
                                setPostalCode={setPostalCode}
                            />
                        )}
                        {step == 3 && <SearchContact formik={formikContact} setEnabled={setEnabled} />}
                        {step == 2 && <UpdateAppointment formik={formikAppoint} />}
                    </div>
                </div>
            </Modal>
            <ValidationModal message="Dossier complété avec succès !" handleClik={handleUpdate} visible={viewValidation} loading={isLoading} />
        </React.Fragment>
    );
};

export { CompleteFolderView };
