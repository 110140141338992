import { Button, Col, Row } from 'antd';
import React from 'react';

const CTAForm = ({ step, onStepChange, handleClick, loading = false, handleValidate }) => {
    return (
        <Col span={16}>
            {step === 0 && (
                <Button className="button primary" style={{ margin: '0 auto' }} onClick={handleClick}>
                    Suivant
                </Button>
            )}
            {step === 1 && (
                <>
                    <Row justify="space-around">
                        <Col span={12}>
                            <button
                                className="button secondary"
                                style={{ margin: '0 auto', border: 0 }}
                                onClick={() => {
                                    console.log('ss', step);
                                    onStepChange(0);
                                }}
                            >
                                Précédent
                            </button>
                        </Col>
                        <Col span={12}>
                            <Button loading={loading} onClick={handleValidate} className="button primary" style={{ margin: '0 auto' }}>
                                Valider
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Col>
    );
};

export default CTAForm;
