import { Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const HeaderViewBack = ({ handleBackToList, label = 'Retour' }) => {
    const { Text } = Typography;

    return (
        <Row justify="space-between" style={{ margin: 8 }}>
            <Link to="#" style={{ fontWeight: 'bold', color: '#222' }} onClick={handleBackToList}>
                <LeftOutlined />
                <Text style={{ padding: 10, paddingLeft: 5, fontWeight: 'bolder' }} underline>
                    {label}
                </Text>
            </Link>
        </Row>
    );
};

export default HeaderViewBack;
