import { Button, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

const NotificationButton = ({ clickShowHideNotifs, showNotifications }) => {
    const { Text } = Typography;
    const notificationsCounter = useSelector((state) => state.notifications.notificationsCounter);
    return (
        <Button
            onClick={clickShowHideNotifs}
            className={showNotifications ? 'button primary notifications open' : 'button primary notifications'}
            shape="round"
            style={{ marginLeft: 8 }}
        >
            <i className="left icon-notification_cloche"></i>
            <Text>Notifications</Text>
            <Text className="numbers">{notificationsCounter}</Text>
        </Button>
    );
};

export default NotificationButton;
