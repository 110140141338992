import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import NotificationHelper from '../../Helpers/NotificationHelper';
import DateHelper from '../../Helpers/DateHelper';
import { Col, List, Row } from 'antd';
import { Link } from 'react-router-dom';
import folderServiceApi from '../../services/folderServiceApi';
import { FolderTypes, getNameFromValue } from '../folders/FolderValues';

const TitleNotifications = ({ toggleReminder }) => {
    return (
        <span className="title-top">
            Rappel :
            <CloseOutlined style={{ position: 'absolute', right: '9px', fontSize: '14px' }} onClick={toggleReminder} />
        </span>
    );
};

const ReminderElement = ({ reminder, type }) => {
    const [numberToSend, setNumberToSend] = useState(0);
    const counselor = useSelector((state) => state.user.id);

    switch (type) {
        case 'to_send':
            folderServiceApi
                .getByKeys('state=to_send&counselor=' + counselor + '&itemsPerPage=0')
                .then((response) => {
                    setNumberToSend(response.data['hydra:totalItems']);
                })
                .catch((e) => console.warn('Error ', e));

            return (
                <>
                    <Row className="row">
                        <Col className="title-item folders" span={24}>
                            Vous avez{' '}
                            <span>
                                {numberToSend} dossier{numberToSend > 1 ? 's' : ''}
                            </span>{' '}
                            à envoyer
                        </Col>
                    </Row>
                </>
            );
        default:
            return (
                <>
                    <List
                        size="large"
                        itemLayout="horizontal"
                        dataSource={reminder}
                        renderItem={(item) => (
                            <Link to={`/folders/${item.id}/view`}>
                                <List.Item key={item.id}>
                                    <Row className="row">
                                        <Col className="title-item" span={14}>
                                            {getNameFromValue(FolderTypes, item.folderType)}
                                        </Col>
                                        <Col className="date-item" span={10}>
                                            Dans 15 minutes
                                        </Col>
                                        <Col span={14}>
                                            <span className="name">
                                                <strong>{item.name}</strong>
                                            </span>
                                        </Col>
                                        <Col className="hour-item" span={10}>
                                            {item.hour}
                                        </Col>
                                    </Row>
                                </List.Item>
                            </Link>
                        )}
                    />
                </>
            );
    }
};

const isFifteenMinuteBefore = (hour) => {
    let trigger = false;
    const now15 = new Date(new Date().getTime() + 15 * 60000);
    const timeT = DateHelper.getDate(now15, 'HH[h]mm');
    if (hour === timeT) {
        trigger = true;
    }

    return trigger;
};

const NotificationsReminder = ({ notifsList }) => {
    const notificationsList = useSelector((state) => state.notifications.notiticationsList);
    const [showReminder, setShowReminder] = useState(false);
    const [remindersMeetingsList, setRemindersMeetingsList] = useState([]);
    const [reminder, setReminder] = useState([{}]);
    const [reminderType, setReminderType] = useState('meeting');
    const [isLoaded, setIsLoaded] = useState(false);

    const intervalRef = React.useRef();

    const showHideReminder = () => {
        setShowReminder(!showReminder);
    };
    let remindersMeetings = notifsList;

    function toSendEveryDayPush() {
        let now = new Date();
        let millisTill10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0, 0, 0) - now;
        if (millisTill10 < 0) {
            millisTill10 += 86400000; // si connecté aprés 10h on reset le timer jusqu'a 10h le lendemain.
        }
        setTimeout(function () {
            setReminderType('to_send');
            setShowReminder(true);
        }, millisTill10);
    }

    useEffect(() => {
        if (!isLoaded) {
            if (notificationsList.length > 0) {
                setRemindersMeetingsList(notifsList);
                remindersMeetings = remindersMeetingsList.filter((e) => e.type === 'new_appointment');
            }
            if (remindersMeetings.length != 0) {
                setRemindersMeetingsList(NotificationHelper.allMeetingsList(remindersMeetings));
                setIsLoaded(true);
            }
            toSendEveryDayPush();
        }

        intervalRef.current = setInterval(() => {
            remindersMeetingsList.forEach(function (e) {
                if (isFifteenMinuteBefore(e.hour)) {
                    setReminder([e]);
                    setShowReminder(true);
                }
            });
        }, 60000);
        return () => clearInterval(intervalRef.current);
    }, []);

    return (
        <>
            <div className={showReminder === true ? 'notification-box show' : 'notification-box hide'} align="center">
                <div className="container-panel reminder">
                    <TitleNotifications toggleReminder={showHideReminder} />
                    <ReminderElement reminder={reminder} type={reminderType} />
                </div>
            </div>
        </>
    );
};

export default NotificationsReminder;
