import React, { useEffect, useState } from 'react';
import { NotificationsHeader } from '../notifications/NotificationsHeader';
import { useHistory } from 'react-router-dom';

const Settings = ({ role }) => {
    // setup auth modeconst hist=useHistory();
    const hist = useHistory();
    function handleClick() {
        console.log('user role ', role);
        hist.push('/userManage');
    }
    // setup auth mode
    if (role === 'proprietaire' || role === 'manager' || role === 'dev') {
        return (
            <>
                <span
                    onClick={handleClick}
                    style={{
                        fontSize: '22px',
                        background: '#F7F7F7',
                        padding: 5,
                        borderRadius: '50%',
                        color: '#675E51',
                        height: 50,
                        width: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <i className="icon-settings"></i>
                </span>
            </>
        );
    } else {
        return null;
    }
};

const Disconnect = () => {
    // setup disconnect
    const hist = useHistory();
    function handleClickdisconnect() {
        hist.push('/connexion');
    }
    return (
        <span
            onClick={handleClickdisconnect}
            className="disconnect"
            style={{
                fontSize: '22px',
                background: '#F7F7F7',
                padding: 5,
                borderRadius: '50%',
                color: '#675E51',
                height: 50,
                width: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <i className="icon-sign-out"></i>
        </span>
    );
};
const NotificationAndSetting = () => {
    const [role, setRole] = useState('');
    useEffect(() => {
        if (localStorage.getItem('user-info') === null) {
            setRole('none');
        } else {
            setRole(JSON.parse(localStorage.getItem('user-info')).role);
        }
    }, [role]);
    return (
        <>
            <NotificationsHeader role={role} />
            {role != 'none' && <Settings style={{ width: 'auto' }} role={role} />}
            <Disconnect style={{ width: 'auto' }} />
        </>
    );
};

export default NotificationAndSetting;
