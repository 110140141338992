import { Button, Input, Radio, Row, Col, Select, Typography, AutoComplete, Avatar, Alert } from 'antd';
import React from 'react';
import { FolderButton } from '../folders/Partials/Button/FolderButton';
import { PlusCircleFilled, SearchOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Header = ({ title = '', subtitle = '' }) => {
    return (
        <>
            <div className="header-modal">
                <Text
                    style={{
                        color: '#CB5D4B',
                        fontSize: 18,
                        display: 'block',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginBottom: 12,
                    }}
                >
                    {title}
                </Text>
                <div style={{ textAlign: 'center', maxWidth: 500, marginBottom: 20 }}>
                    <Text style={{ textAlign: 'center', fontSize: 14 }}>{subtitle}</Text>
                </div>
            </div>
        </>
    );
};

const ComponentRadio = ({ label = '', list = [], withFree = false, id, name, formik, enabled = true }) => {
    const [freeValue, setFreeValue] = React.useState(((v) => (list.some((p) => p.value === v) ? '' : v))(formik.values[name]));
    const handleFreeChange = (event) => {
        const value = event.target.value;
        setFreeValue(value);
        formik.setFieldValue(name, value);
    };
    return (
        <React.Fragment>
            <Title level={5} className="text-primary-color form-title">
                {label}
            </Title>
            <Radio.Group disabled={!enabled} name={name} onChange={formik.handleChange} value={formik.values[name]}>
                {list.map((p) => (
                    <Radio className="radio" style={{ color: '#cb5d4b', fontWeight: 'bold' }} key={`radio${id + p.value}`} value={p.value}>
                        {p.title}
                    </Radio>
                ))}
                {withFree && (
                    <Radio className="radio" style={{ color: '#cb5d4b', fontWeight: 'bold' }} key={`radio${id + '_free'}`} value={freeValue}>
                        <Input type="text" disabled={!enabled} value={freeValue} onChange={handleFreeChange} />
                    </Radio>
                )}
            </Radio.Group>
            {formik.errors[name] && <ErrorAlert message={formik.errors[name]} />}
        </React.Fragment>
    );
};

const ComponentSelect = ({
    label = '',
    loading = false,
    setSearch,
    name,
    titleBtn = '',
    placeholder = '',
    list = [],
    id,
    search = false,
    showInput = false,
    formik,
    enabled = true,
}) => {
    const [show, setShow] = React.useState(false);
    const [content, setContent] = React.useState(formik.values[name]);
    function handleSearch(v) {
        setContent(v);
        if (setSearch != undefined && setSearch != null) {
            setSearch(v);
        }
    }
    function handleChange(v) {
        formik.setFieldValue(name, v);
        const fil = list.filter((p) => p.value == v);
        if (fil.length > 0) {
            setContent(fil[0].title);
        }
    }
    function handleAddNew() {
        setShow(!show);
        formik.values[name] = content;
    }
    const DropdownMenu = () => {
        return (
            <Button
                icon={<PlusCircleFilled className="text-primary-color" style={{ fontSize: 20 }} />}
                onClick={handleAddNew}
                className="text-primary-color form-btn-add_new"
                type="text"
            >
                {titleBtn}
            </Button>
        );
    };
    return (
        <React.Fragment>
            <Title level={5} className="text-primary-color form-title">
                {label}
            </Title>
            {show == false && (
                <Select
                    showSearch={search}
                    optionFilterProp="children"
                    loading={loading}
                    disabled={!enabled}
                    dropdownRender={(menu) => (
                        <div>
                            {showInput == true && <DropdownMenu />}
                            {menu}
                        </div>
                    )}
                    style={{ width: '100%' }}
                    onChange={(v) => handleChange(v)}
                    value={formik.values[name]}
                    id={name}
                    onSearch={handleSearch}
                >
                    {list.map((p) => (
                        <Select.Option key={`select${id + p.value}`} value={p.value}>
                            {p.title}
                        </Select.Option>
                    ))}
                </Select>
            )}
            {show == true && (
                <Input
                    placeholder={placeholder}
                    type="text"
                    name={name}
                    value={formik.values[name]}
                    onChange={formik.handleChange}
                    suffix={
                        <Button shape="circle" onClick={() => setShow(!show)}>
                            <SearchOutlined style={{ color: '#cb5d4b' }} />
                        </Button>
                    }
                />
            )}
            {formik.errors[name] && <ErrorAlert message={formik.errors[name]} />}
        </React.Fragment>
    );
};

const ComponentInput = ({ label, placeholder, name, type, enabled = true, formik }) => {
    return (
        <React.Fragment>
            <Title level={5} className="text-primary-color form-title">
                {label}
            </Title>
            <Input placeholder={placeholder} type={type} disabled={!enabled} value={formik.values[name]} onChange={formik.handleChange} name={name} />
            {formik.errors[name] && <ErrorAlert message={formik.errors[name]} />}
        </React.Fragment>
    );
};

const filterValue = (value) => {
    if (value == undefined) return '';
    return value;
};

const ActionButton = ({ step = 0, title = 'Suivant', handleForward, handleReturn, enabled = false, loading = false }) => {
    return (
        <div>
            <Row align="middle" justify="center">
                {step > 0 && (
                    <Col>
                        <Button
                            style={{
                                color: '#cb5d4b',
                                fontWeight: 'bold',
                                fontSize: 16,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={handleReturn}
                            type="text"
                        >
                            Précédent
                        </Button>
                    </Col>
                )}
                <Col>
                    <FolderButton title={title} onClick={handleForward} type={3} enabled={enabled} loading={loading} />
                </Col>
            </Row>
        </div>
    );
};

const ComponentAutocomplete = ({ label = '', searchValue, setSearchValue, list = [], name, formik, search = true }) => {
    function handleChange(v) {
        formik.setFieldValue(name, v);
    }

    return (
        <React.Fragment>
            <Title level={5} className="text-primary-color form-title">
                {label}
            </Title>
            <AutoComplete
                showSearch={search}
                optionFilterProp="children"
                style={{ width: '100%' }}
                onChange={(v) => handleChange(v)}
                value={formik.values[name]}
                searchValue={searchValue}
                allowClear={false}
                options={list}
                onSearch={(v) => setSearchValue(v)}
                autoFocus
                defaultOpen={true}
                open={true}
            />
        </React.Fragment>
    );
};

const StateComponent = ({ value, step = '', current = 0 }) => (
    <Row justify="center">
        <Col flex="1">
            <Avatar
                style={{ background: current !== value ? '#675e51' : '#cb5d4b', fontWeight: 'bolder', display: 'flex', alignItems: 'center' }}
                size={50}
            >
                {value + 1}
            </Avatar>
            <div style={{ position: 'absolute', marginTop: 5, left: -20 }}>
                <div style={{ position: 'relative', width: 100, textAlign: 'center' }}>
                    <Title level={5} className={current !== value ? 'step-deactive label-step' : 'step-active label-step active'}>
                        {step}
                    </Title>
                </div>
            </div>
        </Col>
    </Row>
);

const StateLine = ({ list = [], current = 0 }) => {
    return (
        <Row justify="center" align="middle" style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 65 }}>
            {list.map((item, index) => {
                if (index != 0) {
                    return (
                        <React.Fragment key={`stateline${index}`}>
                            <Col flex="1">
                                <div style={{ height: 2, background: '#cb5d4b' }} />
                            </Col>
                            <Col>
                                <StateComponent current={current} step={item.step} key={`state${index}`} value={index} />
                            </Col>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <Col key={`stateline${index}`}>
                            <StateComponent current={current} step={item.step} key={`state${index}`} value={index} />
                        </Col>
                    );
                }
            })}
        </Row>
    );
};

function getList(value, label, items = [], showLabel = false) {
    const list = [];
    items.forEach((item) => {
        const uid = item[value];
        let title = item[label];
        if (showLabel == false) {
            list.push({ value: uid, title, item });
        } else {
            list.push({ value: uid, label: title, item });
        }
    });
    return list;
}

function ErrorAlert({ message = '' }) {
    return (
        <React.Fragment>
            <Alert message={message} type="error" />
        </React.Fragment>
    );
}

function isEmpty(value = '') {
    let rep = false;
    if (value == undefined || value == null) {
        rep = true;
    } else {
        if (value == '') {
            rep = true;
        }
    }
    return rep;
}

export { ActionButton, ComponentAutocomplete, ComponentInput, ComponentRadio, ComponentSelect, filterValue, getList, Header, isEmpty, StateLine };
