import React from 'react';
import { Col, Row } from 'antd';
import NotificationsList from './NotificationsList';
import HeaderTitle from '../Partials/HeaderTitle';

const HeaderList = () => {
    return (
        <>
            <Row justify="space-between">
                <Col span={12}>
                    <HeaderTitle title="Centre de notifications" />
                </Col>
            </Row>
        </>
    );
};
const Notifications = () => {
    return (
        <>
            <div className="box list_folders">
                <HeaderList />
                <NotificationsList />
            </div>
        </>
    );
};

export { Notifications };
