import http from './apiConfig';

const source = 'folder_bo_document_files';

class BoFileApi {
    getAll() {
        return http.get(source);
    }

    get(id) {
        return http.get(`${source}/${id}`);
    }

    create(data) {
        return http.post(source, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    update(id, data) {
        return http.put(`${source}/${id}`, data);
    }

    delete(id) {
        return http.delete(`${source}/${id}`);
    }

    getData(data) {
        if (data['hydra:member']) return data['hydra:member'];
        return [];
    }

    getByKeys(queryParam) {
        return http.get(`${source}?${queryParam}`);
    }

    getByUrl(uid) {
        return http.get(`${uid}`);
    }

    getUrl(uid) {
        return http.get(`${source}/${uid}/signed-url`);
    }
}

export default new BoFileApi();
