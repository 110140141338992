import { notification } from 'antd';
import { isEmpty } from 'lodash';

class OrganismHelper {
    getSecondaryContactsNames(organism) {
        return organism.contacts.slice(1).map((contact) => contact.fullName);
    }

    displayOrganismType(organismType) {
        switch (organismType) {
            case 'PF':
                return 'POMPES FUNÈBRES';

            default:
                return 'ORGANISME';
        }
    }

    toDisplayOganismType(organismTypes) {
        let organismTypesToDisplay = [];
        for (let i = 0; i < organismTypes.length; i++) {
            organismTypesToDisplay.push({ name: organismTypes[i]['name'], value: organismTypes[i]['@id'] });
        }

        return organismTypesToDisplay;
    }

    getOrganismObjectProperties(values) {
        return {
            name: values.organismName,
            recipient: values.organismRecipient,
            theme: values.organismTheme,
            email: values.organismEmail,
            phone: values.organismTelephone,
            address: values.organismAdress,
            addressComplement: values.organismExtraAdress,
            postalCode: values.organismPostalCode,
            postalName: values.organismTown,
            country: values.organismCountry,
            contacts: this.getContactObjectProperties(values.organismContacts.filter((e) => !isEmpty(e.fullName))),
        };
    }

    getContactObjectProperties(contactValues) {
        let status = true;
        if (contactValues.length == 1) {
            if (isEmpty(contactValues[0].fullName)) {
                status = false;
            }
        }
        if (status) {
            const items = [];
            contactValues.forEach((p) => {
                items.push({
                    fullName: p.fullName,
                    email: p.email,
                    phone: p.phone,
                });
            });
            return items;
        } else {
            return [];
        }
    }

    getObjectFromOrganism(organism) {
        return {
            organismName: organism.name,
            organismTheme: organism.theme,
            organismTelephone: organism.phone,
            organismEmail: organism.email,
            organismAdress: organism.address,
            organismExtraAdress: organism.addressComplement,
            organismRecipient: organism.recipient,
            organismPostalCode: organism.postalCode,
            organismTown: organism.postalName,
            organismCountry: organism.country,
            organismContacts: organism.contacts,
        };
    }

    displayMessageAfterCTA(successMessage) {
        const key = 'updatable';
        notification.success({
            content: successMessage,
            key,
            duration: 2,
        });
    }

    transformOrganismPostalCode(postalCodes) {
        //return an array of object {value:xxx} with unique value
        return [...new Set(postalCodes.map((postal) => postal.postal_code))].map((postalCode) => ({ value: postalCode }));
    }

    isValid(formik, fields = []) {
        let response = true;
        for (const key in formik.errors) {
            if (fields.findIndex((p) => p == key) != -1 && !isEmpty(formik.errors[key])) {
                response = false;
            }
        }
        return response;
    }
}

export default new OrganismHelper();
