import { Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const HeaderViewOrganism = ({ handleBackToList }) => {
    const { Text } = Typography;
    const label = 'Revenir à la liste des organismes';

    return (
        <Row justify="space-between" style={{ margin: 8 }}>
            <Link to="/organisms" style={{ fontWeight: 'bold', color: '#222' }} onClick={handleBackToList}>
                <LeftOutlined />
                <Text underline style={{ padding: 10, paddingLeft: 5, fontWeight: 'bolder' }}>
                    {label}
                </Text>
            </Link>
        </Row>
    );
};

export default HeaderViewOrganism;
