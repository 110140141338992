import http from './apiConfig';

const contactApiSource = 'people';

class ContactDataApi {
    getAll() {
        return http.get(contactApiSource);
    }

    get(id) {
        return http.get(`${contactApiSource}/${id}`);
    }

    create(data) {
        return http.post(contactApiSource, data);
    }

    update(id, data) {
        return http.put(`${contactApiSource}/${id}`, data);
    }

    delete(id) {
        return http.delete(`${contactApiSource}/${id}`);
    }

    getData(data) {
        if (data['hydra:member']) return data['hydra:member'];
        return [];
    }

    getByKeys(queryParam) {
        return http.get(`${contactApiSource}?${queryParam}`);
    }
    getUri(id) {
        return http.get(id);
    }
}

export default new ContactDataApi();
